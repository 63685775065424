import { Chip, Grid, Hidden } from '@material-ui/core';
import '../../Core/BudgetCore/budget.scss';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import { LineChart, Line, CartesianGrid, Tooltip, ResponsiveContainer, YAxis, XAxis } from 'recharts';
import { DepartmentBudgetCard, DepartmentCard } from '../../Core/BudgetCore/budgetCardsUi_v2';
import { postBranchOverView } from '../../Core/Service/Nicol_Common_services.js';
import formatters from '../../Core/Grid/inputFormatter';
import CircularIndeterminate from '../../Core/BudgetCore/loader';
import BudgetFilter from './budgetFilters';
import { useWindowSize } from '../../Core/Controls/ScreenResolution';
import moment from 'moment';

const BudgetScreen = () => {
    const [data, setData] = useState({
        showLoader: true,
        selectedBranchList: []
    });

    const [WindowWidths] = useWindowSize();
    const [selectedBranchList, setSelectedBranchList] = useState([]);
    const [labourRunRate, setLabourRunRate] = useState([]);
    const [newRunRate, setNewRunRate] = useState([]);
    const [usedRunRate, setUsedRunRate] = useState([]);

    const getSelectedBranchList = (list) => {
        setSelectedBranchList(list || []);
    };

    const getFilterData = async (value) => {
        setData((st) => ({ ...st, showLoader: true }));

        let data = {
            filterDate: value.filterDate,
            franchiseCode: value.franchiseCode,
            branchID: value.branchID
        };
        let res = await postBranchOverView(data);
        if (res.success) {
            let data = res.data.dashboard[0];

            setLabourRunRate(
                res.data.labourRunRate.map((n) => {
                    return { ...n, newMonth: moment(n.month, 'DD/MM/YYYY').format('MMM-DD') };
                })
            );
            setNewRunRate(
                res.data.newRunRate.map((n) => {
                    return { ...n, newMonth: moment(n.month, 'DD/MM/YYYY').format('MMM-DD') };
                })
            );
            setUsedRunRate(
                res.data.usedRunRate.map((n) => {
                    return { ...n, newMonth: moment(n.month, 'DD/MM/YYYY').format('MMM-DD') };
                })
            );
            setData((st) => ({
                ...st,

                afterSalesTotalBudget: data.afterSalesTotalBudget,
                afterSalesTotalPercentage: data.afterSalesTotalPercentage,
                afterSalesTotalProfit: data.afterSalesTotalProfit,
                newTotalBudget: data.newTotalBudget,
                newTotalPercentage: data.newTotalPercentage,
                newTotalProfit: data.newTotalProfit,
                usedTotalBudget: data.usedTotalBudget,
                usedTotalPercentage: data.usedTotalPercentage,
                usedTotalProfit: data.usedTotalProfit,
                newUnitDailyRunRate: data.newUnitDailyRunRate,
                newUnitDailyRunRateIcon: data.newUnitDailyRunRateIcon,
                newUnitSales: data.newUnitSales,
                newInvoicedDailyBudgetIcon: data.newInvoicedDailyBudgetIcon,
                newInvoicedDailyDailyRunRate: data.newInvoicedDailyDailyRunRate,
                newInvoicedSales: data.newInvoicedSales,
                usedUnitDailyRunRate: data.usedUnitDailyRunRate,
                usedUnitDailyRunRateIcon: data.usedUnitDailyRunRateIcon,
                usedUnitSales: data.usedUnitSales,
                usedInvoiceDailyBudgetIcon: data.usedInvoiceDailyBudgetIcon,
                usedInvoiceDailyRunRate: data.usedInvoiceDailyRunRate,
                usedInvoiceSales: data.usedInvoiceSales,
                totalRetailLabour: data.totalRetailLabour,
                totalRetailLabourRunRate: data.totalRetailLabourRunRate,
                totalRetailLabourRunRateIcon: data.totalRetailLabourRunRateIcon,
                totalLabour: data.totalLabour,
                totalLabourRunRate: data.totalLabourRunRate,
                totalLabourRunRateIcon: data.totalLabourRunRateIcon,
                totalInternalLabour: data.totalInternalLabour,
                totalInternalLabourRunRate: data.totalInternalLabourRunRate,
                totalInternalLabourRunRateIcon: data.totalInternalLabourRunRateIcon,
                newReservedProfit: data.newReservedProfit,
                usedReservedProfit: data.usedReservedProfit,

                showLoader: false
            }));
        }
    };

    const NewUnit = [
        {
            name: 'New Reserved',
            showLink: true,
            value: data.newUnitSales,
            profitValue: data.newUnitDailyRunRate,
            icon: data.newUnitDailyRunRateIcon,
            type: 'new',
            est_profit: data.newReservedProfit,
            url: '/new_Reserved',
            hideBudget: true
        },
        {
            name: 'New Invoiced',
            showLink: true,
            value: data.newInvoicedSales,
            profitValue: data.newInvoicedDailyDailyRunRate,
            icon: data.newInvoicedDailyBudgetIcon,
            type: 'new',
            url: '/new_invoice'
        }
    ];

    const UsedUnit = [
        {
            name: 'Used Reserved',
            showLink: true,
            value: data.usedUnitSales,
            profitValue: data.usedUnitDailyRunRate,
            icon: data.usedUnitDailyRunRateIcon,
            type: 'used',
            est_profit: data.usedReservedProfit,
            url: '/used_Reserved',
            hideBudget: true
        },
        {
            name: 'Used Invoiced',
            showLink: true,
            value: data.usedInvoiceSales,
            profitValue: data.usedInvoiceDailyRunRate,
            icon: data.usedInvoiceDailyBudgetIcon,
            type: 'used',
            url: '/used_invoice'
        }
    ];
    const Retail = [
        {
            name: 'Retail Labour',
            showLink: true,
            value: formatters.CurrencyThousandSeparatorWithoutZero(data.totalRetailLabour),
            profitValue: formatters.CurrencyThousandSeparatorWithoutZero(data.totalRetailLabourRunRate),
            icon: data.totalRetailLabourRunRateIcon,
            type: 'R',
            url: '/labour'
        },
        {
            name: 'Internal Labour',
            showLink: true,
            value: formatters.CurrencyThousandSeparatorWithoutZero(data.totalInternalLabour),
            profitValue: formatters.CurrencyThousandSeparatorWithoutZero(data.totalInternalLabourRunRate),
            icon: data.totalInternalLabourRunRateIcon,
            type: 'I',
            url: '/labour'
        }
    ];

    return (
        <div className="budgetScreen">
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs style={{ textAlign: 'left' }}>
                    <div className="mainHeading alignLeft screenMainHeader">Branch Overview</div>
                    {WindowWidths < 960 && (
                        <>
                            {selectedBranchList?.length > 0 ? (
                                <div>
                                    {selectedBranchList.map((p) => {
                                        return <Chip color="secondary" label={p.name} variant="outlined" style={{ margin: '2px' }} />;
                                    })}
                                </div>
                            ) : (
                                <div>
                                    <Chip color="secondary" label="All Branches" variant="outlined" style={{ margin: '2px' }} />
                                </div>
                            )}
                        </>
                    )}
                </Grid>
                <Grid item style={{ marginTop: 5 }}>
                    <Hidden only={['xl', 'lg', 'md']}>
                        <BudgetFilter getFilterData={getFilterData} getSelectedBranchList={getSelectedBranchList} />
                    </Hidden>
                </Grid>
            </Grid>

            <Hidden only={['sm', 'xs']}>
                {' '}
                <BudgetFilter getFilterData={getFilterData} />
            </Hidden>

            {data.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Grid container className="buget01">
                        <Grid item container xs={12} sm={12} md={6} lg={4} xl={4} className="containerPadding">
                            <Grid item xs={12}>
                                <DepartmentCard
                                    value={data.newTotalPercentage}
                                    profit={data.newTotalProfit}
                                    budget={data.newTotalBudget}
                                    dept={'NEW DEPARTMENT'}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={6} lg={4} xl={4} className="containerPadding">
                            <Grid item xs={12}>
                                <DepartmentCard
                                    dept={'USED DEPARTMENT'}
                                    value={data.usedTotalPercentage}
                                    profit={data.usedTotalProfit}
                                    budget={data.usedTotalBudget}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} sm={12} md={6} lg={4} xl={4} className="containerPadding">
                            <Grid item xs={12}>
                                <DepartmentCard
                                    dept={'AFTERSALES DEPARTMENT'}
                                    value={data.afterSalesTotalPercentage}
                                    labour={true}
                                    showLink={true}
                                    type={'T'}
                                    url={'/labour'}
                                    profit={data.totalLabour}
                                    budget={data.totalLabourRunRate}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Grid container justifyContent="space-between">
                                {NewUnit.map((n, i) => {
                                    return (
                                        <Grid item xs={6} sm={6} key={i}>
                                            <DepartmentBudgetCard
                                                showLink={n.showLink}
                                                icon={n.icon}
                                                value={n.value}
                                                label={n.name}
                                                profitVal={n.profitValue}
                                                type={n.type}
                                                url={n.url}
                                                hideBudget={n.hideBudget}
                                                est_profit={n.est_profit}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Grid container justifyContent="space-between">
                                {UsedUnit.map((n, i) => (
                                    <Grid item xs={6} sm={6} key={i}>
                                        <DepartmentBudgetCard
                                            showLink={n.showLink}
                                            icon={n.icon}
                                            value={n.value}
                                            label={n.name}
                                            profitVal={n.profitValue}
                                            type={n.type}
                                            url={n.url}
                                            hideBudget={n.hideBudget}
                                            est_profit={n.est_profit}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Grid container justifyContent="space-between">
                                {Retail.map((n, i) => {
                                    return (
                                        <Grid item xs={6} sm={6} key={i}>
                                            <DepartmentBudgetCard
                                                showLink={n.showLink}
                                                icon={n.icon}
                                                value={n.value}
                                                label={n.name}
                                                profitVal={n.profitValue}
                                                type={n.type || ''}
                                                url={n.url || '/'}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className="containerPadding">
                            <Grid item xs={12}>
                                <div className="BOGraph_card">
                                    <div className="BOtype2_Text alignLeft w100 BOGraphHeadingPadding">NEW RUN RATE</div>
                                    <div className="w100">
                                        <ResponsiveContainer className="BOGraph">
                                            <LineChart
                                                width={300}
                                                height={140}
                                                margin={{
                                                    top: 5,
                                                    right: 10,
                                                    left: -30,
                                                    bottom: 6
                                                }}
                                                data={newRunRate}
                                            >
                                                <Tooltip />
                                                <CartesianGrid />
                                                <Line dataKey="runRate" stroke="#FF007C" strokeWidth={4} Label />
                                                <XAxis
                                                    interval={0}
                                                    angle="-30"
                                                    dataKey="newMonth"
                                                    textAnchor="end"
                                                    sclaeToFit="true"
                                                    verticalAnchor="start"
                                                    style={{ fontSize: 9, fontWeight: 700 }}
                                                />
                                                <YAxis orientation="left" style={{ fontSize: 10 }} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className="containerPadding">
                            <Grid item xs={12}>
                                <div className="BOGraph_card">
                                    <div className="BOtype2_Text alignLeft p4 BOGraphHeadingPadding">USED RUN RATE</div>
                                    <div className="p4">
                                        <ResponsiveContainer className="BOGraph">
                                            <LineChart
                                                width={300}
                                                height={100}
                                                data={usedRunRate}
                                                margin={{
                                                    top: 5,
                                                    right: 10,
                                                    left: -30,
                                                    bottom: 6
                                                }}
                                            >
                                                <Tooltip />
                                                <CartesianGrid />
                                                <Line dataKey="runRate" stroke="#FF007C" strokeWidth={4} Label />
                                                <XAxis
                                                    dataKey="newMonth"
                                                    textAnchor="end"
                                                    sclaeToFit="true"
                                                    verticalAnchor="start"
                                                    interval={0}
                                                    angle="-30"
                                                    style={{ fontSize: 9, fontWeight: 700 }}
                                                />
                                                <YAxis orientation="left" style={{ fontSize: 10 }} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className="containerPadding">
                            <Grid item xs={12}>
                                <div className="BOGraph_card">
                                    <div className="BOtype2_Text alignLeft p4 BOGraphHeadingPadding">LABOUR RUN RATE</div>
                                    <ResponsiveContainer className="BOGraph">
                                        <LineChart
                                            width={300}
                                            height={100}
                                            margin={{
                                                top: 5,
                                                right: 10,
                                                left: -20,
                                                bottom: 6
                                            }}
                                            data={labourRunRate}
                                        >
                                            <Tooltip />
                                            <CartesianGrid />
                                            <Line dataKey="runRate" stroke="#FF007C" strokeWidth={4} Label />
                                            <XAxis
                                                dataKey="newMonth"
                                                textAnchor="end"
                                                sclaeToFit="true"
                                                verticalAnchor="start"
                                                interval={0}
                                                angle="-30"
                                                style={{ fontSize: 9, fontWeight: 700 }}
                                            />
                                            <YAxis orientation="left" style={{ fontSize: 10 }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default BudgetScreen;
