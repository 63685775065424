import { FormControlLabel, Switch } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { SummaryBoxNav } from '../../../Core/BudgetCore/budgetCardsUi_v2';
import {
postGetSitePerformanceDashboard,
postGetSitePerformanceFinanceDetails,
postGetSitePerformanceSummaryDetails,
postGetSitePerformanceProductsDetails,
postGetSitePerformanceWarrantyDetails,
postGetSitePerformanceAccessoriesDetails,
postGetSitePerformanceSalesExec,
postGetSitePerformancePopup
} from '../../../Core/Service/Nicol_Common_services';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { LinkedButton } from '../../../Core/BudgetCore/budgetButton';
import BudgetFilterv2 from '../budgetFiltersv2';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import { useHistory } from 'react-router-dom';
import { SummaryCardShared, SummaryCard } from './summaryCards';
import { SitePerformanceMainData } from './sitePerformanceMain';
import './sitePerformance.scss';
import { SalesExecTable } from './salesExecTable';
import { CSVLink } from "react-csv";
import formatters from '../../../Core/Grid/inputFormatter';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import { PopupVehicleDetails } from './sitePerformancePopup';

const SitePerformance = () => {
    const [state, setState] = useState({
        includeReserved: false,
    });
    const [showModalNewVehicleSummary, setShowModalNewVehicleSummary] = useState(false);
    const [showModalUsedVehicleSummary, setShowModalUsedVehicleSummary] = useState(false);
    const [showModalNewVehicleFinance, setShowModalNewVehicleFinance] = useState(false);
    const [showModalUsedVehicleFinance, setShowModalUsedVehicleFinance] = useState(false);
    const [showModalNewVehicleProducts, setShowModalNewVehicleProducts] = useState(false);
    const [showModalUsedVehicleProducts, setShowModalUsedVehicleProducts] = useState(false);
    const [showModalNewVehicleAcc, setShowModalNewVehicleAcc] = useState(false);
    const [showModalUsedVehicleAcc, setShowModalUsedVehicleAcc] = useState(false);
    const [showModalUsedVehicleWarranty, setShowModalUsedVehicleWarranty] = useState(false);

    const [selectedBranchList, setSelectedBranchList] = useState([]);
    const [data, setData] = useState({
        showLoader: true,
        itemOpen: "",
        itemOpenUsed: "",
        runSummary: false,
        summaryNewVisible: false,
        summaryUsedVisible: false,
        financeNewVisible: false,
        financeUsedVisible: false,
        productsNewVisible: false,
        productsUsedVisible: false,
        warrantyNewVisible: false,
        warrantyUsedVisible: false,
        accessoryNewVisible: false,
        accessoryUsedVisible: false,
        summaryNewLoaderRunning: true,
        summaryUsedLoaderRunning: true,
        financeNewLoaderRunning: true,
        financeUsedLoaderRunning: true,
        productsNewLoaderRunning: true,
        productsUsedLoaderRunning: true,
        warrantyNewLoaderRunning: true,
        warrantyUsedLoaderRunning: true,
        accessoryNewLoaderRunning: true,
        accessoryUsedLoaderRunning: true,
        salesExecRunning: true,
        salesExecNew: true,
        salesExecUsed: true,
        salesExecMotab: false,
        resDataToFilter: ""
    });

    const getSelectedBranchList = (list) => {
        setSelectedBranchList(list || []);
    };

    const getFilterData = async (value) => {
        setData((st) => ({ ...st, showLoader: true, filterDate: value.filterDate, branchID: value.branchID }));
        let dataSearch = {
            filterDate: value.filterDate || [],
            franchiseCode: value.franchiseCode || [],
            branchID: value.branchID || [],
            includeReserved: state.includeReserved
        };
        let res = await postGetSitePerformanceDashboard(dataSearch);
        if (res.success) {
            setData((st) => ({
                ...st,
                dataAfterFilter: res.data,
                showLoader: false,
                runSummary: true
            }));
        }
    };

    const handleincludeReserved = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            let newSt = { ...st };
            newSt[name] = checked;
            return newSt;
        });
    };

    const getSalesExec = async (value) => {
        let usedCars = value === "usedCars" ? !data.salesExecUsed : data.salesExecUsed;
        let newCars = value === "newCars" ? !data.salesExecNew : data.salesExecNew;
        let motabCars = value === "motabCars" ? !data.salesExecMotab : data.salesExecMotab;

        setData((st) => ({
            ...st,
            salesExecRunning: true,
            salesExecMotab: motabCars,
            salesExecUsed: usedCars,
            salesExecNew: newCars,
        }));

        let dataSearchSE = {
            branchID: data.branchID,
            filterDate: data.filterDate || [],
            includeReserved: state.includeReserved,
            salesExecNew: newCars,
            salesExecUsed: usedCars,
            salesExecMotab: motabCars
        }

        let resSE = await postGetSitePerformanceSalesExec(dataSearchSE);

        if (resSE.success) {
            let table = resSE.data.filteredUsers;

            for (let i = 0; i < table.length; i++) {
                table[i].accessories = formatters.CurrencyThousandSeparatorWithoutZero(table[i].accessories);
                table[i].chassisProfit = formatters.CurrencyThousandSeparatorWithoutZero(table[i].chassisProfit);
                table[i].ppu = formatters.CurrencyThousandSeparatorWithoutZero(table[i].ppu);
                table[i].upsellPPU = formatters.CurrencyThousandSeparatorWithoutZero(table[i].upsellPPU);
                table[i].chassisPPU = formatters.CurrencyThousandSeparatorWithoutZero(table[i].chassisPPU);
            }

            setData((st) => ({
                ...st,
                resDataSE: resSE.data.users,
                resDataToFilter: table,
                salesExecRunning: false,
            }));           
        }
    }

    const handlerClickNavTab = async (type) => {
        let currentDate = data.filterDate;
        let previousStartingDate = new Date(currentDate[0].startDate);
        previousStartingDate.setFullYear(previousStartingDate.getFullYear() - 1);
        const previousStartString = `${previousStartingDate.getFullYear()}-${('0' + (previousStartingDate.getMonth() + 1)).slice(-2)}-${('0' + previousStartingDate.getDate()).slice(-2)}`;

        let previousEndDate = new Date(currentDate[0].endDate);
        previousEndDate.setFullYear(previousEndDate.getFullYear() - 1);
        const previousEndtString = `${previousEndDate.getFullYear()}-${('0' + (previousEndDate.getMonth() + 1)).slice(-2)}-${('0' + previousEndDate.getDate()).slice(-2)}`;

        let previousDate = [{
            "startDate": previousStartString,
            "endDate": previousEndtString
        }];

        if (type.includes("New")) {
            setData((st) => ({
                ...st,
                itemOpen: "",
                summaryNewVisible: false,
                financeNewVisible: false,
                productsNewVisible: false,
                warrantyNewVisible: false,
                accessoryNewVisible: false,
                summaryNewLoaderRunning: true,
                financeNewLoaderRunning: true,
                productsNewLoaderRunning: true,
                warrantyNewLoaderRunning: true,
                accessoryNewLoaderRunning: true,
            }));
        }
        else {
            setData((st) => ({
                ...st,
                itemOpenUsed: "",
                summaryUsedVisible: false,
                financeUsedVisible: false,
                productsUsedVisible: false,
                warrantyUsedVisible: false,
                accessoryUsedVisible: false,
                summaryUsedLoaderRunning: true,
                financeUsedLoaderRunning: true,
                productsUsedLoaderRunning: true,
                warrantyUsedLoaderRunning: true,
                accessoryUsedLoaderRunning: true,
            }));
        }


        //Summary 
        if (type === "summaryNew" || type === "summaryUsed") {
            if (type === "summaryNew") {
                setData((st) => ({
                    ...st,
                    summaryNewVisible: true,
                    itemOpen: "summaryNew"
                }));

                let dataSearchSN = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    franchiseCode: data.franchiseCode || [],
                    includeReserved: state.includeReserved,
                    searchType: 'new'
                }

                let resSN = await postGetSitePerformanceSummaryDetails(dataSearchSN);

                if (resSN.success) {
                    setData((st) => ({
                        ...st,
                        resDataSN: resSN.data,
                        summaryNewLoaderRunning: false
                    }));
                }
            }

            if (type === "summaryUsed") {
                setData((st) => ({
                    ...st,
                    summaryUsedVisible: true,
                    itemOpenUsed: "summaryUsed"
                }));

                let dataSearchSU = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    franchiseCode: data.franchiseCode || [],
                    includeReserved: state.includeReserved,
                    searchType: 'used'
                }

                let resSU = await postGetSitePerformanceSummaryDetails(dataSearchSU);

                if (resSU.success) {
                    setData((st) => ({
                        ...st,
                        resDataSU: resSU.data,
                        summaryUsedLoaderRunning: false
                    }));
                }
            }
        }
        //Finance
        if (type === "financeNew" || type === "financeUsed") {
            if (type === "financeNew") {
                setData((st) => ({
                    ...st,
                    financeNewVisible: true,
                    itemOpen: "financeNew"
                }));

                let dataSearchFN = {
                    filterDate: data.filterDate,
                    branchID: data.branchID,
                    filterDatePrevious: previousDate,
                    includeReserved: state.includeReserved,
                    searchType: 'new'
                }

                let resFN = await postGetSitePerformanceFinanceDetails(dataSearchFN);

                if (resFN.success) {
                    setData((st) => ({
                        ...st,
                        resDataFN: resFN.data,
                        financeNewLoaderRunning: false,
                    }));
                }
            }
            if (type === "financeUsed") {
                setData((st) => ({
                    ...st,
                    financeUsedVisible: true,
                    itemOpenUsed: "financeUsed"
                }));

                let dataSearchFU = {
                    filterDate: data.filterDate,
                    branchID: data.branchID,
                    filterDatePrevious: previousDate,
                    includeReserved: state.includeReserved,
                    searchType: 'used'
                }

                let resFU = await postGetSitePerformanceFinanceDetails(dataSearchFU);

                if (resFU.success) {
                    setData((st) => ({
                        ...st,
                        resDataFU: resFU.data,
                        financeUsedLoaderRunning: false,
                    }));
                }
            }
        }
        //Products
        if (type === "productsNew" || type === "productsUsed") {
            if (type === "productsNew") {
                setData((st) => ({
                    ...st,
                    productsNewVisible: true,
                    itemOpen: "productsNew"
                }));

                let dataSearchPN = {
                    filterDate: data.filterDate,
                    branchID: data.branchID,
                    filterDatePrevious: previousDate,
                    includeReserved: state.includeReserved,
                    searchType: 'new'
                }

                let resPN = await postGetSitePerformanceProductsDetails(dataSearchPN);

                if (resPN.success) {
                    setData((st) => ({
                        ...st,
                        resDataPN: resPN.data,
                        productsNewLoaderRunning: false,
                    }));
                }
            }
            if (type === "productsUsed") {
                setData((st) => ({
                    ...st,
                    productsUsedVisible: true,
                    itemOpenUsed: "productsUsed"
                }));

                let dataSearchPU = {
                    filterDate: data.filterDate,
                    branchID: data.branchID,
                    filterDatePrevious: previousDate,
                    includeReserved: state.includeReserved,
                    searchType: 'used'
                }

                let resPU = await postGetSitePerformanceProductsDetails(dataSearchPU);

                if (resPU.success) {
                    setData((st) => ({
                        ...st,
                        resDataPU: resPU.data,
                        productsUsedLoaderRunning: false,
                    }));
                }
            }
        }
        //Warranty
        if (type === "warrantyNew" || type === "warrantyUsed") {
            if (type === "warrantyNew") {
                setData((st) => ({
                    ...st,
                    warrantyNewVisible: true,
                    itemOpen: "warrantyNew"
                }));

                let dataSearchWN = {
                    filterDate: data.filterDate,
                    branchID: data.branchID,
                    filterDatePrevious: previousDate,
                    includeReserved: state.includeReserved,
                    searchType: 'new'
                }

                let resWN = await postGetSitePerformanceWarrantyDetails(dataSearchWN);

                if (resWN.success) {
                    setData((st) => ({
                        ...st,
                        resDataWN: resWN.data,
                        warrantyNewLoaderRunning: false,
                    }));
                }
            }
            if (type === "warrantyUsed") {
                setData((st) => ({
                    ...st,
                    warrantyUsedVisible: true,
                    itemOpenUsed: "warrantyUsed"
                }));

                let dataSearchWU = {
                    filterDate: data.filterDate,
                    branchID: data.branchID,
                    filterDatePrevious: previousDate,
                    includeReserved: state.includeReserved,
                    searchType: 'used'
                }

                let resWU = await postGetSitePerformanceWarrantyDetails(dataSearchWU);

                if (resWU.success) {
                    setData((st) => ({
                        ...st,
                        resDataWU: resWU.data,
                        warrantyUsedLoaderRunning: false,
                    }));
                }
            }
        }
        //Accesories
        if (type === "accNew" || type === "accUsed") {
            if (type === "accNew") {
                setData((st) => ({
                    ...st,
                    accessoryNewVisible: true,
                    itemOpen: "accNew"
                }));

                let dataSearchAN = {
                    filterDate: data.filterDate,
                    branchID: data.branchID,
                    filterDatePrevious: previousDate,
                    includeReserved: state.includeReserved,
                    searchType: 'new'
                }

                let resAN = await postGetSitePerformanceAccessoriesDetails(dataSearchAN);

                if (resAN.success) {
                    setData((st) => ({
                        ...st,
                        resDataAN: resAN.data,
                        accessoryNewLoaderRunning: false,
                    }));
                }
            }
            if (type === "accUsed") {
                setData((st) => ({
                    ...st,
                    accessoryUsedVisible: true,
                    itemOpenUsed: "accUsed"
                }));

                let dataSearchAU = {
                    filterDate: data.filterDate,
                    branchID: data.branchID,
                    filterDatePrevious: previousDate,
                    includeReserved: state.includeReserved,
                    searchType: 'used'
                }

                let resAU = await postGetSitePerformanceAccessoriesDetails(dataSearchAU);

                if (resAU.success) {
                    setData((st) => ({
                        ...st,
                        resDataAU: resAU.data,
                        accessoryUsedLoaderRunning: false,
                    }));
                }
            }
        }
    }

    const history = useHistory();
    const handleRedirect = (e, link) => {
        e.preventDefault();

        history.push({
            pathname: link,
        });
    };

    if (data.runSummary) {
        setData((st) => ({
            ...st,
            runSummary: false
        }));

        handlerClickNavTab("summaryNew");
        handlerClickNavTab("summaryUsed");
        getSalesExec("initial");
    }

    const headCellsSalesExec = [
        {
            label: 'Name',
            key: 'displayName'
        },
        {
            label: 'Total Vol',
            key: 'totalVolume'
        },
        {
            label: 'Average PPU',
            key: 'ppu'
        },
        {
            label: 'Chassis Profit',
            key: 'chassisProfit'
        },
        {
            label: 'Chassis PPU',
            key: 'chassisPPU'
        },
        {
            label: 'Upsell PPU',
            key: 'upsellPPU'
        },
        {
            label: 'Finance Cases',
            key: 'finCases'
        },
        {
            label: 'Service Plan',
            key: 'gap'
        },
        {
            label: 'Supagard',
            key: 'supagard'
        },
        {
            label: 'Cosmetic',
            key: 'cosmetic'
        },
        {
            label: 'T&A',
            key: 'tyreNAlloy'
        },
        {
            label: 'Warranty',
            key: 'warranty'
        },
        {
            label: 'Accessories',
            key: 'accessories'
        }
    ];

    const headCellsPopupVehicle = [
        {
            label: 'Branch',
            key: 'branchName',
        },
        {
            label: 'cXs',
            key: 'salesPerson',
        },
        {
            label: 'Stock No.',
            key: 'stockbookNumber',
        },
        {
            label: 'Invoice Date',
            key: 'invoiceDate',
        },
        {
            label: 'In Stock Date',
            key: 'inStockDate',
        },
        {
            label: 'Customer',
            key: 'customer',
        },
        {
            label: 'Vehicle',
            key: 'vehicle',
        },
        {
            label: 'Reg No',
            key: 'regNo',
        },
        {
            label: 'Chassis',
            key: 'fullChassis',
        },
        {
            label: 'Finance Company',
            key: 'financeCompany',
        },
        {
            label: 'Sale Type',
            key: 'sOrderType',
        },
        {
            label: 'Bodywork',
            key: 'bodyCost',
        },
        {
            label: 'Prep/PDI',
            key: 'prepCost',
        },
        {
            label: 'Total Cost',
            key: 'totalCost',
        },
        {
            label: 'Total Sale',
            key: 'totalSale',
        },
        {
            label: 'Gross Profit',
            key: 'grossProfit',
        },
        {
            label: 'Fuel Sale',
            key: 'fuelSale',
        },
        {
            label: 'Fuel Cost',
            key: 'fuelCost',
        },
        {
            label: 'Admin Fee Sale',
            key: 'adminFeeSale',
        },
        {
            label: 'Admin Fee Cost',
            key: 'adminFeeCost',
        },
        {
            label: 'Accessory Sale',
            key: 'aCCSale',
        },
        {
            label: 'Accessory Cost',
            key: 'aCCCost',
        },
        {
            label: 'Warr Sale',
            key: 'warSale',
        },
        {
            label: 'Warr Cost',
            key: 'warCost',
        },
        {
            label: 'Finance Comm',
            key: 'finance',
        },
        {
            label: 'Finance Due',
            key: 'financeDue',
        },
        {
            label: 'Order Date',
            key: 'orderDate',
        },
        {
            label: 'Delivery Date',
            key: 'deliveryDate',
        },
        {
            label: 'Registration Date',
            key: 'regDate',
        },
        {
            label: 'Part Exchange',
            key: 'partExNumber001',
        },
        {
            label: 'Supplier',
            key: 'supplier',
        },
        {
            label: 'Delivery Sale',
            key: 'saleOfDelivery',
        },
        {
            label: 'Delivery Cost',
            key: 'costOfDelivery',
        },
        {
            label: 'Progress Code',
            key: 'progressCode',
        }
    ];

    const modalHandler = async (type, filter, popupTitle,  retailTradeString) => {
        setShowModalNewVehicleSummary(false);
        setShowModalNewVehicleFinance(false);
        setShowModalNewVehicleProducts(false);
        setShowModalNewVehicleAcc(false);
        setData((st) => ({
            ...st,
            popupSummaryNew: true,
            popupFinanceNew: true,
            popupProductsNew: true,
            popupAccNew: true,
            popupSummaryUsed: true,
            popupFinanceUsed: true,
            popupProductsUsed: true,
            popupAccUsed: true,
            popupWarrantyUsed: true,
        })); 


        setData((st) => ({
            ...st,
            resPopup: "",
        }));    

        if (type === "snVehicle" || type === "suVehicle") {
            let dataNS = {}

            if (type === "suVehicle") {
                setShowModalUsedVehicleSummary(true);

                dataNS = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    includeReserved: state.includeReserved,
                    searchType: 'used'
                }
            }
            else
            {
                setShowModalNewVehicleSummary(true);

                dataNS = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    includeReserved: state.includeReserved,
                    searchType: 'new'
                }
            }
    
            let res = await postGetSitePerformancePopup(dataNS);
    
            if (res.success) {
                setData((st) => ({
                    ...st,
                    resPopup: res.data.popupData,
                    popupSummaryNew: false,
                    popupSummaryUsed: false
                }));           
            }
        }

        if (type === "fnVehicle" || type === "fuVehicle") {
            let dataNS = {}

            if (type === "fuVehicle") {
                setShowModalUsedVehicleFinance(true);

                dataNS = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    includeReserved: state.includeReserved,
                    searchType: 'used',
                    onlyFinance: true,
                    popupFilter: filter
                }
            }
            else {
                setShowModalNewVehicleFinance(true);

                dataNS = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    includeReserved: state.includeReserved,
                    searchType: 'new',
                    onlyFinance: true,
                    popupFilter: filter
                }
            }
            
    
            let res = await postGetSitePerformancePopup(dataNS);
    
            if (res.success) {
                setData((st) => ({
                    ...st,
                    resPopup: res.data.popupData,
                    popupFinanceNew: false,
                    popupFinanceUsed: false,
                }));           
            }
        }

        if (type === "pnVehicle" || type === "puVehicle") {
            let dataNS = {}

            if (type === "puVehicle") {
                setShowModalUsedVehicleProducts(true);
                dataNS = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    includeReserved: state.includeReserved,
                    searchType: 'used',
                    onlyVehicleWithProducts: true,
                    popupFilter: filter
                }
            }
            else
            {
                setShowModalNewVehicleProducts(true);
                dataNS = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    includeReserved: state.includeReserved,
                    searchType: 'new',
                    onlyVehicleWithProducts: true,
                    popupFilter: filter
                }
            }
    
            let res = await postGetSitePerformancePopup(dataNS);
    
            if (res.success) {
                setData((st) => ({
                    ...st,
                    resPopup: res.data.popupData,
                    popupProductsNew: false,
                    popupProductsUsed: false,
                }));           
            }
        }

        if (type === "anVehicle" || type === "auVehicle") {
            let dataNS = {}

            if (type === "auVehicle") {
                setShowModalUsedVehicleAcc(true);

                dataNS = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    includeReserved: state.includeReserved,
                    searchType: 'used',
                    onlyVehicleWithAcc: true,
                    listLookUp: filter,
                    sOrderType: retailTradeString
                }
            }
            else {
                setShowModalNewVehicleAcc(true);

                dataNS = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    includeReserved: state.includeReserved,
                    searchType: 'new',
                    onlyVehicleWithAcc: true,
                    listLookUp: filter,
                    sOrderType: retailTradeString
                }
            }

            let res = await postGetSitePerformancePopup(dataNS);
    
            if (res.success) {
                let data = res.data.popupData;

                setData((st) => ({
                    ...st,
                    resPopup: data,
                    popupAccNew: false,
                    popupAccUsed: false,
                    popupTitle: popupTitle
                }));           
            }
        }

        if (type === "wuVehicle" || type === "wuVehicleList") {
            setShowModalUsedVehicleWarranty(true);

            let dataNS = {}
            
            if (type === "wuVehicleList") {
                dataNS = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    includeReserved: state.includeReserved,
                    searchType: 'used',
                    onlyVehicleWithWarranty: true,
                    listLookUp: filter
                }
            }
            else {
                dataNS = {
                    branchID: data.branchID,
                    filterDate: data.filterDate || [],
                    includeReserved: state.includeReserved,
                    searchType: 'used',
                    onlyVehicleWithWarranty: true,
                    popupFilter: filter
                }
            }
            

            let res = await postGetSitePerformancePopup(dataNS);
    
            if (res.success) {
                setData((st) => ({
                    ...st,
                    resPopup: res.data.popupData,
                    popupWarrantyUsed: false,
                }));           
            }
        }
    }


    return (
        <div className="boxSkel">
            <Grid container spacing={1} alignContent="center" justifyContent="space-between">
                <Grid item xs style={{ textAlign: 'left' }}>
                    <div className="titleBoxSkel">
                        Sales Performance
                        {/* Site Performance */}
                        <FormControlLabel
                            control={<Switch checked={state.includeReserved} onChange={handleincludeReserved} name="includeReserved" />}
                            label="Include Reserved"
                            labelPlacement="start"
                        />
                    </div>
                </Grid>
            </Grid>
            <BudgetFilterv2 getFilterData={getFilterData} getSelectedBranchList={getSelectedBranchList} includeReserved={state.includeReserved} hideFranchiseSearch={true} />

            {data.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <SitePerformanceMainData dataAfterFilter={data.dataAfterFilter} />

                    <Grid item xs={12} className='summaryBoxes' >
                        <Grid className='summaryBox'>
                            <SummaryBoxNav
                                title1={"Summary"}
                                clickHandler1={handlerClickNavTab}
                                id1={"summaryNew"}
                                title2={"Finance"}
                                id2={"financeNew"}
                                title3={"Products"}
                                id3={"productsNew"}
                                title4={"CSI"}
                                id4={"doNothing"}
                                title5={"Others"}
                                id5={"accNew"}
                                itemOpen={data.itemOpen}
                            />
                            <div className="summaryBoxWRP">
                                <div className={data.summaryNewVisible ? 'summaryBoxInr summaryBoxVisible' : 'summaryBoxInr'}>
                                    <div className="summaryBoxTitle">
                                        <span>NEW VEHICLE SUMMARY</span>
                                        <span onClick={() => modalHandler("snVehicle", "", "", "")} className='summaryBoxTitleLink tooltipWrp'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                            </svg>
                                            <div className='tooltipBox' style={{right: "0"}}>Click for full deal breakdown</div>
                                        </span>
                                    </div>
                                    {
                                        showModalNewVehicleSummary ? (
                                            <DialogComp className="popupSitePerformance" maxWidth="lg" title={"NEW VEHICLE SUMMARY"} onClose={() => setShowModalNewVehicleSummary(!showModalNewVehicleSummary)}>   
                                                {
                                                    data.popupSummaryNew ? (
                                                        <CircularIndeterminate type="small" />
                                                    ) : (
                                                        <>
                                                            <div className='exportCSVButton'>
                                                                <CSVLink data={data.resPopup} headers={headCellsPopupVehicle} target="_blank" filename={"NewVehiclesSummary.csv"}>
                                                                    <ViewComfyIcon />
                                                                    Export CSV
                                                                </CSVLink>
                                                            </div>
                                                            <PopupVehicleDetails dataTablePopup={data.resPopup} type={"snVehicle"} />
                                                        </>
                                                    )
                                                }
                                            </DialogComp>
                                        ) : null
                                    }
                                    {
                                        data.summaryNewLoaderRunning ? (
                                            <CircularIndeterminate type="small" />
                                        ) : (
                                            <>
                                                <SummaryCard type="summaryNew" mainData={data.dataAfterFilter} resData={data.resDataSN} lowerLabel={"(excl fleet)"} />
                                            </>
                                        )
                                    }

                                </div>
                                <div className={data.financeNewVisible ? 'summaryBoxInr summaryBoxVisible' : 'summaryBoxInr'}>
                                    <div className="summaryBoxTitle">
                                        <span>NEW VEHICLE FINANCE</span>
                                        <span onClick={() => modalHandler("fnVehicle", "", "", "")} className='summaryBoxTitleLink tooltipWrp'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                            </svg>
                                            <div className='tooltipBox' style={{right: "0"}}>Click for full deal breakdown</div>
                                        </span>
                                    </div>
                                    {
                                        showModalNewVehicleFinance ? (
                                            <DialogComp className="popupSitePerformance" maxWidth="lg" title={"NEW VEHICLE FINANCE"} onClose={() => setShowModalNewVehicleFinance(!showModalNewVehicleFinance)}>   
                                                {
                                                    data.popupFinanceNew ? (
                                                        <CircularIndeterminate type="small" />
                                                    ) : (
                                                        <>
                                                            <div className='exportCSVButton'>
                                                                <CSVLink data={data.resPopup} headers={headCellsPopupVehicle} target="_blank" filename={"NewVehiclesFinance.csv"}>
                                                                    <ViewComfyIcon />
                                                                    Export CSV
                                                                </CSVLink>
                                                            </div>
                                                            <PopupVehicleDetails dataTablePopup={data.resPopup} type={"fnVehicle"} />
                                                        </>
                                                    )
                                                }
                                            </DialogComp>
                                        ) : null
                                    }
                                    {
                                        data.financeNewLoaderRunning ? (
                                            <CircularIndeterminate type="small" />
                                        ) : (
                                            <>
                                                <SummaryCardShared resData={data.resDataFN} resDataMain={data.dataAfterFilter} type="financeNew" lowerLabel={"(excl fleet)"} modalHandler={modalHandler} />
                                            </>
                                        )
                                    }
                                </div>
                                <div className={data.productsNewVisible ? 'summaryBoxInr summaryBoxVisible' : 'summaryBoxInr'}>
                                    <div className="summaryBoxTitle">
                                        <span>NEW VEHICLE PRODUCTS</span>
                                        <span onClick={() => modalHandler("pnVehicle", "", "", "")} className='summaryBoxTitleLink tooltipWrp'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                            </svg>
                                            <div className='tooltipBox' style={{right: "0"}}>Click for full deal breakdown</div>
                                        </span>
                                    </div>
                                    {
                                        showModalNewVehicleProducts ? (
                                            <DialogComp className="popupSitePerformance" maxWidth="lg" title={"NEW VEHICLE PRODUCTS"} onClose={() => setShowModalNewVehicleProducts(!showModalNewVehicleProducts)}>   
                                                {
                                                    data.popupProductsNew ? (
                                                        <CircularIndeterminate type="small" />
                                                    ) : (
                                                        <>
                                                            <div className='exportCSVButton'>
                                                                <CSVLink data={data.resPopup} headers={headCellsPopupVehicle} target="_blank" filename={"NewVehiclesProducts.csv"}>
                                                                    <ViewComfyIcon />
                                                                    Export CSV
                                                                </CSVLink>
                                                            </div>
                                                            <PopupVehicleDetails dataTablePopup={data.resPopup} type={"pnVehicle"} />
                                                        </>
                                                    )
                                                }
                                            </DialogComp>
                                        ) : null
                                    }
                                    {
                                        data.productsNewLoaderRunning ? (
                                            <CircularIndeterminate type="small" />
                                        ) : (
                                            <>
                                                <SummaryCardShared resData={data.resDataPN} resDataMain={data.dataAfterFilter} type="productsNew" lowerLabel={"(excl fleet)"} modalHandler={modalHandler} />
                                            </>
                                        )
                                    }
                                </div>
                                {/* <div className={data.warrantyNewVisible ? 'summaryBoxInr summaryBoxVisible' : 'summaryBoxInr'}>
                                    <div className="summaryBoxTitle">
                                        <span>NEW VEHICLE WARRANTY</span>
                                        <span onClick={e => handleRedirect(e, '/new_invoice')} className='summaryBoxTitleLink'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                            </svg>
                                        </span>
                                    </div>
                                    {
                                        data.warrantyNewLoaderRunning ? (
                                            <CircularIndeterminate type="small" />
                                        ) : (
                                            <>
                                                <SummaryCardShared resData={data.resDataWN} resDataMain={data.dataAfterFilter} type="warrantyNew" lowerLabel={"(excl fleet)"} />
                                            </>
                                        )
                                    }
                                </div> */}
                                <div className={data.accessoryNewVisible ? 'summaryBoxInr summaryBoxVisible' : 'summaryBoxInr'}>
                                    <div className="summaryBoxTitle">
                                        <span>NEW VEHICLE OTHERS</span>
                                        <span onClick={() => modalHandler("anVehicle", data.resDataAN.lookupList, "", "R")} className='summaryBoxTitleLink tooltipWrp'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                            </svg>
                                            <div className='tooltipBox' style={{right: "0"}}>Click for full deal breakdown</div>
                                        </span>
                                    </div>
                                    {
                                        showModalNewVehicleAcc ? (
                                            <DialogComp className="popupSitePerformance" maxWidth="lg" title={"NEW VEHICLE OTHERS"} onClose={() => setShowModalNewVehicleAcc(!showModalNewVehicleAcc)}>   
                                                {
                                                    data.popupAccNew ? (
                                                        <CircularIndeterminate type="small" />
                                                    ) : (
                                                        <>
                                                            <span>{data.popupTitle !== null ? data.popupTitle : ""}</span>
                                                            <div className='exportCSVButton'>
                                                                <CSVLink data={data.resPopup} headers={headCellsPopupVehicle} target="_blank" filename={"NewVehiclesOthers.csv"}>
                                                                    <ViewComfyIcon />
                                                                    Export CSV
                                                                </CSVLink>
                                                            </div>
                                                            <PopupVehicleDetails dataTablePopup={data.resPopup} type={"anVehicle"} />
                                                        </>
                                                    )
                                                }
                                            </DialogComp>
                                        ) : null
                                    }
                                    {
                                        data.accessoryNewLoaderRunning ? (
                                            <CircularIndeterminate type="small" />
                                        ) : (
                                            <>
                                                <SummaryCardShared resData={data.resDataAN} resDataMain={data.dataAfterFilter} type="accNew" lowerLabel={"(excl fleet)"} modalHandler={modalHandler} />
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid className='summaryBox'>
                            <div className='summaryBoxInr'>
                                <SummaryBoxNav
                                    title1={"Summary"}
                                    clickHandler1={handlerClickNavTab}
                                    id1={"summaryUsed"}
                                    title2={"Finance"}
                                    id2={"financeUsed"}
                                    title3={"Products"}
                                    id3={"productsUsed"}
                                    title4={"Warranty"}
                                    id4={"warrantyUsed"}
                                    title5={"Others"}
                                    id5={"accUsed"}
                                    itemOpen={data.itemOpenUsed}
                                />
                                <div className="summaryBoxWRP">
                                    <div className={data.summaryUsedVisible ? 'summaryBoxInr summaryBoxVisible' : 'summaryBoxInr'}>
                                        <div className="summaryBoxTitle">
                                            <span>USED VEHICLE SUMMARY</span>
                                            <span onClick={() => modalHandler("suVehicle", "", "", "")} className='summaryBoxTitleLink tooltipWrp'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                    <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                                </svg>
                                                <div className='tooltipBox' style={{right: "0"}}>Click for full deal breakdown</div>
                                            </span>
                                        </div>
                                        {
                                            showModalUsedVehicleSummary ? (
                                                <DialogComp className="popupSitePerformance" maxWidth="lg" title={"USED VEHICLE SUMMARY"} onClose={() => setShowModalUsedVehicleSummary(!showModalUsedVehicleSummary)}>   
                                                    {
                                                        data.popupSummaryUsed ? (
                                                            <CircularIndeterminate type="small" />
                                                        ) : (
                                                            <>
                                                                <div className='exportCSVButton'>
                                                                    <CSVLink data={data.resPopup} headers={headCellsPopupVehicle} target="_blank" filename={"UsedVehiclesSummary.csv"}>
                                                                        <ViewComfyIcon />
                                                                        Export CSV
                                                                    </CSVLink>
                                                                </div>
                                                                <PopupVehicleDetails dataTablePopup={data.resPopup} type={"suVehicle"} />
                                                            </>
                                                        )
                                                    }
                                                </DialogComp>
                                            ) : null
                                        }
                                        {
                                            data.summaryUsedLoaderRunning ? (
                                                <CircularIndeterminate type="small" />
                                            ) : (
                                                <>
                                                    <SummaryCard type="summaryUsed" mainData={data.dataAfterFilter} resData={data.resDataSU} lowerLabel={"(excl trade)"} />
                                                </>
                                            )
                                        }

                                    </div>
                                    <div className={data.financeUsedVisible ? 'summaryBoxInr summaryBoxVisible' : 'summaryBoxInr'}>
                                        <div className="summaryBoxTitle">
                                            <span>USED VEHICLE FINANCE</span>
                                            <span onClick={() => modalHandler("fuVehicle", "", "", "")} className='summaryBoxTitleLink tooltipWrp'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                    <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                                </svg>
                                                <div className='tooltipBox' style={{right: "0"}}>Click for full deal breakdown</div>
                                            </span>
                                        </div>
                                        {
                                            showModalUsedVehicleFinance ? (
                                                <DialogComp className="popupSitePerformance" maxWidth="lg" title={"USED VEHICLE FINANCE"} onClose={() => setShowModalUsedVehicleFinance(!showModalUsedVehicleFinance)}>   
                                                    {
                                                        data.popupFinanceUsed ? (
                                                            <CircularIndeterminate type="small" />
                                                        ) : (
                                                            <>
                                                                <div className='exportCSVButton'>
                                                                    <CSVLink data={data.resPopup} headers={headCellsPopupVehicle} target="_blank" filename={"UsedVehiclesFinance.csv"}>
                                                                        <ViewComfyIcon />
                                                                        Export CSV
                                                                    </CSVLink>
                                                                </div>
                                                                <PopupVehicleDetails dataTablePopup={data.resPopup} type={"fuVehicle"} />
                                                            </>
                                                        )
                                                    }
                                                </DialogComp>
                                            ) : null
                                        }
                                        {
                                            data.financeUsedLoaderRunning ? (
                                                <CircularIndeterminate type="small" />
                                            ) : (
                                                <>
                                                    <SummaryCardShared resData={data.resDataFU} resDataMain={data.dataAfterFilter} type="financeUsed" lowerLabel={"(excl trade)"} modalHandler={modalHandler} />
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className={data.productsUsedVisible ? 'summaryBoxInr summaryBoxVisible' : 'summaryBoxInr'}>
                                        <div className="summaryBoxTitle">
                                            <span>USED VEHICLE PRODUCTS</span>
                                            <span onClick={() => modalHandler("puVehicle", "", "", "")} className='summaryBoxTitleLink tooltipWrp'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                    <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                                </svg>
                                                <div className='tooltipBox' style={{right: "0"}}>Click for full deal breakdown</div>
                                            </span>
                                        </div>
                                        {
                                            showModalUsedVehicleProducts ? (
                                                <DialogComp className="popupSitePerformance" maxWidth="lg" title={"USED VEHICLE PRODUCTS"} onClose={() => setShowModalUsedVehicleProducts(!showModalUsedVehicleProducts)}>   
                                                    {
                                                        data.popupProductsUsed ? (
                                                            <CircularIndeterminate type="small" />
                                                        ) : (
                                                            <>
                                                                <div className='exportCSVButton'>
                                                                    <CSVLink data={data.resPopup} headers={headCellsPopupVehicle} target="_blank" filename={"UsedVehiclesProducts.csv"}>
                                                                        <ViewComfyIcon />
                                                                        Export CSV
                                                                    </CSVLink>
                                                                </div>
                                                                <PopupVehicleDetails dataTablePopup={data.resPopup} type={"puVehicle"} />
                                                            </>
                                                        )
                                                    }
                                                </DialogComp>
                                            ) : null
                                        }
                                        {
                                            data.productsUsedLoaderRunning ? (
                                                <CircularIndeterminate type="small" />
                                            ) : (
                                                <>
                                                    <SummaryCardShared resData={data.resDataPU} resDataMain={data.dataAfterFilter} type="productsUsed" lowerLabel={"(excl trade)"} modalHandler={modalHandler} />
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className={data.warrantyUsedVisible ? 'summaryBoxInr summaryBoxVisible' : 'summaryBoxInr'}>
                                        <div className="summaryBoxTitle">
                                            <span>USED VEHICLE WARRANTY</span>
                                            <span onClick={() => modalHandler("wuVehicleList", data.resDataWU.lookUpList, "", "")} className='summaryBoxTitleLink tooltipWrp'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                    <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                                </svg>
                                                <div className='tooltipBox' style={{right: "0"}}>Click for full deal breakdown</div>
                                            </span>
                                        </div>
                                        {
                                            showModalUsedVehicleWarranty ? (
                                                <DialogComp className="popupSitePerformance" maxWidth="lg" title={"USED VEHICLE WARRANTY"} onClose={() => setShowModalUsedVehicleWarranty(!showModalUsedVehicleWarranty)}>   
                                                    {
                                                        data.popupWarrantyUsed ? (
                                                            <CircularIndeterminate type="small" />
                                                        ) : (
                                                            <>
                                                                <div className='exportCSVButton'>
                                                                    <CSVLink data={data.resPopup} headers={headCellsPopupVehicle} target="_blank" filename={"UsedVehiclesWarranty.csv"}>
                                                                        <ViewComfyIcon />
                                                                        Export CSV
                                                                    </CSVLink>
                                                                </div>
                                                                <PopupVehicleDetails dataTablePopup={data.resPopup} type={"wuVehicle"} />
                                                            </>
                                                        )
                                                    }
                                                </DialogComp>
                                            ) : null
                                        }
                                        {
                                            data.warrantyUsedLoaderRunning ? (
                                                <CircularIndeterminate type="small" />
                                            ) : (
                                                <>
                                                    <SummaryCardShared resData={data.resDataWU} resDataMain={data.dataAfterFilter} type="warrantyUsed" lowerLabel={"(excl trade)"} modalHandler={modalHandler} />
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className={data.accessoryUsedVisible ? 'summaryBoxInr summaryBoxVisible' : 'summaryBoxInr'}>
                                        <div className="summaryBoxTitle">
                                            <span>USED VEHICLE OTHERS</span>
                                            <span onClick={() => modalHandler("auVehicle", data.resDataAU.lookupList, "", "R")} className='summaryBoxTitleLink tooltipWrp'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                    <path d="M24.6261 14.3854C23.8684 14.3854 23.2535 15.0004 23.2535 15.758V23.2535H2.7452V2.7452H10.308C11.0656 2.7452 11.6806 2.13022 11.6806 1.3726C11.6806 0.614978 11.0656 0 10.308 0H2.27017C1.01868 0 0 1.01868 0 2.27017V23.7298C0 24.9813 1.01868 26 2.27017 26H23.7298C24.9813 26 26 24.9813 26 23.7298V15.758C25.9987 15.0004 25.3837 14.3854 24.6261 14.3854ZM23.8281 0H16.8964C16.1388 0 15.5238 0.614978 15.5238 1.3726C15.5238 2.13022 16.1388 2.7452 16.8964 2.7452H21.3251L10.5138 13.5564C9.97826 14.092 9.97826 14.9613 10.5138 15.4969C10.7816 15.7647 11.1329 15.8993 11.4841 15.8993C11.8353 15.8993 12.1865 15.7647 12.4543 15.4969L23.2535 4.69914V9.13721C23.2535 9.89483 23.8684 10.5098 24.6261 10.5098C25.3837 10.5098 25.9987 9.89483 25.9987 9.13721V2.17059C25.9987 0.972931 25.0257 0 23.8281 0Z" fill="black" />
                                                </svg>
                                                <div className='tooltipBox' style={{right: "0"}}>Click for full deal breakdown</div>
                                            </span>
                                        </div>
                                        {
                                            showModalUsedVehicleAcc ? (
                                                <DialogComp className="popupSitePerformance" maxWidth="lg" title={"USED VEHICLE OTHERS"} onClose={() => setShowModalUsedVehicleAcc(!showModalUsedVehicleAcc)}>   
                                                    {
                                                        data.popupAccUsed ? (
                                                            <CircularIndeterminate type="small" />
                                                        ) : (
                                                            <>
                                                                <span>{data.popupTitle !== null ? data.popupTitle : ""}</span>
                                                                <div className='exportCSVButton'>
                                                                    <CSVLink data={data.resPopup} headers={headCellsPopupVehicle} target="_blank" filename={"UsedVehiclesOthers.csv"}>
                                                                        <ViewComfyIcon />
                                                                        Export CSV
                                                                    </CSVLink>
                                                                </div>
                                                                <PopupVehicleDetails dataTablePopup={data.resPopup} type={"auVehicle"} />
                                                            </>
                                                        )
                                                    }
                                                </DialogComp>
                                            ) : null
                                        }
                                        {
                                            data.accessoryUsedLoaderRunning ? (
                                                <CircularIndeterminate type="small" />
                                            ) : (
                                                <>
                                                    <SummaryCardShared resData={data.resDataAU} resDataMain={data.dataAfterFilter} type="accUsed" lowerLabel={"(excl trade)"} modalHandler={modalHandler} />
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='salesExecPerformance'>
                        <div className='salesExecTable'>
                            <span className='salesExecTitle'>SALES TEAM PERFORMANCE</span>
                            <span className='salesExecSub'>Select categories</span>
                            <div className="exportCSVButton">
                                <CSVLink data={data.resDataToFilter} headers={headCellsSalesExec} target="_blank" filename={"SalesTeamPerformance.csv"}>
                                    <ViewComfyIcon />
                                    Export CSV
                                </CSVLink>
                            </div>
                                <Grid item xs={12}>
                                    <div className='salesExecSwitch'>
                                        <FormControlLabel
                                            control={<Switch checked={data.salesExecNew} onChange={() => getSalesExec("newCars")} name="salesExecNew" id="salesExecNew" />}
                                            label="New Cars"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={data.salesExecUsed} onChange={() => getSalesExec("usedCars")} name="salesExecUsed" />}
                                            label="Used Cars"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={data.salesExecMotab} onChange={() => getSalesExec("motabCars")} name="salesExecMotab" />}
                                            label="Motability"
                                            labelPlacement="end"
                                        />
                                    </div>
                                    {data.salesExecRunning ? (
                                        <CircularIndeterminate type="small" />
                                    ) : <>
                                            <SalesExecTable execData={data.resDataSE} />
                                        </>
                                    }
                                </Grid>
                        </div>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default SitePerformance;
