import {
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Table,
    TableBody
} from '@material-ui/core';
import { TextBox } from '../../../Core/FormInput';
import { useState, useEffect } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import { some } from 'lodash';
import { Grid, FormLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { CSVLink } from "react-csv";

export default function UsedStockReportTable(props) {
    const { StockReportDataTable, updatePrice, openCapPopup } = props;
    const [canEdit, setCanEdit] = useState((localStorage.getItem('_canEditUsedStockReport_') || '').toUpperCase() === 'TRUE' || false);
    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
    const [stockData, setStockData] = useState({
        tableData: StockReportDataTable,
        filterTableData: [],
        filterText: ''
    });

    const [selectTab, setSelectTab] = useState("vehicle");

    const [actionFilter, setActionFilter] = useState('all');
    let searchTimer;

    const headCellsFixed = [
        {
            id: "site",
            label: "Site",
            classLabel: "columnSize60"
        },
        {
            id: "regNo",
            label: "Reg No",
            classLabel: "columnSize80"
        },
        {
            id: "make",
            label: "Make",
            classLabel: "columnSize95"
        },
        {
            id: "model",
            label: "Model",
            classLabel: "columnSize200"
        },
        {
            id: "retailPrice",
            label: "Retail Price",
            classLabel: "columnSize80"
        },
        {
            id: "mileage",
            label: "Mileage",
            classLabel: "columnSize80"
        },
        {
            id: "dis",
            label: "DIS",
            classLabel: "columnSize60"
        }
    ];

    const headsCellsVehicle = [
        {
            id: "stockNo",
            label: "Stock No",
            classLabel: "columnSize80"
        },
        {
            id: "specification",
            label: "Specification",
            classLabel: "columnSize250"
        },
        {
            id: "transmission",
            label: "Transmission",
            classLabel: "columnSize95"
        },
        {
            id: "fuelType",
            label: "Fuel Type",
            classLabel: "columnSize95"
        },
        {
            id: "colour",
            label: "Colour",
            classLabel: "columnSize95"
        },
        {
            id: "modelCode",
            label: "Model Code",
            classLabel: "columnSize95"
        },
        {
            id: "dor",
            label: "DOR",
            classLabel: "columnSize95"
        },
        {
            id: "qualifyingForVAT",
            label: "VAT Status",
            classLabel: "columnSize95"
        },
        {
            id: "progressCode",
            label: "Progress Code",
            classLabel: "columnSize95"
        }
    ];

    const headsCellsPrice = [
        {
            id: "pricePosition",
            label: "Price Position",
            classLabel: "columnSize95"
        },
        {
            id: "atPriceIndicator",
            label: "AT Price Indicator",
            classLabel: "columnSize120"
        },
        {
            id: "daysSinceChange",
            label: "Days since change",
            classLabel: "columnSize120"
        },
        {
            id: "noOfChanges",
            label: "No of changes",
            classLabel: "columnSize95"
        },
        {
            id: "lastPriceChange",
            label: "Last price change",
            classLabel: "columnSize120"
        },
        {
            id: "totalPriceChange",
            label: "Total price change",
            classLabel: "columnSize120"
        },
        {
            id: "standInValue",
            label: "SIV",
            classLabel: "columnSize95" 
        },
        {
            id: "total",
            label: "Total (excl VAT)",
            classLabel: "columnSize95" 
        },
        {
            id: "margin",
            label: "Margin",
            classLabel: "columnSize95" 
        }
    ];

    const headsCellsValuation = [
        {
            id: "atRetailValue",
            label: "AT Retail Value",
            classLabel: "columnSize120"
        },
        {
            id: "atVariance",
            label: "AT Variance",
            classLabel: "columnSize120"
        },
        {
            id: "capClean",
            label: "CAP Clean",
            classLabel: "columnSize120"
        },
        {
            id: "capVariance",
            label: "CAP Variance",
            classLabel: "columnSize120"
        },
        {
            id: "standInValue",
            label: "SIV",
            classLabel: "columnSize95"
        },
        {
            id: "daysToSell",
            label: "Days to Sell",
            classLabel: "columnSize95"
        },
        {
            id: "forecastValuation",
            label: "Forecast Valuation",
            classLabel: "columnSize120"
        },
        {
            id: "capId",
            label: "Cap Code",
            classLabel: "columnSize80"
        }
    ];

    const headsCellsMarket = [
        {
            id: "atRetailRating",
            label: "AT Retail Rating",
            classLabel: "columnSize95"
        },
        {
            id: "atPerformanceRating",
            label: "AT Performance Rating",
            classLabel: "columnSize150"
        },
        {
            id: "atPriceIndicator",
            label: "AT Price Indicator",
            classLabel: "columnSize120"
        },
        {
            id: "daysToSell",
            label: "Days to Sell",
            classLabel: "columnSize95"
        },
        {
            id: "marketCondition",
            label: "Market Condition",
            classLabel: "columnSize100"
        },
        {
            id: "attentionGrabber",
            label: "Attention Grabber",
            classLabel: "columnSize100"
        },
        {
            id: "photos",
            label: "Photos",
            classLabel: "columnSize80"
        },
        {
            id: "atAdvertViews",
            label: "AT Advert Views",
            classLabel: "columnSize100"
        },
        {
            id: "atLink",
            label: "AutoTrader Link",
            classLabel: "columnSize95"
        },
        {
            id: "chorleyLink",
            label: "Chorley Group Link",
            classLabel: "columnSize120"
        }
    ];

    const headsCellsPrep = [
        {
            id: "vehiclePrep",
            label: "Vehicle Prepped",
            classLabel: "columnSize120"
        },
        {
            id: "estimatedMechPrep",
            label: "Estimated Mech Prep",
            classLabel: "columnSize150"
        },
        {
            id: "actualMechPrep",
            label: "Actual Mech Prep",
            classLabel: "columnSize150"
        },
        {
            id: "estimatedBodywork",
            label: "Estimated  Body Work",
            classLabel: "columnSize150"
        },
        {
            id: "actualBodywork",
            label: "Actual Body Work",
            classLabel: "columnSize150"
        },
        {
            id: "motExpiry",
            label: "MOT Expiry Date",
            classLabel: "columnSize150"
        }
    ];

    const headsCellsActions = [
        {
            id: "noPrice",
            label: "No Price",
            classLabel: "columnSize80"
        },
        {
            id: "noPhotos",
            label: "No Photos",
            classLabel: "columnSize80"
        },
        {
            id: "noAttGrabber",
            label: "No Att Grabber",
            classLabel: "columnSize100"
        },
        {
            id: "toPrep",
            label: "To Prep",
            classLabel: "columnSize80"
        },
        {
            id: "priceChange",
            label: "Price Change",
            classLabel: "columnSize80"
        },
        {
            id: "noCapCode",
            label: "No CAP Code",
            classLabel: "columnSize80"
        },
        {
            id: "lowLeads",
            label: "Low Leads",
            classLabel: "columnSize80"
        },
        {
            id: "reserved10+Days",
            label: "Reserved 10+ Days",
            classLabel: "columnSize120"
        },
    ];

    const popupHeads = [
        {
            key: "site",
            label: "Site",
        },
        {
            key: "regNo",
            label: "Reg No",
        },
        {
            key: "make",
            label: "Make",
        },
        {
            key: "model",
            label: "Model",
        },
        {
            key: "retailPrice",
            label: "Retail Price",
        },
        {
            key: "mileage",
            label: "Mileage",
        },
        {
            key: "dis",
            label: "DIS",
        },
		{
            key: "stockNo",
            label: "Stock No",
        },
        {
            key: "specification",
            label: "Specification",
        },
        {
            key: "transmission",
            label: "Transmission",
        },
        {
            key: "fuelType",
            label: "Fuel Type",
        },
        {
            key: "colour",
            label: "Colour",
        },
        {
            key: "modelCode",
            label: "Model Code",
        },
        {
            key: "dor",
            label: "DOR",
        },
        {
            key: "qualifyingForVAT",
            label: "VAT Status",
        },
        {
            key: "progressCode",
            label: "Progress Code",
        },
		{
            key: "pricePosition",
            label: "Price Position",
        },
        {
            key: "atPriceIndicator",
            label: "AT Price Indicator",
        },
        {
            key: "daysSinceChange",
            label: "Days since change",
        },
        {
            key: "noOfChanges",
            label: "No of changes",
        },
        {
            key: "lastPriceChange",
            label: "Last price change",
        },
        {
            key: "totalPriceChange",
            label: "Total price change",
        },
        {
            key: "standInValue",
            label: "SIV",
        },
        {
            key: "total",
            label: "Total (excl VAT)",
        },
        {
            key: "margin",
            label: "Margin",
        },
		{
            key: "atRetailValue",
            label: "AT Retail Value",
        },
        {
            key: "atVariance",
            label: "AT Variance",
        },
        {
            key: "capClean",
            label: "CAP Clean",
        },
        {
            key: "capVariance",
            label: "CAP Variance",
        },
        {
            key: "standInValue",
            label: "SIV",
        },
        {
            key: "daysToSell",
            label: "Days to Sell",
        },
        {
            key: "forecastValuation",
            label: "Forecast Valuation",
        },
        {
            key: "capId",
            label: "Cap Code",
        },
		{
            key: "atRetailRating",
            label: "AT Retail Rating",
        },
        {
            key: "atPerformanceRating",
            label: "AT Performance Rating",
        },
        {
            key: "atPriceIndicator",
            label: "AT Price Indicator",
        },
        {
            key: "daysToSell",
            label: "Days to Sell",
        },
        {
            key: "marketCondition",
            label: "Market Condition",
        },
        {
            key: "attentionGrabber",
            label: "Attention Grabber",
        },
        {
            key: "photos",
            label: "Photos",
        },
        {
            key: "atAdvertViews",
            label: "AT Advert Views",
        },
        {
            key: "atLink",
            label: "AutoTrader Link",
        },
        {
            key: "chorleyLink",
            label: "Chorley Group Link",
        },
		{
            key: "vehiclePrep",
            label: "Vehicle Prepped",
        },
        {
            key: "estimatedMechPrep",
            label: "Estimated Mech Prep",
        },
        {
            key: "actualMechPrep",
            label: "Actual Mech Prep",
        },
        {
            key: "estimatedBodywork",
            label: "Estimated  Body Work",
        },
        {
            key: "actualBodywork",
            label: "Actual Body Work",
        },
        {
            key: "motExpiry",
            label: "MOT Expiry Date",
        },
		{
            key: "noPrice",
            label: "No Price",
        },
        {
            key: "noPhotos",
            label: "No Photos",
        },
        {
            key: "noAttGrabber",
            label: "No Att Grabber",
        },
        {
            key: "toPrep",
            label: "To Prep",
        },
        {
            key: "priceChange",
            label: "Price Change",
        },
        {
            key: "noCapCode",
            label: "No CAP Code",
        },
        {
            key: "lowLeads",
            label: "Low Leads",
        },
        {
            key: "reserved10+Days",
            label: "Reserved 10+ Days",
        }
    ]

    const extractSiteName = function (name) {
        let site = name.split(' ')[0];
        let brand = name.split(' ')[1];

        let siteBrand = site[0] + site[1] + site[2] + "-" + brand[0];

        return siteBrand.toUpperCase();
    };

    const setFilterText = (e) => {
        const vl = e.target.value;
        setStockData((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setStockData((st) => {
            const newSt = { ...st };
            const { filterText, tableData } = newSt;
            let filterd = [];
            filterd = [...tableData];

            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }
            newSt.filterTableData = filterd;
            return newSt;
        });
    };

    const actionFilterHandler = (e) => {
        let type = e.target.value;
        setActionFilter(type);

        if (type === "all") {
            setStockData((st) => {
                const newSt = { ...st };
                const { tableData } = newSt;
                let filterd = [];
                filterd = [...tableData];
                
                newSt.filterTableData = filterd;
                return newSt;
            });
        }
        
        if (type === "noPrice") {
            setStockData((st) => {
                const newSt = { ...st };
                const { tableData } = newSt;
                let filterd = [];
                filterd = [...tableData];

                filterd = filterd.filter((rw) => rw.retailPrice === 0 && rw.progressCode !== "R");
                newSt.filterTableData = filterd;
                return newSt;
            });
        }

        if (type === "noPhotos") {
            setStockData((st) => {
                const newSt = { ...st };
                const { tableData } = newSt;
                let filterd = [];
                filterd = [...tableData];

                filterd = filterd.filter((rw) => rw.photos === 0);
                newSt.filterTableData = filterd;
                return newSt;
            });
        }

        if (type === "noAttGrabber") {
            setStockData((st) => {
                const newSt = { ...st };
                const { tableData } = newSt;
                let filterd = [];
                filterd = [...tableData];

                filterd = filterd.filter((rw) => rw.attentionGrabber === "No");
                newSt.filterTableData = filterd;
                return newSt;
            });
        }

        if (type === "toPrep") {
            setStockData((st) => {
                const newSt = { ...st };
                const { tableData } = newSt;
                let filterd = [];
                filterd = [...tableData];

                filterd = filterd.filter((rw) => rw.vehiclePrep === "No");
                newSt.filterTableData = filterd;
                return newSt;
            });
        }

        if (type === "priceChange") {
            setStockData((st) => {
                const newSt = { ...st };
                const { tableData } = newSt;
                let filterd = [];
                filterd = [...tableData];
                filterd = filterd.filter((rw) => rw.daysSinceChange >= 7);
                newSt.filterTableData = filterd;
                return newSt;
            });
        }

        if (type === "noCapCode") {
            setStockData((st) => {
                const newSt = { ...st };
                const { tableData } = newSt;
                let filterd = [];
                filterd = [...tableData];
                filterd = filterd.filter((rw) => rw.capId === null);
                newSt.filterTableData = filterd;
                return newSt;
            });
        }

        if (type === "lowLeads") {
            // setStockData((st) => {
            //     const newSt = { ...st };
            //     const { tableData } = newSt;
            //     let filterd = [];
            //     filterd = [...tableData];

            //     filterd = filterd.filter((rw) => rw.retailPrice === 0);
            //     newSt.filterTableData = filterd;
            //     return newSt;
            // });
        }

        if (type === "reserved") {
            setStockData((st) => {
                const newSt = { ...st };
                const { tableData } = newSt;
                let filterd = [];
                filterd = [...tableData];
                filterd = filterd.filter((rw) => rw.progressCode === "R");
                newSt.filterTableData = filterd;
                return newSt;
            });
        }
    }

    useEffect(() => {
        setFilteredRow();
    }, [stockData.tableData]);

    const PriceIndicator = (props) => {
        return (
            props.indicator === "Good Price" ? <>
               <span className='priceIndicator good'>{props.indicator}</span>
            </> : <></>
        )
    }

    const PerformanceRating = (props) => {
        return (
            props.indicator === "Above Avg" ? <>
               <span className='priceIndicator good'>{props.indicator}</span>
            </> : <></>
        )
    }

    const RetailRating = (props) => {
        return (
            props.rating > 0 && props.rating < 50 ? <>
                <span className='yellow'>{props.rating+"/100"}</span>
            </> : <></>
        )
    }

    const getScroll = (e) => {
        const target = e.target;

        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, scrollTablePosition: target.scrollTop }));
    }

    const GreenCheck = () => {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M6.3335 8.63642L8.11127 10.3334L11.6668 6.93945" stroke="#4F8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <ellipse cx="9" cy="8.63636" rx="8" ry="7.63636" stroke="#4F8000" stroke-width="2"/>
                </svg>
            </>
        );
    }

    const RedCheck = () => {
        return (
            <>
                <svg fill="#CE2020" height="18" width="18" viewBox="0 0 27.963 27.963">
                    <g>
                        <g id="c129_exclamation">
                            <path d="M13.983,0C6.261,0,0.001,6.259,0.001,13.979c0,7.724,6.26,13.984,13.982,13.984s13.98-6.261,13.98-13.984
                                C27.963,6.259,21.705,0,13.983,0z M13.983,26.531c-6.933,0-12.55-5.62-12.55-12.553c0-6.93,5.617-12.548,12.55-12.548
                                c6.931,0,12.549,5.618,12.549,12.548C26.531,20.911,20.913,26.531,13.983,26.531z"/>
                            <polygon points="15.579,17.158 16.191,4.579 11.804,4.579 12.414,17.158 		"/>
                            <path d="M13.998,18.546c-1.471,0-2.5,1.029-2.5,2.526c0,1.443,0.999,2.528,2.444,2.528h0.056c1.499,0,2.469-1.085,2.469-2.528
                                C16.441,19.575,15.468,18.546,13.998,18.546z"/>
                        </g>
                        <g id="Capa_1_207_">
                        </g>
                    </g>
                </svg>
            </>
        );
    }

    return (
        <>
            <div className='stockTableHeader'>
                <div style={{position: "relative"}}>
                    <span className='stockTableHeaderTitle'>STOCK LIST</span>
                    <div className='exportCSVButton'>
                        <CSVLink data={stockData.filterTableData} headers={popupHeads} target="_blank" filename={"StockList.csv"}>
                            <ViewComfyIcon />
                            Export CSV
                        </CSVLink>
                    </div>
                </div>
                <div className='stockTableHeaderFilters'>
                    <Grid item className='filterTopPage'>
                        <FormLabel className='labelInput'>
                            Action Filter
                        </FormLabel>
                        <Select 
                            className='customSelectFilterSingle'
                            onChange={actionFilterHandler}
                            value={actionFilter}
                            id="actionFilter"
                            name="actionFilter"
                        >
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"noPrice"}>No Price</MenuItem>
                            <MenuItem value={"noPhotos"}>No Photos</MenuItem>
                            <MenuItem value={"noAttGrabber"}>No Att Grabber</MenuItem>
                            <MenuItem value={"toPrep"}>To Prep</MenuItem>
                            <MenuItem value={"priceChange"}>Price Change</MenuItem>
                            <MenuItem value={"noCapCode"}>No CAP Code</MenuItem>
                            <MenuItem value={"lowLeads"}>Low Leads</MenuItem>
                            <MenuItem value={"reserved"}>Reserved 10+Day</MenuItem>
                        </Select>

                        <input type="hidden" onClick={actionFilterHandler} value="noPhotos" id="noPhotosInput" />
                        <input type="hidden" onClick={actionFilterHandler} value="noAttGrabber" id="noAttGrabberInput" />
                        <input type="hidden" onClick={actionFilterHandler} value="toPrep" id="toPrepInput" />
                        <input type="hidden" onClick={actionFilterHandler} value="priceChange" id="priceChangeInput" />
                    </Grid>
                    <TextBox className="customTextBox" required name="Search" label="Search" onChange={setFilterText} value={stockData.filterText} />
                    <span className={selectTab === "vehicle" ? "tableSelectButtons active" : "tableSelectButtons" } onClick={() => setSelectTab("vehicle")}>VEHICLE</span>
                    <span className={selectTab === "price" ? "tableSelectButtons active" : "tableSelectButtons" } onClick={() => setSelectTab("price")}>PRICE</span>
                    <span className={selectTab === "valuation" ? "tableSelectButtons active" : "tableSelectButtons" } onClick={() => setSelectTab("valuation")}>VALUATION</span>
                    <span className={selectTab === "market" ? "tableSelectButtons active" : "tableSelectButtons" } onClick={() => setSelectTab("market")}>MARKET</span>
                    <span className={selectTab === "prep" ? "tableSelectButtons active" : "tableSelectButtons" } onClick={() => setSelectTab("prep")}>PREP</span>
                    <span className={selectTab === "actions" ? "tableSelectButtons active" : "tableSelectButtons" } onClick={() => setSelectTab("actions")}>ACTIONS</span>
                </div>
            </div>
            <TableContainer className='tableUsedStockReport' id="tableStockScroll" onScroll={getScroll}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <th className='stickyHeaderCustom'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headCellsFixed.map((headCell) => (
                                                <TableCell className={headCell.classLabel}
                                                    key={headCell.id}
                                                >
                                                    {headCell.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </th>
                            <th className={selectTab === "vehicle" ? 'headerCustomFinal' : 'headerCustomFinal hidden'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headsCellsVehicle.map((headCell) => (
                                                <TableCell className={headCell.classLabel}
                                                    key={headCell.id}
                                                >
                                                    {headCell.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </th>
                            <th className={selectTab === "price" ? 'headerCustomFinal' : 'headerCustomFinal hidden'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headsCellsPrice.map((headCell) => (
                                                <TableCell className={headCell.classLabel}
                                                    key={headCell.id}
                                                >
                                                    {headCell.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </th>
                            <th className={selectTab === "valuation" ? 'headerCustomFinal' : 'headerCustomFinal hidden'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headsCellsValuation.map((headCell) => (
                                                <TableCell className={headCell.classLabel}
                                                    key={headCell.id}
                                                >
                                                    {headCell.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </th>
                            <th className={selectTab === "market" ? 'headerCustomFinal' : 'headerCustomFinal hidden'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headsCellsMarket.map((headCell) => (
                                                <TableCell className={headCell.classLabel}
                                                    key={headCell.id}
                                                >
                                                    {headCell.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </th>
                            <th className={selectTab === "prep" ? 'headerCustomFinal' : 'headerCustomFinal hidden'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headsCellsPrep.map((headCell) => (
                                                <TableCell className={headCell.classLabel}
                                                    key={headCell.id}
                                                >
                                                    {headCell.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </th>
                            <th className={selectTab === "actions" ? 'headerCustomFinal' : 'headerCustomFinal hidden'}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headsCellsActions.map((headCell) => (
                                                <TableCell className={headCell.classLabel}
                                                    key={headCell.id}
                                                    style={{textAlign: "center"}}
                                                >
                                                    {headCell.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </th>
                        </TableRow>
                    </TableHead>
                    <TableBody className='bodyTableCustom'>
                        {stockData.filterTableData.map((row, i) => (
                            <TableRow key={i} className={row.progressCode === 'R' ? "rowTableCustom reserved" : "rowTableCustom"}>
                                <td className='stickyColumnsCustom'>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className='columnSize60'>
                                                    {extractSiteName(row.site)}
                                                </TableCell>
                                                <TableCell className='columnSize80'>
                                                    {row.regNo}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {row.make}
                                                </TableCell>
                                                <TableCell className='columnSize200'>
                                                    {row.model}
                                                </TableCell>
                                                <TableCell className='columnSize80'>
                                                    {
                                                        row.progressCode === 'R' ? <><span style={{ fontWeight: "bold" }}>RESERVED</span></> :
                                                            <>
                                                                <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.retailPrice)}
                                                                    {
                                                                        canEdit ?
                                                                            <>
                                                                                <span onClick={() => updatePrice(row.branchID, row.regNo, row.stockNO, row.retailPrice, row.mileage, row.vehicleDescription, extractSiteName(row.site), row.lastUpdateMileage, row.lasUpdatePrice, row.priceUpdateUser, row.mileageUpdateUser)} className='spanEdit'>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                                                        <path d="M9.29388 3.42704L5.74481 3.44603C3.13134 3.46001 1.02409 5.59943 1.03814 8.22456L1.07627 15.3544C1.09031 17.9795 3.22033 20.0963 5.83379 20.0823L12.9319 20.0443C15.5454 20.0304 17.6526 17.8909 17.6386 15.2658L17.6132 10.5126M13.2995 3.53462L8.29347 9.25727C8.20263 9.3618 8.14925 9.48866 8.14391 9.61266L8.15888 12.348C8.14644 12.637 8.56095 12.9973 8.85999 12.9278L11.5187 12.3102C11.647 12.2804 11.7686 12.2058 11.8595 12.1013L16.9594 5.94779M13.2995 3.53462L15.0178 1.46134C15.4176 1.00121 16.0657 0.87633 16.4652 1.18241L18.4586 2.75209C18.8581 3.05817 18.8579 3.67931 18.4581 4.13944L16.9594 5.94779M13.2995 3.53462C13.713 4.52158 15.024 6.38595 16.9594 5.94779M4.6317 16.5237L14.0959 16.4731" stroke="#3E3E3E" stroke-width="1.5" stroke-linecap="round" />
                                                                                    </svg>
                                                                                </span>
                                                                            </> : ""
                                                                    }
                                                                </span>
                                                            </>
                                                    }
                                                </TableCell>
                                                <TableCell className='columnSize80'>
                                                    <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        {row.mileage}
                                                        {
                                                            row.progressCode !== 'R' ? canEdit ?
                                                                <>
                                                                    <span onClick={() => updatePrice(row.branchID, row.regNo, row.stockNO, row.retailPrice, row.mileage, row.vehicleDescription, extractSiteName(row.site), row.lastUpdateMileage, row.lasUpdatePrice, row.priceUpdateUser, row.mileageUpdateUser)} className='spanEdit'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                                            <path d="M9.29388 3.42704L5.74481 3.44603C3.13134 3.46001 1.02409 5.59943 1.03814 8.22456L1.07627 15.3544C1.09031 17.9795 3.22033 20.0963 5.83379 20.0823L12.9319 20.0443C15.5454 20.0304 17.6526 17.8909 17.6386 15.2658L17.6132 10.5126M13.2995 3.53462L8.29347 9.25727C8.20263 9.3618 8.14925 9.48866 8.14391 9.61266L8.15888 12.348C8.14644 12.637 8.56095 12.9973 8.85999 12.9278L11.5187 12.3102C11.647 12.2804 11.7686 12.2058 11.8595 12.1013L16.9594 5.94779M13.2995 3.53462L15.0178 1.46134C15.4176 1.00121 16.0657 0.87633 16.4652 1.18241L18.4586 2.75209C18.8581 3.05817 18.8579 3.67931 18.4581 4.13944L16.9594 5.94779M13.2995 3.53462C13.713 4.52158 15.024 6.38595 16.9594 5.94779M4.6317 16.5237L14.0959 16.4731" stroke="#3E3E3E" stroke-width="1.5" stroke-linecap="round" />
                                                                        </svg>
                                                                    </span>
                                                                </> : ""
                                                            : ""
                                                        }
                                                    </span>
                                                </TableCell>
                                                <TableCell className='columnSize60'>
                                                    {row.dis}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </td>
                                <td className={selectTab === "vehicle" ? "tableSelect" : "tableSelect hidden"}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className='columnSize80'>
                                                    {row.stockNO}
                                                </TableCell>
                                                <TableCell className='columnSize250'>
                                                    {row.specification}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {
                                                        row.transmission === "4" || row.transmission === "5" || row.transmission === "6" ? row.transmission + " speed" : row.transmission === "A" ? "Automatic" : row.transmission === "C" ? "CVT" : row.transmission === "S" ? "Semi-automatic" : row.transmission
                                                    }
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {
                                                        row.fuelType == "D" ? "Diesel" : row.fuelType == "E" ? "Electric" : row.fuelType == "H" ? "Hybrid" : row.fuelType == "L" ? "LPG" : row.fuelType == "M" ? "Plug-in Hybrid" : row.fuelType == "O" ? "Others" : row.fuelType == "P" || row.fuelType == "U" ? "Petrol" : row.fuelType
                                                    }
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {row.colour}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {row.modelCode}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {row.dor}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {row.qualifyingForVAT}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {row.progressCode}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </td>
                                <td className={selectTab === "price" ? "tableSelect" : "tableSelect hidden"}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className='columnSize95'>
                                                    {row.pricePosition}
                                                </TableCell>
                                                <TableCell className='columnSize120'>
                                                    <PriceIndicator indicator={row.atPriceIndicator}  />
                                                </TableCell>
                                                <TableCell className='columnSize120'>
                                                    {row.daysSinceChange}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {row.noOfChanges}
                                                </TableCell>
                                                <TableCell className='columnSize120'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.lastPriceChange)}
                                                </TableCell>
                                                <TableCell className='columnSize120'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.totalPriceChange)}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.standInValue)}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.total)}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.retailPrice - row.total)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </td>
                                <td className={selectTab === "valuation" ? "tableSelect" : "tableSelect hidden"}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className='columnSize120'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.atRetailValue)}
                                                </TableCell>
                                                <TableCell className='columnSize120'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.atVariance)}
                                                </TableCell>
                                                <TableCell className='columnSize120'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.capClean)}
                                                </TableCell>
                                                <TableCell className='columnSize120'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.capClean-row.standInValue)}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.standInValue)}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {row.daysToSell}
                                                </TableCell>
                                                <TableCell className='columnSize120'>
                                                   {formatters.CurrencyThousandSeparatorWithoutZero(row.forecastValuation)}
                                                </TableCell>
                                                <TableCell className='columnSize80'>
                                                   <span style={row.capId !== null ? {cursor: "pointer"} : {cursor: "pointer", color: "rgb(237, 76, 60)"}} onClick={() => openCapPopup(row.usedVehicleStockID)}>{row.capId !== null ? row.capId : "No Code"}</span>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </td>
                                <td className={selectTab === "market" ? "tableSelect" : "tableSelect hidden"}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className='columnSize95'>
                                                    <RetailRating rating={row.atRetailRating} />
                                                </TableCell>
                                                <TableCell className='columnSize150'>
                                                    <PerformanceRating indicator={row.atPerformanceRating}  />
                                                </TableCell>
                                                <TableCell className='columnSize120'>
                                                    <PriceIndicator indicator={row.atPriceIndicator}  />
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    {row.daysToSell}
                                                </TableCell>
                                                <TableCell className='columnSize100'>
                                                    {row.marketCondition}
                                                </TableCell>
                                                <TableCell className='columnSize100'>
                                                    {row.attentionGrabber}
                                                </TableCell>
                                                <TableCell className='columnSize80'>
                                                    {row.photos}
                                                </TableCell>
                                                <TableCell className='columnSize100'>
                                                    {row.atAdvertViews}
                                                </TableCell>
                                                <TableCell className='columnSize95'>
                                                    <a href={row.atLink} title='AT Ad Link' target='_blank' style={{textDecoration: 'none', color: '#4871D9'}}>AT Ad Link</a>
                                                </TableCell>
                                                <TableCell className='columnSize120'>
                                                    <a href={row.chorleyLink} title='CG Ad Link' target='_blank' style={{textDecoration: 'none', color: '#4871D9'}}>CG Ad Link</a>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </td>
                                <td className={selectTab === "prep" ? "tableSelect" : "tableSelect hidden"}>
                                <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className='columnSize120'>
                                                    {row.vehiclePrep}
                                                </TableCell>
                                                <TableCell className='columnSize150'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.estimatedMechPrep)}
                                                </TableCell>
                                                <TableCell className='columnSize150'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.actualMechPrep)}
                                                </TableCell>
                                                <TableCell className='columnSize150'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.estimatedBodywork)}
                                                </TableCell>
                                                <TableCell className='columnSize150'>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.actualBodywork)}
                                                </TableCell>
                                                <TableCell className='columnSize150'>
                                                    {row.motExpiry}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </td>
                                <td className={selectTab === "actions" ? "tableSelect" : "tableSelect hidden"}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className='columnSize80' style={{textAlign: "center"}}>
                                                    {row.noPrice ? <RedCheck /> : <GreenCheck />}
                                                </TableCell>
                                                <TableCell className='columnSize80' style={{textAlign: "center"}}>
                                                    {row.noPhotos ? <RedCheck /> : <GreenCheck />}
                                                </TableCell>
                                                <TableCell className='columnSize100' style={{textAlign: "center"}}>
                                                    {row.noAttGrabber ? <RedCheck /> : <GreenCheck />}
                                                </TableCell>
                                                <TableCell className='columnSize80' style={{textAlign: "center"}}>
                                                    {row.toPrep ? <RedCheck /> : <GreenCheck />}
                                                </TableCell>
                                                <TableCell className='columnSize80' style={{textAlign: "center"}}>
                                                    {row.priceChange ? <RedCheck /> : <GreenCheck />}
                                                </TableCell>
                                                <TableCell className='columnSize80' style={{textAlign: "center"}}>
                                                    {row.noCapCode ? <RedCheck /> : <GreenCheck />}
                                                </TableCell>
                                                <TableCell className='columnSize80' style={{textAlign: "center"}}>
                                                    {row.lowLeads ? <RedCheck /> : <GreenCheck />}
                                                </TableCell>
                                                <TableCell className='columnSize120' style={{textAlign: "center"}}>
                                                    {row.reserved10 ?  <RedCheck /> : <GreenCheck />}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </td>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}