import {get, post } from './http-calls';

export const addPortalSettings = async (data) => {
    let res = await post('PortalSetting', data, { useAuthToken: true });
    return res;
};

export const getPortalSettings = async() => {
    let res = await get(`PortalSetting`, { useAuthToken: true });
    return res;
};