import { Grid, Hidden } from '@material-ui/core';
import { useState } from 'react';
import { LinkedButton } from '../../../Core/BudgetCore/budgetButton';
import { ASalePandLCard } from '../../../Core/BudgetCore/budgetCardsUi_v2';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { postAfterSalesOverviewData } from '../../../Core/Service/Nicol_Common_services';
import BudgetFilter from './../budgetFilters';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';

const AfterSaleProfitLoss = () => {
    const [state, setState] = useState({
        branches: [],
        tableRow: [],
        branchList: [],
        franchise: [],
        groupIds: [],
        franchiseIds: [],
        showTable: true,
        monthOfDate: [],
        yearList: [],
        tempDashBoradData: [],
        isReload: [],
        showLoader: true
    });
    const history = useHistory();

    const [isTable] = useState(false);
    const [WindowWidths] = useWindowSize();

    const handleTable = () => {
        history.push({ pathname: '/aSalepanLTable' });
    };
    const getFilterData = async (value) => {
        setState((st) => ({ ...st, showLoader: true }));

        let data = {
            filterDate: value.filterDate || [],
            franchiseCode: value.franchiseCode || [],
            branchID: value.branchID || []
        };

        let res = await postAfterSalesOverviewData(data);
        if (res.success) {
            let result = res.data.list.map((m) => {
                let totalCalProfit = Math.round((+m.totalLabour / +m.labourBudget) * 100);
                if (+m.labourBudget === 0) {
                    totalCalProfit = 100;
                }
                return { totalCalProfit, ...m };
            });
            let data = orderBy(result, ['totalCalProfit'], ['desc']); //order by profit %
            setState((st) => ({ ...st, tempDashBoradData: data, showLoader: false }));
        }
    };

    const onRedirectFun = (id) => {
        const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
        history.push({ pathname: '/aSalepanLTable' });
        let array = [];
        if (id) {
            array.push(id);
        }
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));
        setState((st) => ({ ...st, branchId: id }));
    };

    return (
        <div className="budgetScreen">
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={6} sm={9} md={9} lg={10} xl={10}>
                    <div className="mainHeading alignLeft screenMainHeader">Aftersales Profit and Loss</div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Hidden only={['xl', 'lg', 'md']}>
                            <Grid item>
                                <BudgetFilter getFilterData={getFilterData} isTable={isTable} />
                            </Grid>
                        </Hidden>
                        <Grid item style={{ paddingTop: '4px' }}>
                            <LinkedButton onClick={handleTable}>
                                <ViewComfyIcon /> {WindowWidths > 1024 ? <> &nbsp;&nbsp;ASALES P&L TABLE</> : ''}
                            </LinkedButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Hidden only={['xs', 'sm']}>
                <BudgetFilter getFilterData={getFilterData} isTable={isTable} />
            </Hidden>
            {state.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Grid container justifyContent="flex-start">
                        {state.tempDashBoradData.map((m, i) => (
                            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} key={1 + i} className="containerPadding">
                                <ASalePandLCard
                                    totalCalProfit={m.totalCalProfit}
                                    value={m.value}
                                    profit={m.totalLabour}
                                    budget={m.labourBudget}
                                    label={WindowWidths <= 600 ? m.branchShortName : m.branchName}
                                    branchID={m.branchID}
                                    onRedirectFun={(id) => onRedirectFun(id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </div>
    );
};

export default AfterSaleProfitLoss;
