import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { login } from '../../../Core/Service/login-service';
import AppContext from '../../../App/AppContext';
import { Button } from '@material-ui/core';
import { TextBox, PasswordBox } from '../../../Core/FormInput';
import { Grid } from '@material-ui/core';
import './login.scss';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2)
    }
}));

function Login(props) {
    const { portalSettings, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        userName: '',
        password: ''
    });

    const img = !!portalSettings.portalSettingMainLogo ? portalSettings.portalSettingMainLogo : `${process.env.PUBLIC_URL}/${portalSettings.portalSettingLogo}`;

    const userNameUpdated = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, userName: value }));
    };

    const passwordUpdated = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, password: value }));
    };
    const handleSubmitClick = async (e) => {
        e.preventDefault();
        if (state.userName && state.password) {
            const res = await login(state.userName, state.password);
            if (!res.success) {
                showToast(res.message);
            } else {
                sessionStorage.setItem("sessionIsActive", true);
                props.history.push('/');
                window.location.reload();
            }
        } else {
            showToast('User Name and Password both required.');
        }
    };

    const classes = useStyles();
    return (
        <div className="logincontainer">
            <div className="loginsection">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img src={img} className="image" alt="img" />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <div className="loginHeading">Login to your account</div>
                    </Grid>
                    <Grid item xs={12}>
                        <form className={classes.form} noValidate>
                            <TextBox
                                autoFocus={true}
                                required
                                placeholder="E-MAIL"
                                type="email"
                                onChange={userNameUpdated}
                                value={state.userName}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        handleSubmitClick(event);
                                    }
                                }}
                            />

                            <PasswordBox
                                style={{ marginTop: 20 }}
                                required
                                placeholder="PASSWORD"
                                onChange={passwordUpdated}
                                value={state.password}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        handleSubmitClick(event);
                                    }
                                }}
                            />
                            <Button onClick={handleSubmitClick} className="SignButton">
                                Login
                            </Button>
                        </form>
                    </Grid>
                </Grid>{' '}
            </div>
        </div>
    );
}

export default Login;
