import React, { useContext, useState } from "react";
import AppContext from "../../App/AppContext";
import {
    TextBox,
    SecondaryButton,
    DialogContents,
    DialogTitles,
    Dialogs,
} from "./../FormInput";
import "./model.css";
import { ForgotPassword } from "../Service/forgotPassword-service"
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const ForgotPasswordModal = (props) => {
    const { hideModal,showToast } = useContext(AppContext);
    const [state, setState] = useState({
        email: "",
    });

    const handleClose = () => {
        hideModal();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };
    const handleResetPass = async (e) => {
        e.preventDefault();
        if (state.email) {
            const res = await ForgotPassword(state.email);
            if (!res.success) {
                showToast(res.message)
                hideModal();
            }
            else{
                showToast(res.message);
                hideModal();
            }
        }
        else {
            showToast('Email address is required.');
            hideModal();
        }
    }
    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="sm">
             <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitles>Forgot Password?</DialogTitles>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="setDialogFP">
                <span>
                    Enter the email address associated with your account
                </span>
                <TextBox
                    autoFocus
                    placeholder="Enter email address"
                    name="email"
                    type="email"
                    onChange={fieldChange}
                />
                <SecondaryButton className="Submitbtn" onClick={handleResetPass}>
                    Reset Password
                </SecondaryButton>
            </DialogContents>
        </Dialogs>
    );
};

export default ForgotPasswordModal;
