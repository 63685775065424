import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppContext from "../../App/AppContext";
import { PasswordBox, PrimaryButton } from "./../FormInput";
import { changeUserPassword } from "../Service/changePassword-service";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import "./model.css";

const PasswordChangeModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        current: "",
        new: "",
        confirm: "",
        enableChange: false,
    });

    const handleClose = () => {
        hideModal();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            nst.enableChange =
                nst.current &&
                nst.new &&
                nst.confirm &&
                nst.new === nst.confirm;
            return nst;
        });
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (state.new && state.confirm) {
            const res = await changeUserPassword(
                state.current,
                state.new,
                state.confirm
            );
            if (!res.success) {
                console.error(res);
            }
            showToast(res.messages);
        } else {
            showToast("New password and confirm password is required.");
        }
        hideModal();
    };
    return (
        <Dialog open={true} onClose={handleClose} maxWidth="sm">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>Change Password</DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent>
                <PasswordBox
                    autoFocus
                    label="Current Password"
                    name="current"
                    onChange={fieldChange}
                />
                <PasswordBox
                    label="New Password"
                    name="new"
                    onChange={fieldChange}
                />
                <PasswordBox
                    label="Password Password"
                    name="confirm"
                    onChange={fieldChange}
                />
                <PrimaryButton
                    className="Submitbtn"
                    onClick={handleChangePassword}
                    disabled={!state.enableChange}
                >
                    Submit
                </PrimaryButton>
            </DialogContent>
        </Dialog>
    );
};

export default PasswordChangeModal;
