import { useContext, useEffect, useState } from 'react';
import { getCAPCleanHistory, postManuallyUpdateCAPValuation } from '../../../Core/Service/Nicol_Common_services';
import { DialogTitle, Grid, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import moment from 'moment';
import formatters from '../../../Core/Grid/inputFormatter';
import { SecondaryButton, TextBox, TextBoxShrink, YesNoButton } from '../../../Core/FormInput';
import AppContext from '../../../App/AppContext';

const useStyles = makeStyles({
    table: {
        minWidth: 750,
        overflow: 'auto',
        '& .MuiTableCell-root': {
            padding: '10px'
        }
    },
    tableRowStyle: {
        backgroundColor: '#E0E0E0'
    }
});

const CapClean = (props) => {
    const { showToast } = useContext(AppContext);
    const classes = useStyles();
    const [state, setState] = useState({
        details: [],
        history: [],
        isEditable: props.isEditable,
        usedVehicleStockID: props.stockID,
        valuationDateMatch: null,
        valuationMileage: 0,
        valuationOrignalMileage: 0,
        tradeRetail: 0,
        tradeClean: 0,
        tradeAverage: 0,
        tradePoor: 0,
        valuationDate: null,
        vin: '',
        isImported: null,
        capId: null,
        colour: '',
        make: '',
        model: '',
        scrapped: 'NO',
        exported: 'NO'
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await getCAPCleanHistory(props.stockID);
                let details = res?.data?.details[0] || {};
                setState((st) => ({
                    ...st,
                    details: details,
                    history: res?.data?.history,
                    valuationDateMatch: details?.valuationDateMatch,
                    valuationMileage: details.orignalMileage,
                    valuationOrignalMileage: details.orignalMileage,
                    tradeRetail: details.tradeRetail,
                    tradeClean: details.tradeClean,
                    tradeAverage: details.tradeAverage,
                    tradePoor: details.tradePoor,
                    valuationDate: details.valuationDate,
                    vin: details.vin,
                    isImported: details.isImported === 'Yes' ? true : false,
                    capId: details.capId,
                    colour: details.colour,
                    make: details.make,
                    model: details.model,
                    scrapped: details.scrapped === 'YES' ? true : false,
                    exported: details.exported === 'YES' ? true : false
                }));
            } catch (error) {
                // Handle any errors that might occur during the asynchronous operation
                console.error('An error occurred:', error);
            }
        };

        fetchData();
    }, [props.stockID]);

    const fieldChange = async (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };
    const handleSubmit = async () => {
        let data = {
            usedVehicleStockID: props.stockID,
            valuationDateMatch: state.valuationDateMatch,
            valuationMileage: state.valuationMileage,
            valuationOrignalMileage: state.valuationOrignalMileage,
            tradeRetail: state.tradeRetail,
            tradeClean: state.tradeClean,
            tradeAverage: state.tradeAverage,
            tradePoor: state.tradePoor,
            valuationDate: state.valuationDate,
            vin: state.vin,
            isImported: state.isImported,
            capId: state.capId,
            colour: state.colour,
            make: state.make,
            model: state.model,
            scrapped: state.scrapped === true ? 'YES' : 'NO',
            exported: state.exported === true ? 'YES' : 'NO'
        };

        let res = await postManuallyUpdateCAPValuation(data);
        if (res.success) {
            props.onClose(true);
            showToast('CAP valuation updated successfully');
        }
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st };

            if (name === 'scrapped' || name === 'exported') {
                nst[name] = value === 'YES' ? true : false;
            } else {
                nst[name] = value;
            }
            return nst;
        });
    };

    return (
        <>
            <DialogComp
                title={
                    <DialogTitle style={{ padding: 0 }}>
                        <span>
                            CAP Valuation {state.details.regNo}({moment(state.details.valuationDate).format('DD/MM/YYYY')})
                        </span>
                    </DialogTitle>
                }
                onClose={() => props.onClose(false)}
                fullWidth
                maxWidth="md"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel shrink>Make</InputLabel>
                                    {state.isEditable ? (
                                        <TextBox autoFocus name="make" onChange={fieldChange} value={state.make} />
                                    ) : (
                                        <span>{state.details?.make}</span>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel shrink>Model</InputLabel>
                                    {state.isEditable ? (
                                        <TextBox autoFocus name="model" onChange={fieldChange} value={state.model} />
                                    ) : (
                                        <span>{state.details?.model}</span>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel shrink>Colour</InputLabel>
                                    {state.isEditable ? (
                                        <TextBox autoFocus name="colour" onChange={fieldChange} value={state.colour} />
                                    ) : (
                                        <span>{state.details?.colour}</span>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel shrink>Mileage</InputLabel>
                                    {state.isEditable ? (
                                        <TextBox autoFocus name="valuationMileage" onChange={fieldChange} value={state.valuationMileage} />
                                    ) : (
                                        <span>{state.details?.orignalMileage}</span>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel shrink>Retail</InputLabel>
                                    {state.isEditable ? (
                                        <TextBox type="number" autoFocus name="tradeRetail" onChange={fieldChange} value={state.tradeRetail} />
                                    ) : (
                                        <span>{formatters.CurrencyThousandSeparatorWithoutZero(state.details?.tradeRetail)}</span>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel shrink>Clean</InputLabel>
                                    {state.isEditable ? (
                                        <TextBox type="number" autoFocus name="tradeClean" onChange={fieldChange} value={state.tradeClean} />
                                    ) : (
                                        <span>{formatters.CurrencyThousandSeparatorWithoutZero(state.details?.tradeClean)}</span>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel shrink>Average</InputLabel>
                                    {state.isEditable ? (
                                        <TextBox type="number" autoFocus name="tradeAverage" onChange={fieldChange} value={state.tradeAverage} />
                                    ) : (
                                        <span>{formatters.CurrencyThousandSeparatorWithoutZero(state.details?.tradeAverage)}</span>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel shrink>Poor</InputLabel>
                                    {state.isEditable ? (
                                        <TextBox type="number" autoFocus name="tradePoor" onChange={fieldChange} value={state.tradePoor} />
                                    ) : (
                                        <span>{formatters.CurrencyThousandSeparatorWithoutZero(state.details?.tradePoor)}</span>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={3}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputLabel shrink>CAP ID</InputLabel>
                                    {state.isEditable ? (
                                        <TextBoxShrink type="number" autoFocus name="capId" onChange={fieldChange} value={state.capId} />
                                    ) : (
                                        <span>{state.details?.capId}</span>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={6} sm={3}>
                                <Grid item xs={12}>
                                    Scrapped?
                                </Grid>
                                <Grid item xs={10}>
                                    {state.isEditable ? (
                                        <YesNoButton
                                            state={state.scrapped}
                                            name="scrapped"
                                            onYesClick={() => handleClick('scrapped', 'YES')}
                                            onNoClick={() => handleClick('scrapped', 'NO')}
                                        />
                                    ) : (
                                        <span>{state.details?.exported}</span>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Grid item xs={12}>
                                    Imported?
                                </Grid>
                                <Grid item xs={10}>
                                    {state.isEditable ? (
                                        <YesNoButton
                                            state={state.isImported}
                                            name="isImported"
                                            onYesClick={() => handleClick('isImported', true)}
                                            onNoClick={() => handleClick('isImported', false)}
                                        />
                                    ) : (
                                        <span>{state.details?.exported}</span>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item xs={6} sm={3}>
                                <Grid item xs={12}>
                                    Exported?
                                </Grid>
                                <Grid item xs={10}>
                                    {state.isEditable ? (
                                        <YesNoButton
                                            state={state.exported}
                                            name="exported"
                                            onYesClick={() => handleClick('exported', 'YES')}
                                            onNoClick={() => handleClick('exported', 'NO')}
                                        />
                                    ) : (
                                        <span>{state.details?.exported}</span>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {state.isEditable ? (
                        <Grid item xs={12} sm={4} lg={2}>
                            <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                                Submit
                            </SecondaryButton>
                        </Grid>
                    ) : null}
                </Grid>

                <Grid container style={{ marginTop: '10px' }}>
                    <Grid item xs={12}>
                        <span style={{ fontSize: '18px', fontWeight: '500' }}>Valuation History</span>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: '5px' }}>
                    <Grid item xs>
                        <TableContainer
                            // component={Paper}
                            style={{ height: 'calc(100vh - 320px)', overflow: 'auto' }}
                            className="input-Number-remove-spinners custom-scroll"
                        >
                            <Table className={classes.table} stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Retail</TableCell>
                                        <TableCell>Clean</TableCell>
                                        <TableCell>Average</TableCell>
                                        <TableCell>Poor</TableCell>
                                        <TableCell>Scrapped?</TableCell>
                                        <TableCell>CAP ID</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.history.map((o, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">
                                                    {moment(o.valuationDate).format('DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(o.tradeRetail)}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(o.tradeClean)}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(o.tradeAverage)}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(o.tradePoor)}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {o.scrapped}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {o.capId}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogComp>
        </>
    );
};

export default CapClean;
