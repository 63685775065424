import { Checkbox, CircularProgress, FormControlLabel, FormLabel, Grid, TableContainer, TextField } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import formatters from '../../../../Core/Grid/inputFormatter';
import {
    AddExpenseCategory,
    AddOtherIncomeCategory,
    getBudgetDropDown,
    getBudgetExpenses,
    getBudgetOtherIncomes,
    getNicolBudgetSalesTargets,
    getSalesTargets,
    postBudgetExpenceData,
    postSetOtherIncomes,
    setNicolBudgetSalesTargets,
    setSalesTargets
} from '../../../../Core/Service/branch-screen-service';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../../commonStyle.scss';

import moment from 'moment';
import AppContext from '../../../../App/AppContext';

const useStyles = makeStyles({
    table: {
        minWidth: 1250,

        '& .MuiTableCell-root': {
            padding: '10px'
        }
    },
    tableRowStyle: {
        backgroundColor: '#E0E0E0'
    }
});

const MonthShortNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function isFiniteCheck(str) {
    return isFinite(str) ? str.toFixed(2) : '0.00';
}

const ServiceSalesTargetScreen = (props) => {
    const [targetTypeList, setTargetTypeList] = useState([]);

    const [showCircularLoader, setShowCircularLoader] = useState(true);
    const [calculatedTargetTypeList, setCalculatedTargetTypeList] = useState([]);
    const { showToast } = useContext(AppContext);
    const classes = useStyles();
    const pullSalesTargetGridData = async () => {
        setShowCircularLoader(true);
        let tempTargetTypeList = [
            {
                rowName: 'Labour sales - retail',
                staticID: 1,
                data: []
            },
            {
                rowName: 'Labour sales - warranty',
                staticID: 2,
                data: []
            },
            {
                rowName: 'Labour sales - internal',
                staticID: 3,
                data: []
            },
            {
                rowName: 'Labour sales - other',
                staticID: 4,
                data: []
            },
            {
                rowName: 'Labour Total',

                data: []
            },
            {
                rowName: 'Labour cost of sales',
                staticID: 5,
                data: []
            },
            {
                rowName: 'Labout gross profit',
                data: []
            },
            {
                rowName: 'Oil sales',
                staticID: 6,
                data: []
            },
            {
                rowName: 'Oil cost of sales',
                staticID: 7,
                data: []
            },
            {
                rowName: 'Oil gross profit',
                data: []
            },
            {
                rowName: 'Oil gross profit %',

                data: []
            },
            {
                rowName: 'Subcontract sales',
                staticID: 8,
                data: []
            },
            {
                rowName: 'Subcontract cost of sales',
                staticID: 9,
                data: []
            },
            {
                rowName: 'Subcontract gross profit',
                data: []
            },
            {
                rowName: 'Subcontract gross profit %',

                data: []
            },
            {
                rowName: 'Other sales',
                staticID: 10,
                data: []
            },
            {
                rowName: 'Other cost of sales',
                staticID: 11,
                data: []
            },
            {
                rowName: 'Other gross profit',

                data: []
            },
            {
                rowName: 'Other gross profit %',

                data: []
            },
            {
                rowName: 'Total Other sales',

                data: []
            },
            {
                rowName: 'Total Other cost of sales',

                data: []
            },
            {
                rowName: 'Total Other gross profit',

                data: []
            },
            {
                rowName: 'Total Other gross profit %',

                data: []
            },
            {
                rowName: 'Total Service sales',

                data: []
            },
            {
                rowName: 'Total Service cost of sales',

                data: []
            },
            {
                rowName: 'Total Service Gross Profit',

                data: []
            },
            {
                rowName: 'Total Service Gross Profit %',

                data: []
            }
        ];

        let res = await getNicolBudgetSalesTargets(props.yearCode?.id, props.data.departmentID, props.branchID);
        let tempResData = res.data.list || [];

        tempResData.forEach((q) => {
            let objIndex = tempTargetTypeList.findIndex((p) => p.staticID === q.salesTargetTypeID);
            if (objIndex > -1) {
                tempTargetTypeList[objIndex].data.push(q);
            }
        });

        tempTargetTypeList.forEach((r) => {
            let list2 = MonthShortNameList.map((m) => {
                let obj = r.data.find((p) => moment(p.salesTargetDate).format('DD-MMM-YYYY').includes(`${m}`));
                if (obj) {
                    return obj;
                } else {
                    return {
                        salesTargetAmount: 0,
                        salesTargetBranchID: props.branchID,
                        salesTargetDate: moment(`01/${m}/${props.yearCode?.id}`).format('DD-MMM-YYYY'),
                        salesTargetDepartmentID: props.data.departmentID,
                        salesTargetID: null,
                        salesTargetTypeID: r.staticID
                    };
                }
            });
            r.data = list2;
        });

        setTargetTypeList(tempTargetTypeList);
        setShowCircularLoader(false);
    };

    useEffect(() => {
        pullSalesTargetGridData();
    }, [props.yearCode?.id]);

    useEffect(() => {
        if (targetTypeList.length > 0) {
            let tempArr = [...targetTypeList];

            /////  Labour Total Calculations //////
            let LabourTotalIdList = [1, 2, 3, 4];
            let LabourTotalSortedList = LabourTotalIdList.map((p) => {
                let obj = tempArr.find((q) => q.staticID == p);
                if (obj) {
                    return { ...obj };
                }
            });
            let tempLabourTotalCalObj = {};
            LabourTotalSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempLabourTotalCalObj[i] = (+tempLabourTotalCalObj[i] || 0) + r.salesTargetAmount;
                });
            });

            tempArr[4].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempLabourTotalCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Labout gross profit Calculations //////
            let LabourTotalObj = tempArr.find((q) => q.rowName == 'Labour Total');
            let LabourCostOfSalesObj = tempArr.find((q) => q.staticID == 5);

            let LaboutGrossProfitSortedList = [LabourCostOfSalesObj, LabourTotalObj];
            let tempLaboutGrossProfitCalObj = {};
            LaboutGrossProfitSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempLaboutGrossProfitCalObj[i] = r.salesTargetAmount - (+tempLaboutGrossProfitCalObj[i] || 0);
                });
            });

            tempArr[6].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempLaboutGrossProfitCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            ////// Oil gross profit Calculations //////

            let OilGrossProfitIdList = [7, 6];
            let OilGrossProfitSortedList = OilGrossProfitIdList.map((p) => {
                let obj = tempArr.find((q) => q.staticID == p);
                if (obj) {
                    return { ...obj };
                }
            });
            let tempOilGrossProfitCalObj = {};
            OilGrossProfitSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempOilGrossProfitCalObj[i] = r.salesTargetAmount - (+tempOilGrossProfitCalObj[i] || 0);
                });
            });

            tempArr[9].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempOilGrossProfitCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Oil gross profit % Calculations //////

            let OilGrossProfitObj = tempArr.find((q) => q.rowName == 'Oil gross profit');
            let OilSalesObj = tempArr.find((q) => q.staticID == 6);
            let tempOilGrossProfitPercentageCalObj = {};

            MonthShortNameList.forEach((r, i) => {
                tempOilGrossProfitPercentageCalObj[i] = (OilGrossProfitObj.data[i].salesTargetAmount / OilSalesObj.data[i].salesTargetAmount) * 100;
            });

            tempArr[10].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempOilGrossProfitPercentageCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            ////// Subcontract gross profit Calculations //////

            let SubcontractGrossProfitIdList = [9, 8];
            let SubcontractGrossProfitSortedList = SubcontractGrossProfitIdList.map((p) => {
                let obj = tempArr.find((q) => q.staticID == p);
                if (obj) {
                    return { ...obj };
                }
            });
            let tempSubcontractGrossProfitCalObj = {};
            SubcontractGrossProfitSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempSubcontractGrossProfitCalObj[i] = r.salesTargetAmount - (+tempSubcontractGrossProfitCalObj[i] || 0);
                });
            });

            tempArr[13].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempSubcontractGrossProfitCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Subcontract gross profit % Calculations //////

            let SubcontractGrossProfitObj = tempArr.find((q) => q.rowName == 'Subcontract gross profit');
            let SubcontractSalesObj = tempArr.find((q) => q.staticID == 8);
            let tempSubcontractGrossProfitPercentageCalObj = {};

            MonthShortNameList.forEach((r, i) => {
                tempSubcontractGrossProfitPercentageCalObj[i] =
                    (SubcontractGrossProfitObj.data[i].salesTargetAmount / SubcontractSalesObj.data[i].salesTargetAmount) * 100;
            });

            tempArr[14].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempSubcontractGrossProfitPercentageCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            ////// Other gross profit Calculations //////

            let OtherGrossProfitIdList = [11, 10];
            let OtherGrossProfitSortedList = OtherGrossProfitIdList.map((p) => {
                let obj = tempArr.find((q) => q.staticID == p);
                if (obj) {
                    return { ...obj };
                }
            });
            let tempOtherGrossProfitCalObj = {};
            OtherGrossProfitSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempOtherGrossProfitCalObj[i] = r.salesTargetAmount - (+tempOtherGrossProfitCalObj[i] || 0);
                });
            });

            tempArr[17].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempOtherGrossProfitCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Other gross profit % Calculations //////

            let OtherGrossProfitProfitObj = tempArr.find((q) => q.rowName == 'Other gross profit');
            let OtherSalesObj = tempArr.find((q) => q.staticID == 10);
            let tempOtherGrossProfitProfitPercentageCalObj = {};

            MonthShortNameList.forEach((r, i) => {
                tempOtherGrossProfitProfitPercentageCalObj[i] =
                    (OtherGrossProfitProfitObj.data[i].salesTargetAmount / OtherSalesObj.data[i].salesTargetAmount) * 100;
            });

            tempArr[18].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempOtherGrossProfitProfitPercentageCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Total Other sales Calculations //////

            let TotalOtherSalesIdList = [6, 8, 10];
            let TotalOtherSalesSortedList = TotalOtherSalesIdList.map((p) => {
                let obj = tempArr.find((q) => q.staticID == p);
                if (obj) {
                    return { ...obj };
                }
            });

            let tempTotalOtherSalesCalObj = {};
            TotalOtherSalesSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempTotalOtherSalesCalObj[i] = (+tempTotalOtherSalesCalObj[i] || 0) + r.salesTargetAmount;
                });
            });

            tempArr[19].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempTotalOtherSalesCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Total Other cost of sales Calculations //////

            let TotalOtherCostOfSalesIdList = [7, 9, 11];
            let TotalOtherCostOfSalesSortedList = TotalOtherCostOfSalesIdList.map((p) => {
                let obj = tempArr.find((q) => q.staticID == p);
                if (obj) {
                    return { ...obj };
                }
            });

            let tempTotalOtherCostOfSalesCalObj = {};
            TotalOtherCostOfSalesSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempTotalOtherCostOfSalesCalObj[i] = (+tempTotalOtherCostOfSalesCalObj[i] || 0) + r.salesTargetAmount;
                });
            });

            tempArr[20].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempTotalOtherCostOfSalesCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Total Other gross profit Calculations //////

            let TotalOtherGrossProfitIdList = ['Total Other cost of sales', 'Total Other sales'];
            let TotalOtherGrossProfitSortedList = TotalOtherGrossProfitIdList.map((p) => {
                let obj = tempArr.find((q) => q.rowName == p);
                if (obj) {
                    return { ...obj };
                }
            });

            let tempTotalOtherGrossProfitCalObj = {};
            TotalOtherGrossProfitSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempTotalOtherGrossProfitCalObj[i] = r.salesTargetAmount - (+tempTotalOtherGrossProfitCalObj[i] || 0);
                });
            });

            tempArr[21].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempTotalOtherGrossProfitCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Total Other gross profit % Calculations //////

            let TotalOtherGrossProfitObj = tempArr.find((q) => q.rowName == 'Total Other gross profit');
            let TotalOtherSalesObj = tempArr.find((q) => q.rowName == 'Total Other sales');

            let tempTotalOtherGrossProfitProfitPercentageCalObj = {};

            MonthShortNameList.forEach((r, i) => {
                tempTotalOtherGrossProfitProfitPercentageCalObj[i] =
                    (TotalOtherGrossProfitObj.data[i].salesTargetAmount / TotalOtherSalesObj.data[i].salesTargetAmount) * 100;
            });

            tempArr[22].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempTotalOtherGrossProfitProfitPercentageCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Total Service Sales Calculations //////

            let TotalServiceSalesSortedList = [LabourTotalObj, TotalOtherSalesObj];

            let tempTotalServiceSalesCalObj = {};

            TotalServiceSalesSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempTotalServiceSalesCalObj[i] = (+tempTotalServiceSalesCalObj[i] || 0) + r.salesTargetAmount;
                });
            });

            tempArr[23].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempTotalServiceSalesCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Total Service cost of sales Calculations //////

            let TotalOtherCostOfSalesObj = tempArr.find((q) => q.rowName == 'Total Other cost of sales');
            let TotalServiceCostOfSalesSortedList = [TotalOtherCostOfSalesObj, LabourCostOfSalesObj];
            let tempTotalServiceCostOfSalesCalObj = {};
            TotalServiceCostOfSalesSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempTotalServiceCostOfSalesCalObj[i] = (+tempTotalServiceCostOfSalesCalObj[i] || 0) + r.salesTargetAmount;
                });
            });

            tempArr[24].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempTotalServiceCostOfSalesCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Total Service Gross Profit Calculations //////
            let TotalServiceGrossProfitIdList = ['Total Service cost of sales', 'Total Service sales'];
            let TotalServiceGrossProfitSortedList = TotalServiceGrossProfitIdList.map((p) => {
                let obj = tempArr.find((q) => q.rowName == p);
                if (obj) {
                    return { ...obj };
                }
            });

            let tempTotalServiceGrossProfitCalObj = {};
            TotalServiceGrossProfitSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempTotalServiceGrossProfitCalObj[i] = r.salesTargetAmount - (+tempTotalServiceGrossProfitCalObj[i] || 0);
                });
            });

            tempArr[25].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempTotalServiceGrossProfitCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            /////  Total Service Gross Profit % Calculations //////

            let TotalServiceGrossProfitObj = tempArr.find((q) => q.rowName == 'Total Service Gross Profit');
            let TotalServiceSalesObj = tempArr.find((q) => q.rowName == 'Total Service sales');

            let tempTotalServiceGrossProfitPercentageProfitPercentageCalObj = {};

            MonthShortNameList.forEach((r, i) => {
                tempTotalServiceGrossProfitPercentageProfitPercentageCalObj[i] =
                    (TotalServiceGrossProfitObj.data[i].salesTargetAmount / TotalServiceSalesObj.data[i].salesTargetAmount) * 100;
            });

            tempArr[26].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempTotalServiceGrossProfitPercentageProfitPercentageCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            tempArr.forEach((t) => {
                let DataTotal = 0;
                t.data.forEach((q) => {
                    DataTotal += +q.salesTargetAmount;
                });
                t.allSalesTargetAmount = isFiniteCheck(DataTotal);
            });

            setCalculatedTargetTypeList(tempArr);
        }
    }, [targetTypeList]);

    const gridInputChangeHandler = (index, typeID) => (e) => {
        let tempList = [...targetTypeList];
        let objIndex = tempList.findIndex((x) => x.staticID === typeID);
        tempList[objIndex].data[index] = {
            ...tempList[objIndex].data[index],
            salesTargetAmount: +e.target.value,
            salesTargetTypeID: typeID,
            isChanged: true
        };
        setTargetTypeList(tempList);
    };

    const InputWrapper = (index, typeID, value) => {
        return (
            <input type="number" name={typeID} onChange={gridInputChangeHandler(index, typeID)} value={value} className="pa-4" style={{ textAlign: 'right' }} />
        );
    };

    const handleSubmit = async () => {
        let tempTargetTypeList = [...targetTypeList];
        let data = [];
        tempTargetTypeList.forEach((f) => {
            let tempData = f.data.filter((j) => j.isChanged).map(({ salesTargetDepartmentID, salesTargetBranchID, isChanged, ...q }) => ({ ...q }));
            data = [...data, ...tempData];
        });
        let filterPostData = {
            BranchID: props.branchID,
            SalesTargetDepartmentID: props.data.departmentID,
            SalesTargets: data
        };

        let res = await setNicolBudgetSalesTargets(filterPostData);
        if (res.success) {
            showToast('Sales Target Save Successfully');
            pullSalesTargetGridData();
        }
    };

    return (
        <Grid container item style={{ paddingTop: '10px' }} justifyContent="center">
            <Grid container item spacing={2} justifyContent="space-between">
                <Grid xs={8} item></Grid>
                <Grid xs={4} container item spacing={2} alignItems="center" justifyContent="flex-end">
                    <Grid item xs={4}>
                        <SecondaryButton className="setFullWidth" onClick={handleSubmit}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={4}>
                        <SecondaryButton className="setFullWidth">Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
            {showCircularLoader ? (
                <Grid item>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 290px)' }} className="input-Number-remove-spinners">
                        <Table className={classes.table} stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={170}></TableCell>
                                    {MonthShortNameList.map((p) => {
                                        return <TableCell align="right">{p}</TableCell>;
                                    })}
                                    <TableCell width={100} align="right">
                                        Total
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {calculatedTargetTypeList.map((t, i) => {
                                    return (
                                        <TableRow className={t.staticID ? {} : classes.tableRowStyle} key={i}>
                                            <TableCell component="th" scope="row">
                                                {t.rowName}
                                            </TableCell>

                                            {t.data.map((p, i) => {
                                                return t.staticID ? (
                                                    <TableCell align="right">{InputWrapper(i, t.staticID, p.salesTargetAmount)}</TableCell>
                                                ) : (
                                                    <TableCell align="right">
                                                        {formatters.ThousandSeparatorWithRoundnWithoutZero(+p.salesTargetAmount)}
                                                    </TableCell>
                                                );
                                            })}

                                            <TableCell align="right">{formatters.ThousandSeparatorWithRoundnWithoutZero(+t.allSalesTargetAmount)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
};

export default ServiceSalesTargetScreen;
