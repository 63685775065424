import { Grid } from '@material-ui/core';
import { TotalUnitsSitePerformance, UnitValuesSitePerformance, SummaryTableSitePerformance, SummaryBreakDownSitePerformance } from '../../../Core/BudgetCore/budgetCardsUi_v2';
import formatters from '../../../Core/Grid/inputFormatter';

export const SummaryCardShared = (props) => {
    const { resData, resDataMain, type, modalHandler } = props;
    let typeSelected, cases, profitVal, qntyCalc, casesPrevious, mainCases, mainCasesAll, avgValue, mainTotal, profit, profitBrand, listType, finprofit, finprofitbrand, newOrUsedType;
    let casesTooltip, brandTooltip, priorTooltip;

    let subText = "(excl fleet)";
    let textAvg = "Brand ";

    if (type === "warrantyUsed" || type === "productsUsed" || type === "financeUsed" || type === "accUsed") {
        subText = "(excl trade)";
        textAvg = "Grp "
    }

    if (type === "financeNew" || type === "financeUsed") {
        cases = resData.totalRetailCases;
        profitVal = resData.totalRetailCasesBrand;
        qntyCalc = resData.brandQnty;
        casesPrevious = resData.totalRetailPreviousYear;
        mainCases = resDataMain.dashboardCurrent[0].newRetail;
        mainCasesAll = resDataMain.dashboardall[0].newRetail;
        avgValue = resData.totalFinanceBrand;
        mainTotal = resData.totalFinanceBranch;
        typeSelected = "finance";
        finprofit = resData.profitNew;
        finprofitbrand = resData.profitBrandNew;
        newOrUsedType = "financeNew"
        casesTooltip = "Total number of Retail finance cases in selected period. Excludes Fleet"
        brandTooltip = "Comparison of total retail finance cases against selected Brand average"
        priorTooltip = "% change on prior year for selected period"

        if (type === "financeUsed") {
            mainCases = resDataMain.dashboardCurrent[0].usedRetail;
            mainCasesAll = resDataMain.dashboardall[0].usedRetail;
            qntyCalc = resDataMain.dashboardall[0].qntyBranchBrand;
            finprofit = resData.profitUsed;
            finprofitbrand = resData.profitBrandUsed;
            newOrUsedType = "financeUsed"
            casesTooltip = "Total number of Retail finance cases in selected period. Excludes Trade"
            brandTooltip = "Comparison of total retail finance cases against group average"
            priorTooltip = "% change on prior year for selected period"
        }
    }

    if (type === "productsNew" || type === "productsUsed") {
        cases = resData.totalVolume;
        profitVal = resData.totalVolumeBrand;
        qntyCalc = resData.brandQnty;
        casesPrevious = resData.totalVolumePrevious;
        profit = resData.profit;
        profitBrand = resData.profitBrand;
        mainCases = resDataMain.dashboardCurrent[0].newRetail;
        mainCasesAll = resDataMain.dashboardall[0].newRetail;
        typeSelected = "products";
        subText = "(excl fleet & motab)"
        listType = "new";
        newOrUsedType = "productsNew"
        casesTooltip = "New Products Per Sale. Total number of products sold / total number of units. Applies to Supagard, Cosmetic and T&A. Retail units only"
        brandTooltip = "Comparison of new products per sale against selected brand average"
        priorTooltip = "% change on prior year for selected period"

        if (type === "productsUsed") {
            mainCases = resDataMain.dashboardCurrent[0].usedRetail;
            mainCasesAll = resDataMain.dashboardall[0].usedRetail;
            qntyCalc = resDataMain.dashboardall[0].qntyBranchBrand;
            subText = "";
            listType="used";
            newOrUsedType = "productsUsed"
            casesTooltip = "Used Products Per Sale. Total number of products sold / total number of units. Applies to Supagard, Cosmetic, T&A, Chorley Care Premium and NIC Extended Warranties. Retail units only."
            brandTooltip = "Comparison of new products per sale against group average"
            priorTooltip = "% change on prior year for selected period"
        }
    }

    if (type === "warrantyNew" || type === "warrantyUsed") {
        cases = resData.totalVolume;
        profitVal = resData.totalVolumeBrand;
        qntyCalc = resData.brandQnty;
        casesPrevious = resData.totalVolumePrevious;
        profit = resData.profit;
        profitBrand = resData.profitBrand;
        mainCases = resDataMain.dashboardCurrent[0].newRetail;
        mainCasesAll = resDataMain.dashboardall[0].newRetail;
        typeSelected = "warranty";
        newOrUsedType = "warrantyNew"
        casesTooltip = ""
        brandTooltip = ""
        priorTooltip = ""

        if (type === "warrantyUsed") {
            mainCases = resDataMain.dashboardCurrent[0].usedRetail;
            mainCasesAll = resDataMain.dashboardall[0].usedRetail;
            qntyCalc = resDataMain.dashboardall[0].qntyBranchBrand;
            newOrUsedType = "warrantyUsed"
            casesTooltip = "Total number of Warranties sold in selected period. Includes CCPRE, CCPRE+ and NIC Ext"
            brandTooltip = "Group average of total warranties sold in selected period"
            priorTooltip = "% change on prior year for selected period"
        }
    }

    if (type === "accNew" || type === "accUsed") {
        profitVal = resData.totalVolumeBrand;
        qntyCalc = resData.brandQnty;
        casesPrevious = resData.totalVolumePrevious;
        profit = resData.profit;
        profitBrand = resData.profitBrand;
        mainCases = resDataMain.dashboardCurrent[0].newRetail;
        mainCasesAll = resDataMain.dashboardall[0].newRetail;
        typeSelected = "accessories";
        newOrUsedType = "accNew"
        casesTooltip = "Number of incorrectly costed Admin Fee / Fuel EV charge"
        brandTooltip = ""
        priorTooltip = ""

        if (type === "accUsed") {
            mainCases = resDataMain.dashboardCurrent[0].usedRetail;
            mainCasesAll = resDataMain.dashboardall[0].usedRetail;
            qntyCalc = resDataMain.dashboardall[0].qntyBranchBrand;
            newOrUsedType = "accUsed"
            casesTooltip = "Number of incorrectly costed Admin Fee / Fuel EV Charge"
            brandTooltip = ""
            priorTooltip = ""
        }

        cases = resData.totalVolume;
    }

    return (
        <>
            { 
                typeSelected === "finance" ? <>
                    <Grid container>
                        <TotalUnitsSitePerformance
                            value={cases}
                            valueType="units"
                            label="Total Retail Cases"
                            budgetLable={textAvg+"Avg."}
                            profitVal={Math.round(profitVal / qntyCalc)}
                            previousYear={casesPrevious}
                            mainType={false}
                            lowerLabel={subText}
                            mainValueTooltip={casesTooltip}
                            averageTooltip={brandTooltip}
                            priorYearTooltip={priorTooltip}
                        />
                        <div className='divider'></div>
                        <div className='barWrapperSP'>
                            <UnitValuesSitePerformance
                                mainValue={cases !== 0 ? Math.round((cases * 100) / mainCases) : 0}
                                mainTitle="Total Retail Pen"
                                averageValue={cases !== 0 ? Math.round((profitVal * 100) / mainCasesAll) : 0}
                                averageTitle={textAvg+"Avg."}
                                mainType={false}
                                mainValueType="percentage"
                                mainValueTooltip="Percentage of retail finance penetration in selected period"
                                averageTooltip="Total volume on finance for selected period"
                            />
                            <UnitValuesSitePerformance
                                mainValue={mainTotal}
                                mainTitle="Total Volume"
                                averageValue={Math.round(avgValue / qntyCalc)}
                                averageTitle={textAvg+"Avg."}
                                mainType={false}
                                mainValueType="money"
                                mainValueTooltip="Total volume on finance for selected period"
                                averageTooltip={type === "financeNew" ? "Comparison of total finance volume against selected brand average" : "Comparison of total finance volume against group average"}
                            />
                            <UnitValuesSitePerformance
                                mainValue={finprofit}
                                mainTitle="Commission"
                                averageValue={Math.round(finprofitbrand/qntyCalc)}
                                averageTitle={textAvg+"Avg."}
                                mainType={false}
                                mainValueType="money"
                                mainValueTooltip={type === "financeNew" ? "Total amount of finance commission in selected period. Based on new vehicle manufacturer matrix" : "Total amount of finance commission in selected period. Based on total of FCOMM costed in Keyloop"}
                                averageTooltip={type === "financeNew" ? "Comparison of total finance commission against selected brand average" : "Comparison of total finance commission against group average"}
                            />
                        </div>
                    </Grid>
                    <SummaryTableSitePerformance
                        tableData={resData.financeCompaniesDetails}
                        totalItems={resData.totalRetailCases}
                        dataType={newOrUsedType}
                        modalHandler={modalHandler}
                    />
                </> :
                    typeSelected === "products" ? <>
                        <Grid container>
                            <TotalUnitsSitePerformance
                                value={(cases/mainCases).toFixed(2)}
                                valueCalc={true}
                                valueSec={cases}
                                valueType="units"
                                label="Products Per Sale"
                                budgetLable={textAvg+"Avg."}
                                profitVal={(profitVal/mainCasesAll).toFixed(2)}
                                previousYear={casesPrevious}
                                lowerLabel={subText}
                                mainType={false}
                                mainValueTooltip={casesTooltip}
                                averageTooltip={brandTooltip}
                                priorYearTooltip={priorTooltip}
                            />
                            <div className='divider'></div>
                            <div className='barWrapperSP'>
                                <UnitValuesSitePerformance
                                    mainValue={profit}
                                    mainTitle="Total Product Profit"
                                    averageValue={profitBrand !== 0 ? Math.round((profitBrand / qntyCalc)) : 0}
                                    averageTitle={textAvg+"Avg."}
                                    mainType={false}
                                    mainValueType="money"
                                    mainValueTooltip="Total profit from product sales in selected period"
                                    averageTooltip={type === "productsNew" ? "Comparison of total product profit against selected brand average" : "Comparison of total product profit against group average"}
                                />
                                <UnitValuesSitePerformance
                                    mainValue={Math.round(cases * 100 / mainCases)}
                                    mainTitle="Total Product Penetration"
                                    averageValue={Math.round(profitVal * 100 / mainCasesAll)}
                                    averageTitle={textAvg+"Avg."}
                                    mainType={false}
                                    mainValueType="percentage"
                                    mainValueTooltip="Product penetration against new retail sales in selected period"
                                    averageTooltip={type === "productsNew" ? "Comparison of total product penetration against selected brand average" : "Comparison of total product penetration against group average"}
                                />
                                <UnitValuesSitePerformance
                                    mainValue={resData.superGard}
                                    mainTitle="Supagard"
                                    averageValue={Math.round((resData.brandSupergard) / mainCasesAll)}
                                    averageDisplayValue ={Math.round((resData.brandSupergard*100) / mainCasesAll) +"%"}
                                    averageTitle={textAvg+"Avg."}
                                    mainType={false}
                                    pen={Math.round((resData.superGard*100)/mainCases)}
                                    mainValueType="value"
                                    averageValueType="percentage"
                                    mainValueTooltip="Total number of Supagard sales in selected period"
                                    penTooltip="Penetration percentage of Supagard sales against retail units in selected period. Red <40%, Green >40%"
                                    averageTooltip={type === "productsNew" ? "Comparison of Supagard penetration against selected brand average" : "Comparison of Supagard penetration against group average"}
                                />
                                <UnitValuesSitePerformance
                                    mainValue={resData.servicePlan}
                                    mainTitle="Service Plan"
                                    averageValue={Math.round(resData.brandServicePlan / mainCasesAll)}
                                    averageTitle={textAvg+"Avg."}
                                    mainType={false}
                                    pen={Math.round((resData.servicePlan*100)/mainCases)}
                                    mainValueType="value"
                                    averageDisplayValue ={Math.round((resData.brandServicePlan*100) / mainCasesAll) +"%"}
                                    averageValueType="percentage"
                                    mainValueTooltip="Total number of Service Plan sales in selected period"
                                    penTooltip="Penetration percentage of Service Plan sales against retail units in selected period. Red <40%, Green >40%"
                                    averageTooltip={type === "productsNew" ? "Comparison of Service Plan penetration against selected brand average. Applies all Service Plan variants" : "Comparison of Service Plan penetration against group average. Applies to all Service Plan variants"}
                                />
                                <UnitValuesSitePerformance
                                    mainValue={resData.tyreAlloy}
                                    mainTitle="Tyre & Alloy"
                                    averageValue={Math.round(resData.brandtyreAlloy / mainCasesAll)}
                                    averageTitle={textAvg+"Avg."}
                                    mainType={false}
                                    pen={Math.round((resData.tyreAlloy*100)/mainCases)}
                                    mainValueType="value"
                                    averageDisplayValue ={Math.round((resData.brandtyreAlloy*100) / mainCasesAll) +"%"}
                                    averageValueType="percentage"
                                    mainValueTooltip="Total number of Tyre & Alloy policy sales in selected period"
                                    penTooltip="Penetration percentage of T&A sales against retail units in selected period. Red <25%, Green >25%"
                                    averageTooltip={type === "productsNew" ? "Comparison of Tyre & Alloy penetration against selected brand average. Applies to all T&A variants" : "Comparison of Tyre & Alloy penetration against group average. Applies to all T&A variants"}
                                />
                                <UnitValuesSitePerformance
                                    mainValue={resData.cosmetic}
                                    mainTitle="Cosmetic"
                                    averageValue={Math.round(resData.brandCosmetic / mainCasesAll)}
                                    averageTitle={textAvg+"Avg."}
                                    mainType={false}
                                    pen={Math.round((resData.cosmetic*100)/mainCases)}
                                    mainValueType="value"
                                    averageDisplayValue ={Math.round((resData.brandCosmetic*100) / mainCasesAll) +"%"}
                                    averageValueType="percentage"
                                    mainValueTooltip="Total number of Cosmetic policy sales in selected period"
                                    penTooltip="Penetration percentage of Cosmetic sales against retail units in selected period. Red <25%, Green >25%"
                                    averageTooltip={type === "productsNew" ? "Comparison of Cosmetic penetration against selected brand average. Applies to all Cosmetic variants" : "Comparison of Cosmetic penetration against group average. Applies to all Cosmetic variants."}
                                />
                            </div>  
                        </Grid>
                        <SummaryTableSitePerformance
                            tableData={resData.produtsList}
                            totalItems={resData.totalVolume}
                            totalItemsBrand={profitVal}
                            modalHandler={modalHandler}
                            dataType={newOrUsedType}
                            listType={listType}
                            totalSold={type === "productsUsed" ? resDataMain.dashboardCurrent[0].usedRetail : resDataMain.dashboardCurrent[0].newRetail}
                            totalSoldBrand={type === "productsUsed" ? resDataMain.dashboardall[0].usedRetail : resDataMain.dashboardall[0].newRetail}
                        />
                    </> :
                        typeSelected === "warranty" ? <>
                            <Grid container>
                                <TotalUnitsSitePerformance
                                    value={cases}
                                    valueType="units"
                                    label="Total Volume"
                                    budgetLable={textAvg+"Avg."}
                                    profitVal={Math.round(profitVal / qntyCalc)}
                                    previousYear={casesPrevious}
                                    mainType={false}
                                    lowerLabel={subText}
                                    mainValueTooltip={casesTooltip}
                                    averageTooltip={brandTooltip}
                                    priorYearTooltip={priorTooltip}
                                />
                                <div className='divider'></div>
                                <div className='barWrapperSP'>
                                    <UnitValuesSitePerformance
                                        mainValue={profit}
                                        mainTitle="Total Warranty Profit"
                                        averageValue={profitBrand !== 0 ? Math.round((profitBrand / qntyCalc)) : 0}
                                        averageTitle={textAvg+"Avg."}
                                        mainType={false}
                                        mainValueType="money"
                                        mainValueTooltip="Total profit from Warranty sales in selected period. Includes Chorley Care Premium, Chorley Care Premium Plus and NIC Extended"
                                        averageTooltip="Comparison of total warranty profit against group average"
                                    />
                                    <UnitValuesSitePerformance
                                        mainValue={Math.round(cases * 100 / mainCases)}
                                        mainTitle="Avg Warranty Penetration"
                                        averageValue={Math.round(profitVal * 100 / mainCasesAll)}
                                        averageTitle={textAvg+"Avg."}
                                        mainType={false}
                                        mainValueType="percentage"
                                        mainValueTooltip="Average penetration of warranty sales again retail units. CCPRE, CCPRE+ and NICEXT"
                                        averageTooltip="Comparison of warranty penetration against group average"
                                    />
                                    <UnitValuesSitePerformance
                                        mainValue={resData.ccpre}
                                        mainTitle="Chorley Care Pre"
                                        averageValue={Math.round(resData.ccpreGroup / resData.brandQnty)}
                                        averageTitle={textAvg+"Avg."}
                                        mainType={false}
                                        mainValueType="quantity"
                                        mainValueTooltip="Total number of Chorley Care Premium policies sold in selected period"
                                        averageTooltip="Comparison of Chorley Care Premium policies sold against group average"
                                    />
                                    <UnitValuesSitePerformance
                                        mainValue={resData.ccprePlus}
                                        mainTitle="Chorley Care Pre +"
                                        averageValue={Math.round(resData.ccprePlusGroup / resData.brandQnty)}
                                        averageTitle={textAvg+"Avg."}
                                        mainType={false}
                                        mainValueType="quantity"
                                        mainValueTooltip="Total number of Chorley Care Premium Plus policies sold in selected period"
                                        averageTooltip="Comparison of Chorley Care Premium Plus policies sold against group average"
                                    />
                                    <UnitValuesSitePerformance
                                        mainValue={resData.nicEx}
                                        mainTitle="NIC Extended"
                                        averageValue={Math.round(resData.nicExGroup / resData.brandQnty)}
                                        averageTitle={textAvg+"Avg."}
                                        mainType={false}
                                        mainValueType="quantity"
                                        mainValueTooltip="Total number of NIC Extended policies sold in selected period"
                                        averageTooltip="Comparison of NIC Extended policies sold against group average"
                                    />
                                    <UnitValuesSitePerformance
                                        mainValue={resData.ccess}
                                        mainTitle="Missed Upsell Opportunity"
                                        averageValue={Math.round(resData.ccessGroup / resData.brandQnty)}
                                        averageTitle={textAvg+"Avg."}
                                        mainType={false}
                                        mainValueType="money"
                                        mainValueTooltip="Missed revenue from retail units with no warranty upsell. Total number of Chorley Care Essentials policies multiplied by profit available from a Chorley Care Premium policy plus Chorley Care Essentials cost"
                                        averageTooltip="Comparison of missed warranty upsell opportunity against group average"
                                    />
                                </div>  
                            </Grid>
                            <SummaryTableSitePerformance
                                tableData={resData.produtsList}
                                totalItems={resData.totalVolume}
                                totalItemsBrand={profitVal}
                                dataType={newOrUsedType}
                                modalHandler={modalHandler}
                                totalSold={resDataMain.dashboardCurrent[0].usedRetail}
                                totalSoldBrand={resDataMain.dashboardall[0].usedRetail}
                                war12M={resData.lookUpWar12}
                                warTopUp={resData.lookUpWarTopUp}
                            />
                        </> : 
                            typeSelected === "accessories" ? <>
                                <Grid container>
                                    <TotalUnitsSitePerformance
                                        value={cases}
                                        valueType="units"
                                        label="Incorrect Items"
                                        //previousYear={casesPrevious}
                                        mainType={false}
                                        lowerLabel={subText}
                                        removeBrad={true}
                                        removePrevious={true}
                                        mainValueTooltip={casesTooltip}
                                        averageTooltip={brandTooltip}
                                        priorYearTooltip={priorTooltip}
                                    />
                                    <div className='divider'></div>
                                    <div className='barWrapperSP'>
                                        <UnitValuesSitePerformance
                                            mainValue={profit}
                                            mainTitle="Total Accessory Profit"
                                            // averageValue={profitBrand !== 0 ? Math.round((profitBrand / qntyCalc)) : 0}
                                            averageValue={0}
                                            averageTitle={textAvg+"Avg."}
                                            mainType={false}
                                            mainValueType="money"
                                            mainValueTooltip="Total accessory sales profit for selected period"
                                            averageTooltip={type === "accNew" ? "Comparison of accessory sales profit against selected brand average" : "Comparison of accessory sales profit against group average"}
                                            hideAverage={true}
                                        />
                                         <UnitValuesSitePerformance
                                            mainValue={resData.deliveryPack}
                                            mainTitle="Delivery Pack"
                                            averageValue={Math.round((resData.deliveryPackGrp) / resData.brandQnty)}
                                            pen={formatters.CurrencyThousandSeparatorWithoutZero(resData.deliveryProfit)}
                                            averageTitle={textAvg+"Avg."}
                                            mainType={false}
                                            mainValueType="value"
                                            mainValueTooltip="Number of Accessory Delivery Packs sold in selected period"
                                            averageTooltip={type === "accNew" ? "Comparison of delivery pack sales against selected brand average" : "Comparison of delivery pack sales against group average"}
                                            penTooltip=""
                                            hideAverage={true}
                                        />
                                        {/* <UnitValuesSitePerformance
                                            mainValue={Math.round(cases * 100 / mainCases)}
                                            mainTitle="Avg Penetration"
                                            averageValue={Math.round(profitVal * 100 / mainCasesAll)}
                                            averageTitle={textAvg+"Avg."}
                                            mainType={false}
                                            mainValueType="percentage"
                                        /> */}
                                        <UnitValuesSitePerformance
                                            mainValue={resData.adminFee}
                                            mainTitle="Incorrect Admin Fee"
                                            // averageValue={Math.round((resData.adminFeeGrp) / resData.brandQnty)}
                                            // averageTitle={textAvg+"Avg."}
                                            mainType={false}
                                            mainValueType="value"
                                            hideAverage={true}
                                            colourMainRed={true}
                                            mainValueTooltip="Number of incorrectly costed admin fee in selected period"
                                            averageTooltip=""
                                        />
                                        <UnitValuesSitePerformance
                                            mainValue={resData.fuelCharge}
                                            mainTitle="Incorrect Fuel/EV"
                                            // averageValue={Math.round((resData.fuelChargeGrp) / resData.brandQnty)}
                                            // averageTitle={textAvg+"Avg."}
                                            mainType={false}
                                            mainValueType="value"
                                            hideAverage={true}
                                            colourMainRed={true}
                                            mainValueTooltip="Number of incorrectly costed Fuel/EV Charges in selected period"
                                            averageTooltip=""
                                        />
                                        {
                                            type === "accUsed" ? <>
                                            <UnitValuesSitePerformance
                                                mainValue={resData.adminFeeTrade}
                                                mainTitle="Incorrect Admin Fee (Trade)"
                                                // averageValue={Math.round((resData.adminFeeTradeGrp) / resData.brandQnty)}
                                                // averageTitle={textAvg+"Avg."}
                                                mainType={false}
                                                mainValueType="value"
                                                hideAverage={true}
                                                colourMainRed={true}
                                                mainValueTooltip="Number of incorrectly costed admin fee in selected period"
                                                averageTooltip=""
                                            />
                                            </> : <></>
                                        }
                                    </div>  
                                </Grid>
                                <SummaryTableSitePerformance
                                    tableData={resData.accList}
                                    totalItems={resData.totalVolume}
                                    totalItemsBrand={profitVal}
                                    //adminFee={((mainCases - resData.adminTotal) < 0 ? 0 : mainCases - resData.adminTotal)}
                                    //fuelCharge={((mainCases - resData.fuelTotal) < 0 ? 0 : mainCases - resData.fuelTotal)}
                                    //tradeFee={((resDataMain.dashboardCurrent[0].usedTrade - resData.adminTradeTotal) < 0 ? 0 : resDataMain.dashboardCurrent[0].usedTrade - resData.adminTradeTotal)}
                                    dataType={newOrUsedType}
                                    modalHandler={modalHandler}
                                    totalSold={type === "accUsed" ? resDataMain.dashboardCurrent[0].usedRetail : resDataMain.dashboardCurrent[0].newRetail}
                                    totalSoldTrade={resDataMain.dashboardCurrent[0].usedTrade}
                                    totalSoldBrand={type === "accUsed" ? resDataMain.dashboardall[0].usedRetail : resDataMain.dashboardall[0].newRetail}
                                />
                            </> :
                            <></>
            }
        </>
    )
}

export const SummaryCard = (props) => {
    const { mainData, resData, type, lowerLabel } = props;
    let name, value, previousValue, brandAvg, searchedId,
        chassisProfitAll, chassisProfit,
        totalUnitsAll, totalUnits,
        qntyRetailAll, qntyRetail,
        motabilityAll, motability,
        usedTrade, usedTradeAll;

    totalUnitsAll = resData.detailsAllBranchBrand[0].totalUnit === null ? 0 : resData.detailsAllBranchBrand[0].totalUnit;
    totalUnits = mainData.dashboardCurrent[0].totalUnit === null ? 0 : mainData.dashboardCurrent[0].totalUnit;
    motabilityAll = resData.detailsAllBranchBrand[0].motability === null ? 0 : resData.detailsAllBranchBrand[0].motability;
    motability = mainData.dashboardCurrent[0].motability === null ? 0 : mainData.dashboardCurrent[0].motability;
    searchedId = resData.detailsAllBranchBrand[0].qntyBranchBrandSpecific;

    if (type === "summaryNew") {
        name = "New";
        value = mainData.dashboardCurrent[0].newVehicleProfit;
        previousValue = mainData.dashboardCurrent[0].newVehicleProfitPreviousYear;
        brandAvg = resData.detailsAllBranchBrand[0].newVehicleProfit / searchedId;
        chassisProfitAll = resData.detailsAllBranchBrand[0].newTotalPPU === null ? 0 : resData.detailsAllBranchBrand[0].newTotalPPU;
        chassisProfit = mainData.dashboardCurrent[0].newTotalPPU === null ? 0 : mainData.dashboardCurrent[0].newTotalPPU;
        qntyRetailAll = resData.detailsAllBranchBrand[0].newRetail === null ? 0 : resData.detailsAllBranchBrand[0].newRetail;
        qntyRetail = mainData.dashboardCurrent[0].newRetail === null ? 0 : mainData.dashboardCurrent[0].newRetail;
    }
    else if (type === "summaryUsed") {
        name = "Used";
        value = mainData.dashboardCurrent[0].usedVehicleProfit;
        previousValue = mainData.dashboardCurrent[0].usedVehicleProfitPreviousYear;
        brandAvg = resData.detailsAllBranchBrand[0].usedVehicleProfit / resData.detailsAllBranchBrand[0].qntyBranchBrand;
        chassisProfitAll = resData.detailsAllBranchBrand[0].usedTotalPPU === null ? 0 : resData.detailsAllBranchBrand[0].usedTotalPPU;
        chassisProfit = mainData.dashboardCurrent[0].usedTotalPPU === null ? 0 : mainData.dashboardCurrent[0].usedTotalPPU;
        qntyRetailAll = resData.detailsAllBranchBrand[0].usedRetail === null ? 0 : resData.detailsAllBranchBrand[0].usedRetail;
        qntyRetail = mainData.dashboardCurrent[0].usedRetail === null ? 0 : mainData.dashboardCurrent[0].usedRetail;
        usedTrade = mainData.dashboardCurrent[0].usedTrade === null ? 0 : mainData.dashboardCurrent[0].usedTrade;
        usedTradeAll = resData.detailsAllBranchBrand[0].usedTrade === null ? 0 : resData.detailsAllBranchBrand[0].usedTrade;
    }

    return (
        <>
            <Grid container>
                {
                    type === "summaryNew" ? <>
                        <TotalUnitsSitePerformance
                            value={value}
                            valueType="currency"
                            label={"Total " + name + " Profit"}
                            lowerLabel={lowerLabel}
                            budgetLable="Brand Avg."
                            profitVal={brandAvg}
                            previousYear={previousValue}
                            mainType={false}
                            mainValueTooltip="Total new vehicle profit (excluding trade & fleet). Includes products and finance"
                            averageTooltip = "Comparison of total profit against selected Brand average"
                            priorYearTooltip = "% change on prior year for selected period"
                        />
                        <div className='divider'></div>
                        <div className='barWrapperSP'>
                            <UnitValuesSitePerformance
                                mainValue={chassisProfit}
                                mainTitle="Avg Retail PPU"
                                averageValue={Math.round(chassisProfitAll)}
                                averageTitle="Brand Avg"
                                mainType={false}
                                mainValueType="money"
                                mainValueTooltip="Average retail profit per unit (including finance and products)"
                                averageTooltip="Comparison of Retail profit per unit against average for selected Branch"
                            />
                            <UnitValuesSitePerformance
                                mainValue={qntyRetail + motability}
                                mainTitle="Total Units"
                                averageValue={Math.round((qntyRetailAll + motabilityAll) / searchedId)}
                                averageTitle="Brand Avg"
                                mainType={false}
                                mainValueType="units"
                                mainValueTooltip="Total Units - Retail and Motability"
                                averageTooltip="Comparison of total units (Retail and Motability) against Brand average"
                            />
                            <UnitValuesSitePerformance
                                mainValue={qntyRetail}
                                mainTitle={name + " Retail"}
                                averageValue={Math.round(qntyRetailAll / searchedId)}
                                averageTitle="Brand Avg"
                                mainType={false}
                                mainValueType="units"
                                mainValueTooltip="Total Retail Units"
                                averageTooltip="Comparison of total retail units against Brand average"
                            />
                            <UnitValuesSitePerformance
                                mainValue={motability}
                                mainTitle="Motability"
                                averageValue={Math.round(motabilityAll / searchedId)}
                                averageTitle="Brand Avg"
                                mainType={false}
                                mainValueType="units"
                                mainValueTooltip="Total Motability Units"
                                averageTooltip="Comparison of total Motability units against brand average"
                            />
                            <UnitValuesSitePerformance
                                mainValue={mainData.dashboardCurrent[0].fleetNew}
                                mainTitle="Fleet"
                                averageValue={Math.round(mainData.dashboardall[0].fleetNew / searchedId)}
                                averageTitle="Brand Avg"
                                mainType={false}
                                mainValueType="units"
                                mainValueTooltip="Total Fleet Units"
                                averageTooltip="Comparison of total Fleet units against brand average"
                            />
                        </div>
                    </> : <>
                        <TotalUnitsSitePerformance
                            value={value}
                            valueType="currency"
                            label={"Total " + name + " Profit"}
                            lowerLabel={lowerLabel}
                            budgetLable="Grp Avg."
                            profitVal={brandAvg}
                            previousYear={previousValue}
                            mainType={false}
                            mainValueTooltip="Total used vehicle profit (excluding trade) - Includes products, warranty and finance commission"
                            averageTooltip = "Comparison of total used profit against group average for selected period"
                            priorYearTooltip = "% change on prior year for selected period"
                        />
                        <div className='divider'></div>
                        <div className='barWrapperSP'>
                            <UnitValuesSitePerformance
                                mainValue={chassisProfit}
                                mainTitle="Avg Retail PPU"
                                averageValue={Math.round(chassisProfitAll)}
                                averageTitle="Grp Avg"
                                mainType={false}
                                mainValueType="money"
                                mainValueTooltip="Average retail profit per unit including products, warranty and finance"
                                averageTooltip="Comparison of average retail profit per unit against Group for selected period"
                            />
                            <UnitValuesSitePerformance
                                mainValue={qntyRetail}
                                mainTitle={name + " Retail"}
                                averageValue={Math.round(qntyRetailAll / resData.detailsAllBranchBrand[0].qntyBranchBrand)}
                                averageTitle="Grp Avg"
                                mainType={false}
                                mainValueType="units"
                                mainValueTooltip="Total number of used retail units"
                                averageTooltip="Comparison of total retail sales against Group for selected period"
                            />
                            <UnitValuesSitePerformance
                                mainValue={Math.round(resData.mecPrep / qntyRetail)}
                                mainTitle="Mechanical Prep"
                                averageValue={Math.round(resData.mecPrepAll / qntyRetailAll)}
                                averageTitle="Grp Avg"
                                mainType={false}
                                mainValueType="money"
                                mainValueTooltip="Average amount of Mechanical Prep per unit (does not include bodywork)"
                                averageTooltip="Comparison against group average for mechanical prep (does not include bodywork)"
                            />
                             <UnitValuesSitePerformance
                                mainValue={Math.round(resData.bodywork / qntyRetail)}
                                mainTitle="Bodywork"
                                averageValue={Math.round(resData.bodyworkAll / qntyRetailAll)}
                                averageTitle="Grp Avg"
                                mainType={false}
                                mainValueType="money"
                                mainValueTooltip="Average amount of Bodywork"
                                averageTooltip="Comparison against group average for Bodywork"
                            />
                            <UnitValuesSitePerformance
                                mainValue={usedTrade}
                                mainTitle={"Trade"}
                                averageValue={Math.round(usedTradeAll / resData.detailsAllBranchBrand[0].qntyBranchBrand)}
                                averageTitle="Grp Avg"
                                mainType={false}
                                mainValueType="units"
                                mainValueTooltip="Total number of trade sales in selected period"
                                averageTooltip="Comparison of total Trade units against group for selected period"
                            />
                            <UnitValuesSitePerformance
                                mainValue={Math.round(mainData.dashboardCurrent[0].usedTradeProfit / usedTrade)}
                                mainTitle="Trade PPU"
                                averageValue={Math.round(mainData.dashboardall[0].usedTradeProfit/usedTradeAll)}
                                averageTitle="Grp Avg"
                                mainType={false}
                                mainValueType="money"
                                mainValueTooltip="Average profit per unit for trade sales in selected period"
                                averageTooltip="Comparison of average trade profit per unit against group for selected period"
                            />
                        </div>
                    </>
                }
            </Grid>
            <Grid container justifyContent='space-between' style={{ flexWrap: 'nowrap' }} className='summaryBreakdownWRP'>
                <span className='summaryMainTile'>{name} Vehicle Breakdown</span>
                {
                    type === "summaryNew" ? <>
                        <SummaryBreakDownSitePerformance
                            title="Finance"
                            cases={resData.financeCurrentQnty}
                            casesTitle="Cases"
                            avg={Math.round(resData.financeAllQnty / searchedId)}
                            avgTitle="Brand Avg."
                            value={resData.financeValueCurrent}
                            valueTitle="Commission"
                            pen={resData.financeCurrentQnty !== 0 ? Math.round((resData.financeCurrentQnty * 100) / qntyRetail) : 0}
                            casesTooltip="Total number of vehicles sold on Finance in selected period"
                            averageTooltip="Brand average of number of vehicles sold on finance for selected period"
                            profitTooltip="Total amount of finance commission for selected period"
                            penTooltip="Percentage penetration of vehicles on Finance against total Retail sales"
                        />
                        <SummaryBreakDownSitePerformance
                            title="Products"
                            cases={resData.productsQntyCurrent}
                            casesTitle="Sold"
                            avg={Math.round(resData.productsQntyAll / searchedId)}
                            avgTitle="Brand Avg."
                            value={resData.productsValueCurrent}
                            valueTitle="Profit"
                            pen={resData.productsQntyCurrent !== 0 ? ((resData.productsQntyCurrent/qntyRetail).toFixed(2)) : 0}
                            casesTooltip="Number of products sold in selected period - Includes Supagard, Service Plan, Cosmetic and T&A"
                            averageTooltip="Brand average of total products sold in selected period"
                            profitTooltip="Total profit from products sold in selected period"
                            penTooltip="Percentage penetration of products sold against total Retail sales"
                        />
                        <SummaryBreakDownSitePerformance
                            title="CSI"
                            casesTooltip=""
                            averageTooltip=""
                            profitTooltip=""
                            penTooltip=""
                            // cases={resData.warrantyQntyCurrent !== 0 ? resData.warrantyQntyCurrent : 0}
                            // casesTitle="Sold"
                            // avg={resData.warrantyQntyAll !== 0 ? Math.round(resData.warrantyQntyAll / searchedId) : 0}
                            // avgTitle="Brand Avg."
                            // value={resData.warrantyValueCurrent !== 0 ? resData.warrantyValueCurrent : 0}
                            // valueTitle="Profit"
                            // pen={resData.warrantyQntyCurrent !== 0 ? Math.round((resData.warrantyQntyCurrent * 100) / qntyRetail) : 0}
                        />
                        <SummaryBreakDownSitePerformance
                            title="Admin / Fuel"
                            cases={resData.accessoryQntyCurrent + resData.fuelQntyCurrent }
                            casesTitle="Incorrect"
                            avg={Math.round(resData.accessoryQntyAll / searchedId)}
                            avgTitle="Brand Avg."
                            value={resData.accessoryValueCurrent}
                            valueTitle="Profit"
                            pen={resData.accessoryQntyCurrent !== 0 ? Math.round((resData.accessoryQntyCurrent * 100) / qntyRetail) : 0}
                            casesTooltip="Number of accessories sold in selected period"
                            averageTooltip="Number of missing Admin / Fuel charges in selected period"
                            profitTooltip="Total profit from Accessory sales in selected period"
                            penTooltip=""
                        />
                    </>:
                    <>
                        <SummaryBreakDownSitePerformance
                            title="Finance"
                            cases={resData.financeCurrentQnty}
                            casesTitle="Cases"
                            avg={Math.round(resData.financeAllQnty / resData.detailsAllBranchBrand[0].qntyBranchBrand)}
                            avgTitle="Grp Avg."
                            value={resData.financeValueCurrent}
                            valueTitle="Commission"
                            pen={resData.financeCurrentQnty !== 0 ? Math.round((resData.financeCurrentQnty * 100) / qntyRetail) : 0}
                            casesTooltip="Total number of vehicles sold on finance in selected period"
                            averageTooltip="Group average for number of vehicles sold on finance in selected period"
                            profitTooltip="Total amount of finance commission for selected period"
                            penTooltip="Percentage penetration of vehicles on Finance against total Retail sales"
                        />
                        <SummaryBreakDownSitePerformance
                            title="Products"
                            cases={resData.productsQntyCurrent}
                            casesTitle="Sold"
                            avg={Math.round(resData.productsQntyAll / resData.detailsAllBranchBrand[0].qntyBranchBrand)}
                            avgTitle="Grp Avg."
                            value={resData.productsValueCurrent}
                            valueTitle="Profit"
                            pen={resData.productsQntyCurrent !== 0 ? (resData.productsQntyCurrent/qntyRetail).toFixed(2) : 0}
                            casesTooltip="Number of products sold in selected period - Includes Supagard, Service Plan, Cosmetic, T&A and Warranty"
                            averageTooltip="Group average of total products sold in selected period"
                            profitTooltip="Total profit from products sold in selected period"
                            penTooltip="Percentage penetration of products sold against total Retail sales"
                        />
                        <SummaryBreakDownSitePerformance
                            title="Warranty"
                            cases={resData.warrantyQntyCurrent !== 0 ? resData.warrantyQntyCurrent : 0}
                            casesTitle="Sold"
                            avg={resData.warrantyQntyAll !== 0 ? Math.round(resData.warrantyQntyAll / resData.detailsAllBranchBrand[0].qntyBranchBrand) : 0}
                            avgTitle="Grp Avg."
                            value={resData.warrantyValueCurrent !== 0 ? resData.warrantyValueCurrent : 0}
                            valueTitle="Profit"
                            pen={resData.warrantyQntyCurrent !== 0 ? Math.round((resData.warrantyQntyCurrent * 100) / qntyRetail) : 0}
                            casesTooltip="Total number of Warranties sold in selected period. Includes CCPRE, CCPRE+ and NIC Ext"
                            averageTooltip="Group average of total warranties sold in selected period"
                            profitTooltip="Total profit from sold Warranties in selected period. Includes CCPRE, CCPRE+ and NIC Ext"
                            penTooltip="Percentage penetration of Warranties sold against total Retail sales"
                        />
                        <SummaryBreakDownSitePerformance
                            title="Admin / Fuel"
                            cases={resData.accessoryQntyCurrent + resData.fuelQntyCurrent }
                            casesTitle="Incorrect"
                            avg={Math.round(resData.accessoryQntyAll / resData.detailsAllBranchBrand[0].qntyBranchBrand)}
                            avgTitle="Grp Avg."
                            value={resData.accessoryValueCurrent}
                            valueTitle="Profit"
                            pen={resData.accessoryQntyCurrent !== 0 ? Math.round((resData.accessoryQntyCurrent * 100) / qntyRetail) : 0}
                            casesTooltip="Number of missing Admin / Fuel charges in selected period"
                            averageTooltip=""
                            profitTooltip="Total profit from Accessory sales in selected period"
                            penTooltip=""
                        />
                    </>
                }
            </Grid>
        </>
    );
}