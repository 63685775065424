import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { Grid } from '@material-ui/core';
import { TotalUnitsSitePerformance, ProgressBarSitePerformance, UnitValuesSitePerformance } from '../../../Core/BudgetCore/budgetCardsUi_v2';

export const SitePerformanceMainData = (props) => {
    const [WindowWidths] = useWindowSize();
    const { dataAfterFilter } = props;
    let dataDBCurrent = dataAfterFilter.dashboardCurrent[0];
    let dataDBAll = dataAfterFilter.dashboardall[0];
    let branchNumbersDB = dataAfterFilter.dashboardCurrent[0].qntyBranchBrand;
    let stockDetailsCurrentDB = dataAfterFilter.stockDetailsCurrent;
    let stockDetailsAllDB = dataAfterFilter.stockDetailsAll[0];
    let perSale = dataAfterFilter.prodQntyBranch / dataDBCurrent.newRetail;
    let perSaleUsed = dataAfterFilter.prodQntyBranchUsed / dataDBCurrent.usedRetail;
    let perSaleAvg = dataAfterFilter.prodQntyGroup / dataDBAll.newRetail;
    let perSaleAvgUsed = dataAfterFilter.prodQntyGroupUsed / dataDBAll.usedRetail;

    return (
        <Grid item xs={12} className='largeBoxGrid'>
            <Grid container alignItems="center">
                <TotalUnitsSitePerformance
                    value={dataDBCurrent.totalUnit}
                    valueType="unit"
                    label="Total Units"
                    budgetLable="Grp Avg."
                    profitVal={Math.round(dataDBAll.totalUnit / branchNumbersDB)}
                    previousYear={dataDBCurrent.totalUnitPrevious}
                    mainType={true}
                    lowerLabel={"(excl trade & fleet)"}
                    mainValueTooltip={"Total number of New Retail, Motability and Used Retail units. Excludes Fleet and Trade"}
                    averageTooltip={"Comparison against Group Average"}
                    priorYearTooltip={"% change on prior year for same period"}
                />
                <div className='divider'></div>
                {
                    WindowWidths < 1200 ? <>
                        <div className='barWrapperSP'>
                            <ProgressBarSitePerformance
                                value={dataDBCurrent.totalUnit}
                                title="Sales Units By Type"
                                value1={dataDBCurrent.newRetail}
                                title1="New"
                                value2={dataDBCurrent.usedRetail}
                                title2="Used"
                                title3="Motab"
                                value1Tooltip={"Total number of New Retail units"}
                                value2Tooltip={"Total number of Motability units"}
                                value3Tooltip={"Total number of Used Retail units"}
                            />
                            <div className='divider hidden-lg'></div>
                            <UnitValuesSitePerformance
                                mainValue={parseFloat(perSale.toFixed(2))}
                                mainTitle="New PPS"
                                averageValue={parseFloat(perSaleAvg.toFixed(2))}
                                averageTitle="Grp Avg"
                                mainType={true}
                                mainValueType="normal"
                                mainValueTooltip={"New Products Per Sale. Total number of products sold / total number of units. Applies to Supagard, Cosmetic and T&A. Retail units only"}
                                averageTooltip={"Comparison against group average"}
                            />
                            <div className='divider hidden-lg'></div>
                            <UnitValuesSitePerformance
                                mainValue={parseFloat(perSaleUsed.toFixed(2))}
                                mainTitle="Used PPS"
                                averageValue={parseFloat(perSaleAvgUsed.toFixed(2))}
                                averageTitle="Grp Avg"
                                mainType={true}
                                mainValueType="normal"
                                mainValueTooltip={"Used Products Per Sale. Total number of products sold / total number of units. Applies to Supagard, Cosmetic, T&A, Chorley Care Premium and NIC Extended Warranties"}
                                averageTooltip={"Comparison against Group Average"}
                            />
                            <div className='divider hidden-lg'></div>
                            <UnitValuesSitePerformance
                                mainValue={stockDetailsCurrentDB}
                                mainTitle="Stock Turn"
                                averageValue={stockDetailsAllDB != undefined ? stockDetailsAllDB.stockTurn : "N/A"}
                                averageTitle="Grp Avg"
                                mainType={true}
                                mainValueType="normal"
                                mainValueTooltip={"Average Stock Turn for period. For previous months a value is taken at the start and end of the month to get an average. Current period is based on live data"}
                                averageTooltip={"Comparison against group average"}
                            />
                            <div className='divider hidden-lg'></div>
                            <UnitValuesSitePerformance
                                mainValue={stockDetailsCurrentDB}
                                mainTitle="Average DIS"
                                averageValue={stockDetailsAllDB != undefined ? stockDetailsAllDB.avgDis : "N/A"}
                                averageTitle="Grp Avg"
                                mainType={true}
                                mainValueType="normal"
                                mainValueTooltip={"Average Days in Stock for period. For previous months a value is taken at the start and end of the month to get an average. Current period is based on live data"}
                                averageTooltip={"Comparison against group average"}
                            />
                            <div className='divider hidden-lg'></div>
                            <UnitValuesSitePerformance
                                mainValue={stockDetailsCurrentDB}
                                mainTitle="Overaged (90+)"
                                averageValue={stockDetailsAllDB != undefined ? stockDetailsAllDB.totalPercPlus90 : "N/A"}
                                averageTitle="Grp Avg"
                                mainType={true}
                                mainValueType="percentage"
                                mainValueTooltip={"Percentage of stock over 90 days for period. For previous months a value is taken at the start and end of the month to get an average. Current period is based on live data"}
                                averageTooltip={"Comparison against group average"}
                            />
                        </div>
                    </> : <>
                        <ProgressBarSitePerformance
                            value={dataDBCurrent.totalUnit}
                            title="Sales Units By Type"
                            value1={dataDBCurrent.newRetail}
                            title1="New"
                            value2={dataDBCurrent.usedRetail}
                            title2="Used"
                            title3="Motab"
                            value1Tooltip={"Total number of New Retail units"}
                            value2Tooltip={"Total number of Motability units"}
                            value3Tooltip={"Total number of Used Retail units"}
                        />
                        <div className='divider hidden-lg'></div>
                        <UnitValuesSitePerformance
                                mainValue={parseFloat(perSale.toFixed(2))}
                                mainTitle="New PPS"
                                averageValue={parseFloat(perSaleAvg.toFixed(2))}
                                averageTitle="Grp Avg"
                                mainType={true}
                                mainValueType="normal"
                                mainValueTooltip={"New Products Per Sale. Total number of products sold / total number of units. Applies to Supagard, Cosmetic and T&A. Retail units only"}
                                averageTooltip={"Comparison against group average"}
                            />
                            <div className='divider hidden-lg'></div>
                            <UnitValuesSitePerformance
                                mainValue={parseFloat(perSaleUsed.toFixed(2))}
                                mainTitle="Used PPS"
                                averageValue={parseFloat(perSaleAvgUsed.toFixed(2))}
                                averageTitle="Grp Avg"
                                mainType={true}
                                mainValueType="normal"
                                mainValueTooltip={"Used Products Per Sale. Total number of products sold / total number of units. Applies to Supagard, Cosmetic, T&A, Chorley Care Premium and NIC Extended Warranties"}
                                averageTooltip={"Comparison against Group Average"}
                            />
                        <div className='divider hidden-lg'></div>
                        <UnitValuesSitePerformance
                            mainValue={stockDetailsCurrentDB}
                            mainTitle="Stock Turn"
                            averageValue={stockDetailsAllDB != undefined ? stockDetailsAllDB.stockTurn : "N/A"}
                            averageTitle="Grp Avg"
                            mainType={true}
                            mainValueType="normal"
                            linkTitle="Aged Stock"
                            link={'/agedStocks'}
                            mainValueTooltip={"Average Stock Turn for period. For previous months a value is taken at the start and end of the month to get an average. Current period is based on live data"}
                            averageTooltip={"Comparison against group average"}
                        />
                        <div className='divider hidden-lg'></div>
                        <UnitValuesSitePerformance
                            mainValue={stockDetailsCurrentDB}
                            mainTitle="Average DIS"
                            averageValue={stockDetailsAllDB != undefined ? stockDetailsAllDB.avgDis : "N/A"}
                            averageTitle="Grp Avg"
                            mainType={true}
                            mainValueType="normal"
                            linkTitle="Stock List"
                            link={'/usedStock'}
                            mainValueTooltip={"Average Days in Stock for period. For previous months a value is taken at the start and end of the month to get an average. Current period is based on live data"}
                            averageTooltip={"Comparison against group average"}
                        />
                        <div className='divider hidden-lg'></div>
                        <UnitValuesSitePerformance
                            mainValue={stockDetailsCurrentDB}
                            mainTitle="Overaged (90+)"
                            averageValue={stockDetailsAllDB != undefined ? stockDetailsAllDB.totalPercPlus90 : "N/A"}
                            averageTitle="Grp Avg"
                            mainType={true}
                            mainValueType="percentage"
                            linkTitle="Overage List"
                            link={'/overagePriority'}
                            mainValueTooltip={"Percentage of stock over 90 days for period. For previous months a value is taken at the start and end of the month to get an average. Current period is based on live data"}
                            averageTooltip={"Comparison against group average"}
                        />
                    </>
                }

            </Grid>
        </Grid>
    );
}