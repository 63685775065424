import React, { useState, useEffect, Suspense } from 'react';
import './App.scss';
import { Header } from './../Core/Header';
import AppContext from './AppContext';
import { AppStorage } from '../Core/Service/storage-service';
import { Toast } from './../Core/Toast';
import { get } from '../Core/Service/http-calls';
import { ProfileModal } from '../Core/Modal';

const Loader = () => (
    <div className="App">
        <div>loading...</div>
    </div>
);

function App() {
    const portalSettings = AppStorage.getPortalSetting() || { expired: true };
    const [contextState, setContextState] = useState({
        loggedIn: AppStorage.isLoggedIn(),
        userRoles: AppStorage.getUserRoles(),
        displayName: AppStorage.getName(),
        lastLogin: AppStorage.getLastLogin(),
        canResetPassword: AppStorage.getCanResetPassword(),
        portalSettings: portalSettings
    });

    const [tempMenus, setTempMenus] = useState({ menus: [], role: '', menuName: '' });

    const showToast = (msg) => {
        setContextState((st) => ({ ...st, snackbar_open: true, snackbar_message: msg }));
    };

    const hideToast = () => {
        setContextState((st) => ({ ...st, snackbar_open: false, snackbar_message: null }));
    };

    const showModal = (modalId) => {
        setContextState((st) => ({ ...st, visibleModalId: modalId }));
    };

    const hideModal = () => {
        setContextState((st) => ({ ...st, visibleModalId: '' }));
    };

    useEffect(() => {
        async function getPortalSetting() {
            if (portalSettings.expired) {
                const res = await get(`portalSetting`);
                if (res.data.details[0]) {
                    AppStorage.setPortalSetting(res.data.details[0]);
                    setContextState((st) => ({ ...st, portalSettings: AppStorage.getPortalSetting() }));
                }
            } else {
                setContextState((st) => ({ ...st, portalSettings: AppStorage.getPortalSetting() }));
            }
        }
        getPortalSetting();

        const listenMsg = ({ data }) => {
            if (data.type === 'trigger-logout') {
                AppStorage.performLogout();
                window.location = '/';
            }
        };
        window.addEventListener('message', listenMsg);
        return () => {
            window.removeEventListener('message', listenMsg);
        };
    }, []);

    useEffect(() => {
        window.document.title = portalSettings.portalSettingTitle || 'Nicol';
    }, [contextState.portalSettings]);

    return (
        <Suspense fallback={<Loader />}>
            <AppContext.Provider
                value={{
                    ...contextState,
                    showToast,
                    hideToast,
                    hideModal,
                    showModal,
                    tempMenus
                }}
            >
                <div className="App">
                    <Header />
                </div>
                <Toast />
                <ProfileModal />
            </AppContext.Provider>
        </Suspense>
    );
}

export default App;
