import Tooltip from '@material-ui/core/Tooltip';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Button, CircularProgress, Grid, Badge, IconButton } from '@material-ui/core';

import './Controls.scss';

export const DefaultButton = (props) => {
    const { isBtnLoader, ...rest } = props;

    return (
        <Button variant="contained" disabled={isBtnLoader} {...rest}>
            {!isBtnLoader ? (
                <>{props.children}</>
            ) : (
                <Grid style={{ position: 'relative', width: '100%' }}>
                    <Grid>{props.children}</Grid>

                    <Grid style={{ position: 'absolute', right: 0, top: '3px' }}>
                        <CircularProgress size={20} />
                    </Grid>
                </Grid>
            )}
        </Button>
    );
};

export const PrimaryButton = (props) => {
    return (
        <DefaultButton {...props} color="primary">
            {props.children}
        </DefaultButton>
    );
};

export const SecondaryButton = (props) => {
    return (
        <DefaultButton {...props} color="secondary">
            {props.children}
        </DefaultButton>
    );
};

export const GreenButton = (props) => {
    return (
        <Button className="greenbtn" fullWidth variant="contained" {...props}>
            {props.children}
        </Button>
    );
};

export const UploadWithBadge = (props) => {
    const { toolTipTitle, count, ...rest } = props;
    return (
        <Badge
            overlap="rectangular"
            badgeContent={count || 0}
            max={99}
            color="primary"
            anchorOrigin={{
                vertical: 'top',
                horizontal: rest.direction ? rest.direction : 'left'
            }}
        >
            <div className="uploadWithBadgeButton">
                <Tooltip title={toolTipTitle || ''} arrow>
                    <CameraAltIcon className="doneIcon" {...rest} />
                </Tooltip>
            </div>
        </Badge>
    );
};

export const YesButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <div>
                <DefaultButton className={rest.state ? 'btnActive' : 'btninActive'} value={true} {...rest}>
                    {rest.btnName || 'Yes'}
                </DefaultButton>
            </div>
        </Tooltip>
    );
};

export const NoButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <div>
                <DefaultButton className={rest.state ? 'btninActive' : 'btnActiveNo'} value={true} {...rest}>
                    {rest.btnName || 'No'}
                </DefaultButton>
            </div>
        </Tooltip>
    );
};

export const SquareButton = (props) => {
    const { toolTipTitle, ...rest } = props;
    return (
        <Tooltip title={toolTipTitle || ''} arrow>
            <Grid>
                <IconButton
                    style={
                        props.style
                            ? props.style
                            : {
                                  backgroundColor: 'green',
                                  borderRadius: '20%',
                                  width: '32px',
                                  height: '32px',
                                  textAlign: 'center',
                                  padding: '0px 0px 0px 0px'
                              }
                    }
                    onClick={props.onClick ? props.onClick : ''}
                >
                    {props.children}
                </IconButton>
            </Grid>
        </Tooltip>
    );
};

export const YesNoButton = (props) => {
    const { toolTipTitle, onYesClick, onNoClick, ...rest } = props;
    return (
        <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
                <Tooltip title={toolTipTitle || ''} arrow>
                    <div>
                        <DefaultButton onClick={onYesClick} className={rest.state ? 'btnActive' : 'btninActive'} value={true} {...rest}>
                            {rest.btnName || 'Yes'}
                        </DefaultButton>
                    </div>
                </Tooltip>
            </Grid>
            <Grid item xs={6} sm={6}>
                <Tooltip title={toolTipTitle || ''} arrow>
                    <div>
                        <DefaultButton onClick={onNoClick} className={rest.state ? 'btninActive' : 'btnActiveNo'} value={false} {...rest}>
                            {rest.btnName || 'No'}
                        </DefaultButton>
                    </div>
                </Tooltip>
            </Grid>
        </Grid>
    );
};
