import React from 'react';
import {
    Grid,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableContainer,
    Table,
    TableBody
} from '@material-ui/core';
import { useState } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import { FormControlLabel, Switch } from '@material-ui/core';

export const PopupVehicleDetails = (props) => {
    const { dataTablePopup, type } = props;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [popupNewCars, setPopupNewCars] = useState(true);
    const [popupNewMotab, setPopupNewMotab] = useState(true);

    const dataUntouched = dataTablePopup;

    const [list, setList] = useState(dataTablePopup)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getComparator(order, orderBy) {
        return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    }

    let headCellsPoupVehicle = [
        {
            label: 'Branch',
            id: 'branchName',
            numeric: false,
            disablePadding: false,
        },
        {
            label: 'cXs',
            id: 'salesPerson',
            numeric: false,
            disablePadding: false,
        },
        {
            label: 'Stock No.',
            id: 'stockbookNumber',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Invoice Date',
            id: 'invoiceDate',
            numeric: false,
            disablePadding: false,
        },
        {
            label: 'In Stock Date',
            id: 'inStockDate',
            numeric: false,
            disablePadding: false,
        },
        {
            label: 'Customer',
            id: 'customer',
            numeric: false,
            disablePadding: false,
        },
        {
            label: 'Vehicle',
            id: 'vehicle',
            numeric: false,
            disablePadding: false,
        },
        {
            label: 'Reg No',
            id: 'regNo',
            numeric: false,
            disablePadding: false,
        },
        {
            label: 'Finance Company',
            id: 'financeCompany',
            numeric: false,
            disablePadding: false,
        },
        {
            label: 'Sale Type',
            id: 'sOrderType',
            numeric: false,
            disablePadding: false,
        },
        {
            label: 'Prep/PDI',
            id: 'prepCost',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Gross Profit',
            id: 'grossProfit',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Fuel Sale',
            id: 'fuelSale',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Fuel Cost',
            id: 'fuelCost',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Admin Fee Sale',
            id: 'adminFeeSale',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Admin Fee Cost',
            id: 'adminFeeCost',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Accessory Sale',
            id: 'aCCSale',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Accessory Cost',
            id: 'aCCCost',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Warr Sale',
            id: 'warSale',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Warr Cost',
            id: 'warCost',
            numeric: true,
            disablePadding: false,
        },
        {
            label: 'Finance Comm',
            id: 'finance',
            numeric: true,
            disablePadding: false,
        }
    ]

    const handlePopupDataFilter = (type) => {
        if (type === "SNNew") {
            setPopupNewCars(!popupNewCars);

            if (!popupNewCars) {
                setList(dataUntouched);
            }
            else {
                setList(list.filter(item => item.sOrderType !== 'R'))
            }
        }

        if (type === "SNMotab") {
            setPopupNewMotab(!popupNewMotab);

            if (!popupNewMotab) {
                setList(dataUntouched);
            }
            else {
                setList(list.filter(item => item.sOrderType !== 'M'))
            }
        }
    }

    return (
        <>
            <div className='popupFilters'>
                {
                    type === "snVehicle" ? <>
                        <FormControlLabel
                            control={<Switch checked={popupNewCars} onChange={() => handlePopupDataFilter("SNNew")} name="popupNew" id="popupNew" />}
                            label="Retail"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            control={<Switch checked={popupNewMotab} onChange={() => handlePopupDataFilter("SNMotab")} name="popupNewMotab" id="popupNewMotab" />}
                            label="Motability"
                            labelPlacement="end"
                        />
                    </> : <></>
                }
            </div>
            <TableContainer style={{ display: "block", overflow: "auto", whiteSpace: "nowrap", width: '100%', maxHeight: '500px', height: '100%' }}>
                <Table className={""} stickyHeader>
                    <TableHead>
                        <TableRow>
                            {headCellsPoupVehicle.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...stableSort([...list], getComparator(order, orderBy))].map((row) => (
                            <TableRow key={row.stockbookNumber} hover={true}>
                                <TableCell>
                                    {row.branchName}
                                </TableCell>
                                <TableCell>
                                    {row.salesPerson}
                                </TableCell>
                                <TableCell>
                                    {row.stockbookNumber}
                                </TableCell>
                                <TableCell>
                                    {row.invoiceDate}
                                </TableCell>
                                <TableCell>
                                    {row.inStockDate}
                                </TableCell>
                                <TableCell>
                                    {row.customer}
                                </TableCell>
                                <TableCell>
                                    {row.vehicle}
                                </TableCell>
                                <TableCell>
                                    {row.regNo}
                                </TableCell>
                                <TableCell>
                                    {row.financeCompany}
                                </TableCell>
                                <TableCell>
                                    {row.sOrderType}
                                </TableCell>
                                <TableCell>
                                    {row.prepCost}
                                </TableCell>
                                <TableCell>
                                    {row.grossProfit}
                                </TableCell>
                                <TableCell>
                                    {formatters.CurrencyThousandSeparator(row.fuelSale)}
                                </TableCell>
                                <TableCell>
                                    {formatters.CurrencyThousandSeparator(row.fuelCost)}
                                </TableCell>
                                <TableCell>
                                    {formatters.CurrencyThousandSeparator(row.adminFeeSale)}
                                </TableCell>
                                <TableCell>
                                    {formatters.CurrencyThousandSeparator(row.adminFeeCost)}
                                </TableCell>
                                <TableCell>
                                    {row.aCCSale}
                                </TableCell>
                                <TableCell>
                                    {row.aCCCost}
                                </TableCell>
                                <TableCell>
                                    {row.warSale}
                                </TableCell>
                                <TableCell>
                                    {row.warCost}
                                </TableCell>
                                <TableCell>
                                    {row.finance}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    )
}