import { unAuthMenu, adminMenu, internalUserMenu } from './menu';
import { AppStorage } from '../Service/storage-service';
import { TypeOfAuth } from './menu/menu-auth-type';

export const getMenuForRole = (roles, isLoggedIn) => {
    let menus = [];
    if (!isLoggedIn) {
        menus.push(...unAuthMenu);
    } else {
        const isBranchOverviewAllowed = AppStorage.getReportingCanAccessBranchOverview();
        const isSalesProfitAndLossAllowed = AppStorage.getReportingCanAccessSalesProfitAndLoss();
        const isAfterSalesProfitAndLossAllowed = AppStorage.getReportingCanAccessAfterSalesProfitAndLoss();
        const isProfitAndLossDetailAllowed = AppStorage.getReportingCanAccessProfitAndLossDetail();
        const isUsedStockValuationAllowed = AppStorage.getReportingCanAccessUsedStockValuation();
        const isUsedVehicleStockAllowed = AppStorage.getReportingCanAccessUsedVehicleStock();
        const isAgedVehicleStockAllowed = AppStorage.getReportingCanAccessAgedVehicleStock();
        const isOveragePriorityListAllowed = AppStorage.getReportingCanAccessOveragePriorityList();
        const isSalesReportingAllowed = AppStorage.getReportingCanAccessSalesReporting();
        const isCanAccessUncappedVehicle = AppStorage.getReportingCanAccessUncappedVehicled();
        const canAccessDemoStock = AppStorage.getReportingCanAccessDemoStock();
        const canAccessSitePerformance = AppStorage.getReportingCanAccessSitePerformance();
        const canAccessUsedStockReport = AppStorage.getReportingCanAccessUsedStockReport();
        const canEditUsedStockReport = AppStorage.getReportingCanEditUsedStockReport();

        const internalMenu = (menus) => {
            menus.push(...internalUserMenu);
            if (!isBranchOverviewAllowed) {
                let branchOverview = menus.findIndex((m) => m.label === 'Branch Overview');
                menus.splice(branchOverview, 1);
            }

            if (!isSalesProfitAndLossAllowed) {
                let checkSalesProfitandLoss = menus.findIndex((m) => m.label === 'Sales Profit and Loss');
                menus.splice(checkSalesProfitandLoss, 1);
            }

            if (!isAfterSalesProfitAndLossAllowed) {
                let isAfterSalesProfitAndLoss = menus.findIndex((m) => m.label === 'Aftersales Profit and Loss');
                menus.splice(isAfterSalesProfitAndLoss, 1);
            }
            if (!isProfitAndLossDetailAllowed) {
                let isProfitAndLossDetail = menus.findIndex((m) => m.label === 'Profit and Loss Detail');
                menus.splice(isProfitAndLossDetail, 1);
            }
            if (!isUsedStockValuationAllowed) {
                let checkUsedStockValuation = menus.findIndex((m) => m.label === 'Used Stock Valuation');
                menus.splice(checkUsedStockValuation, 1);
            }
            if (!isUsedVehicleStockAllowed) {
                let checkAgedStockValuation = menus.findIndex((m) => m.label === 'Used Vehicle Stock');
                menus.splice(checkAgedStockValuation, 1);
            }
            if (!isAgedVehicleStockAllowed) {
                let checkAgedStockValuation = menus.findIndex((m) => m.label === 'Aged Vehicle Stock');
                menus.splice(checkAgedStockValuation, 1);
            }
            if (!isOveragePriorityListAllowed) {
                let checkAgedStockValuation = menus.findIndex((m) => m.label === 'Overage Priority List');
                menus.splice(checkAgedStockValuation, 1);
            }
            if (!isSalesReportingAllowed) {
                let checkAgedStockValuation = menus.findIndex((m) => m.label === 'Sales Reporting');
                menus.splice(checkAgedStockValuation, 1);
            }
            if (!isCanAccessUncappedVehicle) {
                let CheckcanAccessUncappedVehicled = menus.findIndex((m) => m.label === 'Uncapped Vehicles');
                menus.splice(CheckcanAccessUncappedVehicled, 1);
            }
            if (!canAccessDemoStock) {
                let CheckcanDemoStock = menus.findIndex((m) => m.label === 'Demo Stock');
                menus.splice(CheckcanDemoStock, 1);
            }
            if (!canAccessSitePerformance) {
                let CheckcanAccessSitePerformance = menus.findIndex((m) => m.label === 'Sales Performance');
                menus.splice(CheckcanAccessSitePerformance, 1);
            }
            if (!canAccessUsedStockReport) {
                let CheckcanAccessUsedStockReport = menus.findIndex((m) => m.label === 'Used Stock Report');
                menus.splice(CheckcanAccessUsedStockReport, 1);
            }
        }

        roles.forEach((role) => {
            if (role === 'admin') {
                if (localStorage.getItem('_adminUserDashboard_') === 'admin') {
                    menus.push(...adminMenu);
                }
                else if (localStorage.getItem('_adminUserDashboard_') === 'user') {
                    internalMenu(menus);
                }
            }
            else if (role === 'internal') {
                internalMenu(menus);
            }
            else {
                console.log('invalid Role');
            }
            // switch (role) {
            //     case 'admin':
            //         menus.push(...adminMenu);
            //         break;

            //     case 'internal':
            //         menus.push(...internalUserMenu);
            //         if (!isBranchOverviewAllowed) {
            //             let branchOverview = menus.findIndex((m) => m.label === 'Branch Overview');
            //             menus.splice(branchOverview, 1);
            //         }

            //         if (!isSalesProfitAndLossAllowed) {
            //             let checkSalesProfitandLoss = menus.findIndex((m) => m.label === 'Sales Profit and Loss');
            //             menus.splice(checkSalesProfitandLoss, 1);
            //         }

            //         if (!isAfterSalesProfitAndLossAllowed) {
            //             let isAfterSalesProfitAndLoss = menus.findIndex((m) => m.label === 'Aftersales Profit and Loss');
            //             menus.splice(isAfterSalesProfitAndLoss, 1);
            //         }
            //         if (!isProfitAndLossDetailAllowed) {
            //             let isProfitAndLossDetail = menus.findIndex((m) => m.label === 'Profit and Loss Detail');
            //             menus.splice(isProfitAndLossDetail, 1);
            //         }
            //         if (!isUsedStockValuationAllowed) {
            //             let checkUsedStockValuation = menus.findIndex((m) => m.label === 'Used Stock Valuation');
            //             menus.splice(checkUsedStockValuation, 1);
            //         }
            //         if (!isUsedVehicleStockAllowed) {
            //             let checkAgedStockValuation = menus.findIndex((m) => m.label === 'Used Vehicle Stock');
            //             menus.splice(checkAgedStockValuation, 1);
            //         }
            //         if (!isAgedVehicleStockAllowed) {
            //             let checkAgedStockValuation = menus.findIndex((m) => m.label === 'Aged Vehicle Stock');
            //             menus.splice(checkAgedStockValuation, 1);
            //         }
            //         if (!isOveragePriorityListAllowed) {
            //             let checkAgedStockValuation = menus.findIndex((m) => m.label === 'Overage Priority List');
            //             menus.splice(checkAgedStockValuation, 1);
            //         }
            //         if (!isSalesReportingAllowed) {
            //             let checkAgedStockValuation = menus.findIndex((m) => m.label === 'Sales Reporting');
            //             menus.splice(checkAgedStockValuation, 1);
            //         }
            //         if (!isCanAccessUncappedVehicle) {
            //             let CheckcanAccessUncappedVehicled = menus.findIndex((m) => m.label === 'Uncapped Vehicles');
            //             menus.splice(CheckcanAccessUncappedVehicled, 1);
            //         }
            //         if (!canAccessDemoStock) {
            //             let CheckcanDemoStock = menus.findIndex((m) => m.label === 'Demo Stock');
            //             menus.splice(CheckcanDemoStock, 1);
            //         }

            //         break;
            //     default:
            //         console.log('invalid Role');
            //         break;
            // }
        });
    }

    return menus.filter((m) => m.authType === TypeOfAuth.Both || m.authType === (isLoggedIn ? TypeOfAuth.Auth : TypeOfAuth.NonAuth));
};
