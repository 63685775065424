import { FormLabel, Grid, Hidden } from '@material-ui/core';
import { useState } from 'react';
import { FilterBtn, LinkedButton } from '../../../Core/BudgetCore/budgetButton';
import { ProfitLossCards } from '../../../Core/BudgetCore/budgetCardsUi_v2';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { postSalesProfitLossDashBoardData } from '../../../Core/Service/Nicol_Common_services';
import BudgetFilter from '../budgetFilters';
import CircularIndeterminate from './../../../Core/BudgetCore/loader';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import _ from 'lodash';

const filterBtnList = [
    { label: 'COMBINED', id: 'COMBINED' },
    { label: 'NEW', id: 'New' },
    { label: 'USED', id: 'Used' }
];

const ProfitLoss = (props) => {
    const [WindowWidths] = useWindowSize();
    let history = useHistory();

    const [state, setState] = useState({
        monthOfDate: [],
        yearList: [],
        selectedFilterBtn: props.location.state || filterBtnList[0].id,
        tempDashBoradData: [],
        dashBoradData: [],
        isReload: '',
        showLoader: true
    });

    const getFilterData = async (value) => {
        setState((st) => ({ ...st, showLoader: true }));

        let data = {
            filterDate: value.filterDate || [],
            franchiseCode: value.franchiseCode || [],
            branchID: value.branchID || [],
            Type: value.type === 'COMBINED' ? '' : value.type
        };

        let res = await postSalesProfitLossDashBoardData(data);
        if (res.success) {
            let result = res.data.list.map((m) => {
                let totalCalProfit = Math.round((+m.totalProfit / +m.totalBudget) * 100);
                if (+m.totalBudget === 0) {
                    totalCalProfit = 100;
                }
                return { totalCalProfit, ...m };
            });
            let data = _.orderBy(result, ['totalCalProfit'], ['desc']); //order by profit %
            setState((st) => ({ ...st, tempDashBoradData: data, showLoader: false }));
        }
    };

    const handleTable = () => {
        history.push({ pathname: '/panLTable', state: state.selectedFilterBtn });
    };

    const filterBtnChange = (id) => {
        if (id._reactName === 'onChange') {
            setState((st) => ({
                ...st,
                selectedFilterBtn: id.target.value
            }));
        } else {
            setState((st) => ({
                ...st,
                selectedFilterBtn: id
            }));
        }
    };

    const onRedirectFun = (id) => {
        const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
        history.push({ pathname: '/panLTable', state: state.selectedFilterBtn });
        let array = [];
        if (id) {
            array.push(id);
        }
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));
        setState((st) => ({ ...st, branchId: id }));
    };
    const onRedirectToNewUnits = (id, type) => {
        let path = type === 'New' ? '/new_invoice' : '/used_invoice';
        history.push({ pathname: path, state: type });
        const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
        let array = [];
        if (id) {
            array.push(id);
        }
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: array }));
        setState((st) => ({ ...st, branchId: id }));
    };
    return (
        <div className="budgetScreen">
            <>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={6} sm={9} md={9} lg={6} xl={10}>
                        <div className="mainHeading alignLeft screenMainHeader">Sales Profit and Loss </div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={6} xl={2}>
                        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                            <Hidden only={['xl', 'lg', 'md']}>
                                <Grid item>
                                    <BudgetFilter getFilterData={getFilterData} type={state.selectedFilterBtn} />
                                </Grid>
                            </Hidden>
                            <Grid items>
                                <div>
                                    <LinkedButton onClick={handleTable}>
                                        <ViewComfyIcon /> {WindowWidths > 1024 ? <> &nbsp;&nbsp;SALES P&L TABLE</> : ''}
                                    </LinkedButton>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden only={['sm', 'xs']}>
                    <BudgetFilter getFilterData={getFilterData} type={state.selectedFilterBtn} />
                </Hidden>
                <Grid item xs={12} md={8} lg={4} xl={4} className="containerPadding">
                    <Grid container>
                        <Grid item xs={12}>
                            <FormLabel component="legend" className="budgetLebels" style={{ marginLeft: '2px' }}>
                                SELECT FILTER
                            </FormLabel>
                        </Grid>
                        <>
                            {WindowWidths >= 600 ? (
                                filterBtnList?.map((p, i) => {
                                    return (
                                        <Grid item xs={4} className="containerPadding" key={i}>
                                            <FilterBtn active={state.selectedFilterBtn == p.id} onClick={() => filterBtnChange(p.id)}>
                                                {p.label}
                                            </FilterBtn>
                                        </Grid>
                                    );
                                })
                            ) : (
                                <select
                                    name="selectedFilterBtn"
                                    value={state.selectedFilterBtn}
                                    onChange={filterBtnChange}
                                    style={{
                                        width: '100%',
                                        padding: 10,
                                        background: '#dcd4fc',
                                        color: '#522bd2',
                                        textAlign: 'center',
                                        fontFamily: `Montserrat`,
                                        fontWeight: 600,
                                        border: '0.0625rem solid #522bd2',
                                        marginTop: 10
                                    }}
                                >
                                    {filterBtnList.map((p, i) => {
                                        return (
                                            <option key={i + 1} value={p.id} style={{ background: 'white' }}>
                                                {p.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            )}
                        </>
                    </Grid>
                </Grid>

                {state.showLoader ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        <Grid container justifyContent="flex-start">
                            {state.tempDashBoradData?.map((q) => (
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} className="containerPadding">
                                    <ProfitLossCards
                                        totalCalProfit={q.totalCalProfit}
                                        branchName={WindowWidths >= 600 ? q.branchName : q.branchShortName}
                                        value={60}
                                        profit={q.totalProfit}
                                        budget={q.totalBudget}
                                        retailsUnits={q.retailsUnits}
                                        tradeUnits={q.tradeUnits}
                                        motabilityUnits={q.motabilityUnits}
                                        newUnits={q.newUnits}
                                        usedUnits={q.usedUnits}
                                        selectedFilterBtnId={state.selectedFilterBtn}
                                        branchID={q.branchID}
                                        onRedirectFun={(id) => onRedirectFun(id)}
                                        onRedirectToNewUnits={(id, type) => onRedirectToNewUnits(id, type)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            </>
        </div>
    );
};

export default ProfitLoss;
