import React from 'react';
import ReactDOM from 'react-dom';
import './App/index.css';
import App from './App/App.jsx';

const DevApp = () => {
    return (
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
};

const ProdApp = () => {
    return <App />;
};

ReactDOM.render(process.env.NODE_ENV === 'production' ? <ProdApp /> : <DevApp />, document.getElementById('root'));
