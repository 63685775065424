import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import formatters from '../../../Core/Grid/inputFormatter';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useState } from 'react';
import { useEffect } from 'react';
import CapClean from './CapClean';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { AppStorage } from '../../../Core/Service/storage-service';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = (props, screenWidth) => [
    {
        id: 'branch',
        width: '10%',
        label: 'BRANCH'
    },
    {
        id: 'stockNO',
        width: '5%',
        label: 'STOCK NO'
    },
    {
        id: 'regNo',
        width: '7%',
        label: 'REG NO'
    },
    {
        id: 'make',
        width: '7%',
        label: 'MAKE'
    },
    {
        id: 'model',
        width: '15%',
        minWidth: '150px',
        label: 'MODEL'
    },
    {
        id: 'variant',
        width: '7%',
        minWidth: '150px',
        label: 'SPECIFICATION'
    },
    ...(props.showName
        ? [
              {
                  id: 'supplier',
                  minWidth: '100px',
                  width: '20%',
                  label: 'PURCHASED FROM'
              }
          ]
        : []),
    {
        id: 'dor',
        width: '7%',
        label: 'DOR'
    },
    {
        id: 'dayInStock',
        width: '7%',
        label: 'DAYS \n IN STOCK'
    },
    {
        id: 'mileage',
        width: '3%',
        minWidth: '50px',
        label: 'MILEAGE'
    },
    {
        id: 'sellingPrice',
        width: '5%',
        minWidth: '65px',
        numeric: true,
        label: 'SELLING PRICE'
    },
    {
        id: 'standInValue',
        width: '7%',
        minWidth: '65px',
        numeric: true,
        label: 'STAND IN VALUE'
    },
    {
        id: 'vat',
        width: '7%',
        numeric: true,
        label: 'VAT'
    },
    {
        id: 'total',
        width: '7%',
        numeric: true,
        label: 'Total'
    },
    {
        id: 'capClean',
        width: '10%',
        numeric: true,
        label: 'CAP CLEAN'
    },
    {
        id: 'variance',
        width: '7%',
        numeric: true,
        label: 'VARIANCE'
    },
    {
        id: 'r',
        width: '2%',
        numeric: false,
        label: `    R `,
        align: 'center'
    }
];

function EnhancedTableHead(props) {
    const [WindowWidths] = useWindowSize();
    const { classes, order, orderBy, onRequestSort, screenWidth = WindowWidths } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    let headerColumn = headCells(props, screenWidth);
    return (
        <TableHead>
            <TableRow>
                {headerColumn.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align ? headCell.align : headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            width={headCell.width ? headCell.width : 'auto'}
                            style={{
                                fontSize: 12,
                                fontWeight: 600,
                                padding: '2px 0px',
                                minWidth: headCell.minWidth ? headCell.minWidth : 'auto'
                            }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        '& .MuiTableCell-root': {
            padding: '5px 2px 5px 2px !important',
            border: '1px solid rgba(0,0,0,0.03)'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    textError: {
        color: 'white'
    },
    textSucc: {
        color: 'black'
    }
}));

export default function UsedStocksTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [state, setState] = React.useState({ stockID: null, show: false });
    const [stateGrid, setStateGrid] = useState({ list: props.list });

    useEffect(() => {
        setStateGrid((rw) => ({ ...rw, list: props.list }));
    }, [props]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const OnCapClick = (val) => {
        setState((st) => ({
            ...st,
            show: true,
            stockID: val.stockID,
            isEditable: AppStorage.getReportingCanAccessUncappedVehicled() && (!!val?.manuallyUpdatedBy || val?.capClean == 0) ? true : false
        }));
    };
    const OnCloseShow = (val) => {
        setState((st) => ({ ...st, show: false }));
        val && props.refreshNgetData();
    };
    return (
        <div className={classes.root}>
            {state.show ? <CapClean stockID={state.stockID} onClose={OnCloseShow} isEditable={state.isEditable} /> : null}
            <TableContainer style={{ maxHeight: 600, width: '100%' }}>
                <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={stateGrid.list?.length}
                        className="type3_Text"
                        showName={props.showName}
                    />
                    <TableBody>
                        {[...stableSort([...stateGrid.list], getComparator(order, orderBy))].map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                    style={{
                                        backgroundColor: row?.manuallyUpdatedBy ? '#FFBF00' : row?.capClean != 0 ? '' : 'red',
                                        color: row.capClean != 0 ? 'black' : 'white'
                                    }}
                                >
                                    <TableCell className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`} component="th" id={labelId} scope="row">
                                        {row.branch}
                                    </TableCell>

                                    <TableCell className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`} component="th" id={labelId} scope="row">
                                        {row.stockNO}
                                    </TableCell>
                                    <TableCell align="left" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                        {row.regNo}
                                    </TableCell>
                                    <TableCell align="left" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                        {row.make}
                                    </TableCell>
                                    <TableCell align="left" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                        {row.model}
                                    </TableCell>
                                    <TableCell align="left" className="type3_Text">
                                        {row.variant}
                                    </TableCell>
                                    {props.showName && (
                                        <TableCell align="left" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                            {row.supplier}
                                        </TableCell>
                                    )}
                                    <TableCell align="left" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                        {formatters.YearFormatter(row.dor)}
                                    </TableCell>
                                    <TableCell align="left" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                        {row.dayInStock}
                                    </TableCell>
                                    <TableCell align="left" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                        {row.mileage}
                                    </TableCell>
                                    <TableCell align="right" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(row.sellingPrice)}
                                    </TableCell>
                                    <TableCell align="right" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(row.standInValue)}
                                    </TableCell>
                                    <TableCell align="right" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(row.vat)}
                                    </TableCell>
                                    <TableCell align="right" className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(row.standInValue + row.vat)}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => OnCapClick(row)}
                                    >
                                        {formatters.CurrencyThousandSeparatorWithoutZero(row.capClean)}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}
                                        style={{ color: row?.capClean == 0 ? 'white' : row.variance < 0 ? '#E51A54' : '#539101' }}
                                    >
                                        {formatters.CurrencyThousandSeparatorWithoutZero(row.variance)}
                                        {row.variance < 0 ? <ArrowDownwardIcon style={{ fontSize: 15 }} /> : <ArrowUpwardIcon style={{ fontSize: 15 }} />}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={`${row?.capClean != 0 ? '' : classes.textError} type3_Text`}
                                        style={{ color: true ? '#539101' : '#E51A54' }}
                                    >
                                        {row.r}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
