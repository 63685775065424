import { useState, useEffect } from 'react';
import { Grid, FormLabel } from '@material-ui/core';
import Multiselect from '../../../Core/FormInput/MultiSelect';
import { getReportsDropdowns } from '../../../Core/Service/reportService';
import { getVehicleProfileListDropDown, getVehicleDetailsDropdown } from '../../../Core/Service/Nicol_Common_services';

const StockReportFilters = (props) => {
    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
    let VehicleFilter = JSON.parse(localStorage.getItem('NicoleFilterData'))?.VehicleProfileIds || [];
    const [state, setState] = useState({
        branchList: [],
        groupIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.groupIds || [JSON.parse(localStorage.getItem('_defaultBranchID'))] || [],
        VehicleProfileIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.VehicleIdState ? VehicleFilter : ['R', 'A'] || [],
        VehicleIdState: JSON.parse(localStorage.getItem('NicoleFilterData'))?.VehicleProfileIds || false,
        daysInStockIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.daysInStockIds || [1],
        fuelTypeIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.fuelTypeIds || [1],
        franNonFranIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.franNonFranIds || [1],
        modelCodesIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.modelCodesIds || [0],
    });

    const [temp, tempOption] = useState({
        groupVal: [],
        VehicleProfileVal: [],
        daysInStockVal: [],
        fuelTypeVal: [],
        franNonFranVal: [],
        modelCodesVal: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                let DropDownRes = await getReportsDropdowns(props.isPermissionBased);
                //let newRes = await getVehicleProfileListDropDown();
                let vecDrodpwon = await getVehicleDetailsDropdown();

                let franNonFranList = [{"id": 1, "name": "All"}, {"id": 2, "name": "Franchise"}, {"id": 3, "name": "Non Franchise"}]

                if (DropDownRes.success) {
                    setState((st) => ({
                        ...st,
                        branchList: DropDownRes.data.branch,
                        //vehicleList: newRes?.data.list,
                        vehicleList: vecDrodpwon?.data.progressCode,
                        daysInStockList: vecDrodpwon?.data.daysInStock,
                        fuelTypeList: vecDrodpwon?.data.fuelType,
                        modelCodesList: vecDrodpwon?.data.modelCodes,
                        franNonFranList: franNonFranList
                    }));
                } else {
                    // Handle the case where the request for DropDownRes was not successful
                    console.error('Request for DropDownRes was not successful:');
                }
            } catch (error) {
                // Handle any errors that might occur during the asynchronous operation
                console.error('An error occurred:', error);
            }
        };

        fetchData();
    }, [props.isPermissionBased]);

    useEffect(() => {
        let data = {
            branchID: state.groupIds,
            vehicleProfileIds: state.VehicleProfileIds,
            modelCodes: state.modelCodesIds,
            daysInStock: state.daysInStockIds,
            fuelType: state.fuelTypeIds,
            franNonFran: state.franNonFranIds
        };

        props.getFilterData(data);
    }, []);

    const HandleDoneSubmit = (val) => {
        let data = {
            branchID: state.groupIds,
            vehicleProfileIds: state.VehicleProfileIds,
            modelCodes: state.modelCodesIds,
            daysInStock: state.daysInStockIds,
            fuelType: state.fuelTypeIds,
            franNonFran: state.franNonFranIds
        };

        props.getFilterData(data);
    };

    useEffect(() => {
        if (state.branchList.length > 0) {
            tempOption((st) => ({
                ...st,
                groupVal: state.groupIds.map((w) => {
                    let list = state.branchList.find((m) => w === m.id);
                    return list;
                })
            }));

            if (state.VehicleProfileIds.length > 0) {
                tempOption((st) => ({
                    ...st,
                    VehicleProfileVal: state.VehicleProfileIds.map((w) => {
                        let list = state?.vehicleList?.find((m) => w === m.id);
                        return list;
                    })
                }));
            }

            if (state.modelCodesIds.length > 0) {
                tempOption((st) => ({
                    ...st,
                    modelCodesVal: state.modelCodesIds.map((w) => {
                        let list = state?.modelCodesList?.find((m) => w === m.id);
                        return list;
                    })
                }));
            }

            if (state.daysInStockIds.length > 0) {
                tempOption((st) => ({
                    ...st,
                    daysInStockVal: state.daysInStockIds.map((w) => {
                        let list = state?.daysInStockList?.find((m) => w === m.id);
                        return list;
                    })
                }));
            }

            if (state.fuelTypeIds.length > 0) {
                tempOption((st) => ({
                    ...st,
                    fuelTypeVal: state.fuelTypeIds.map((w) => {
                        let list = state?.fuelTypeList?.find((m) => w === m.id);
                        return list;
                    })
                }));
            }

            if (state.franNonFranIds.length > 0) {
                tempOption((st) => ({
                    ...st,
                    franNonFranVal: state.franNonFranIds.map((w) => {
                        let list = state?.franNonFranList?.find((m) => w === m.id);
                        return list;
                    })
                }));
            }
        }
    }, [state.branchList, state.groupIds]);

    useEffect(() => {
        if (state.branchList.length > 0 && props.getSelectedBranchList) {
            let tempBranchList =
                state.branchList.length > 0
                    ? state.groupIds.map((p) => {
                        let obj = state.branchList.find((q) => q.id == p);
                        return obj;
                    })
                    : [];
            props.getSelectedBranchList(tempBranchList);
        }
    }, [state.branchList, state.groupIds]);

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: value.map((c) => c.id) }));
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleChangesfranch = (event, value) => {
        tempOption((st) => ({ ...st, VehicleProfileVal: value }));

        setState((st) => {
            const nst = { ...st, VehicleProfileIds: value.map((c) => c.id) };
            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, VehicleProfileIds: value.map((c) => c.id), VehicleIdState: true }));
            return nst;
        });
    };

    const handleChangesModelCodes = (event, value) => {
        tempOption((st) => ({ ...st, modelCodesVal: value }));

        setState((st) => {
            const nst = { ...st, modelCodesIds: value.map((c) => c.id) };
            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, modelCodesIds: value.map((c) => c.id) }));
            return nst;
        });
    };

    const handleChangesdaysInStock = (event, value) => {
        tempOption((st) => ({ ...st, daysInStockVal: value }));

        setState((st) => {
            const nst = { ...st, daysInStockIds: value.map((c) => c.id) };
            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, daysInStockIds: value.map((c) => c.id) }));
            return nst;
        });
    };

    const handleChangesfuelType = (event, value) => {
        tempOption((st) => ({ ...st, fuelTypeVal: value }));

        setState((st) => {
            const nst = { ...st, fuelTypeIds: value.map((c) => c.id) };
            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, fuelTypeIds: value.map((c) => c.id) }));
            return nst;
        });
    };

    const handleChangesfranNonFran = (event, value) => {
        tempOption((st) => ({ ...st, franNonFranVal: value }));

        setState((st) => {
            const nst = { ...st, franNonFranIds: value.map((c) => c.id) };
            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, franNonFranIds: value.map((c) => c.id) }));
            return nst;
        });
    };

    let randerSelectAll = (type, name) => {
        if (type === 'SelectAll') {
            tempOption((st) => {
                let newSt = {
                    ...st
                };
                if (name === 'branch') {
                    newSt.groupVal = [...state.branchList];
                    localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: state.branchList.map((c) => c.id) }));
                    setState((st) => {
                        const nst = { ...st, groupIds: state.branchList.map((c) => c.id) };
                        return nst;
                    });
                }
                if (name === 'vehicle') {
                    newSt.VehicleProfileVal = [...state.vehicleList];
                    setState((st) => {
                        const nst = { ...st, VehicleProfileIds: state.vehicleList.map((c) => c.id) };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, VehicleProfileIds: state.vehicleList.map((c) => c.id) }));
                        return nst;
                    });
                }
                if (name === 'daysInStock') {
                    newSt.daysInStockVal = [...state.daysInStockList];
                    setState((st) => {
                        const nst = { ...st, daysInStockIds: state.daysInStockList.map((c) => c.id) };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, daysInStockIds: state.daysInStockList.map((c) => c.id) }));
                        return nst;
                    });
                }
                if (name === 'fuelType') {
                    newSt.fuelTypeVal = [...state.fuelTypeList];
                    setState((st) => {
                        const nst = { ...st, fuelTypeIds: state.fuelTypeList.map((c) => c.id) };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, fuelTypeIds: state.fuelTypeList.map((c) => c.id) }));
                        return nst;
                    });
                }
                if (name === 'franNonFran') {
                    newSt.franNonFranVal = [...state.franNonFranList];
                    setState((st) => {
                        const nst = { ...st, franNonFranIds: state.franNonFranList.map((c) => c.id) };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, franNonFranIds: state.franNonFranList.map((c) => c.id) }));
                        return nst;
                    });
                }
                if (name === 'modelCodes') {
                    newSt.modelCodesVal = [...state.modelCodesList];
                    setState((st) => {
                        const nst = { ...st, modelCodesIds: state.modelCodesList.map((c) => c.id) };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, modelCodesIds: state.modelCodesList.map((c) => c.id) }));
                        return nst;
                    });
                }
                return newSt;
            });
        }
        if (type === 'clear') {
            tempOption((st) => {
                let newSt = {
                    ...st
                };
                if (name === 'branch') {
                    newSt.groupVal = [];
                    localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: [] }));
                    setState((st) => {
                        const nst = { ...st, groupIds: [] };
                        return nst;
                    });
                }
                if (name === 'vehicle') {
                    newSt.VehicleProfileVal = [];
                    setState((st) => {
                        const nst = { ...st, VehicleProfileIds: [] };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, VehicleProfileIds: [] }));
                        return nst;
                    });
                }
                if (name === 'daysInStock') {
                    newSt.daysInStockVal = [];
                    setState((st) => {
                        const nst = { ...st, daysInStockIds: [] };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, daysInStockIds: [] }));
                        return nst;
                    });
                }
                if (name === 'fuelType') {
                    newSt.fuelTypeVal = [];
                    setState((st) => {
                        const nst = { ...st, fuelTypeIds: [] };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, fuelTypeIds: [] }));
                        return nst;
                    });
                }
                if (name === 'franNonFran') {
                    newSt.franNonFranVal = [];
                    setState((st) => {
                        const nst = { ...st, franNonFranIds: [] };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, franNonFranIds: [] }));
                        return nst;
                    });
                }
                if (name === 'modelCodes') {
                    newSt.modelCodesVal = [];
                    setState((st) => {
                        const nst = { ...st, modelCodesIds: [] };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, modelCodesIds: [] }));
                        return nst;
                    });
                }
                return newSt;
            });
        }
    };

    const handleRemoveBranchFromList = (branch) => {
        let newList = temp.groupVal.filter((c) => c.id !== branch);
        tempOption((st) => ({ ...st, groupVal: newList }));
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: newList.map((c) => c.id) }));
        setState((st) => {
            const nst = { ...st, groupIds: newList.map((c) => c.id) };
            return nst;
        });

        let data = {
            branchID: newList.map((c) => c.id),
            vehicleProfileIds: state.VehicleProfileIds,
            modelCodes: state.modelCodesIds,
            daysInStock: state.daysInStockIds,
            fuelType: state.fuelTypeIds,
            franNonFran: state.franNonFranIds
        };

        props.getFilterData(data);
    }

    return (
        <>
            <Grid container alignItems='center' className='filterTopPageWrp customFilterSpacing'>
                <Grid item className='filterTopPage'>
                    <FormLabel className='labelInput'>
                        Select Branch
                    </FormLabel>
                    <Multiselect
                        options={state.branchList || []}
                        onChange={handleChanges}
                        value={temp.groupVal}
                        selectAll={randerSelectAll}
                        name="branch"
                        isDoneFunction={HandleDoneSubmit}
                        disableClearable={true}
                    />
                </Grid>
                <Grid item className='filterTopPage'>
                    <FormLabel className='labelInput'>
                        Vehicle Status
                    </FormLabel>
                    <Multiselect
                        name="vehicle"
                        value={temp.VehicleProfileVal}
                        options={state.vehicleList || []}
                        onChange={handleChangesfranch}
                        selectAll={randerSelectAll}
                        disableClearable={true}
                        isDoneFunction={HandleDoneSubmit}
                        customFlag={true}
                    />
                </Grid>
                <Grid item className='filterTopPage'>
                    <FormLabel className='labelInput'>
                        Model Codes
                    </FormLabel>
                    <Multiselect
                        name="modelCodes"
                        value={temp.modelCodesVal}
                        options={state.modelCodesList || []}
                        onChange={handleChangesModelCodes}
                        selectAll={randerSelectAll}
                        disableClearable={true}
                        isDoneFunction={HandleDoneSubmit}
                    />
                </Grid>
                <Grid item className='filterTopPage'>
                    <FormLabel className='labelInput'>
                        Days in Stock
                    </FormLabel>
                    <Multiselect
                        name="daysInStock"
                        value={temp.daysInStockVal}
                        options={state.daysInStockList || []}
                        onChange={handleChangesdaysInStock}
                        selectAll={randerSelectAll}
                        disableClearable={true}
                        isDoneFunction={HandleDoneSubmit}
                    />
                </Grid>
                <Grid item className='filterTopPage'>
                    <FormLabel className='labelInput'>
                        Fuel Type
                    </FormLabel>
                    <Multiselect
                        name="fuelType"
                        value={temp.fuelTypeVal}
                        options={state.fuelTypeList || []}
                        onChange={handleChangesfuelType}
                        selectAll={randerSelectAll}
                        disableClearable={true}
                        isDoneFunction={HandleDoneSubmit}
                    />
                </Grid>
                <Grid item className='filterTopPage'>
                    <FormLabel className='labelInput'>
                        Fran/Non Fran
                    </FormLabel>
                    <Multiselect
                        name="franNonFran"
                        value={temp.franNonFranVal}
                        options={state.franNonFranList || []}
                        onChange={handleChangesfranNonFran}
                        selectAll={randerSelectAll}
                        disableClearable={true}
                        isDoneFunction={HandleDoneSubmit}
                    />
                </Grid>
            </Grid>
            {temp.groupVal?.length > 1 ? (
                <div className='selectedBranchList'>
                    {temp.groupVal.map((p) => {
                        return <>
                            <div className='selectedBranch'>
                                <span>{p.name}</span>
                                {temp.groupVal?.length > 1 ? (
                                    <span className='removeBranch' onClick={() => {handleRemoveBranchFromList(p.id)}}>x</span>
                                ) : ""}
                            </div>
                        </> ;
                    })}
                </div>
            ) : ""}
        </>
    );
}

export default StockReportFilters;