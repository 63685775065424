import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';

export default (props) => {
    return <TextField margin="dense" autoComplete="off" required fullWidth variant="outlined" {...props} />;
};
export const DatePicker = (props) => {
    const { value, onChange, isDisabled, inputLabel, isRequired, ...rest } = props;

    let [state, setState] = useState({ date: undefined });

    useEffect(() => {
        setState((st) => ({ ...st, date: props.value == 'Invalid date' ? '--' : props.value }));
    }, [props.value]);
    const handeDateChange = (e) => {
        const { value } = e.target;
        if (value < '2099-12-31') {
            setState((st) => ({ ...st, date: value }));
            props.onChange(e);
        }
    };
    return (
        <TextField
            id="date"
            size="small"
            variant="outlined"
            type="date"
            fullWidth
            inputProps={{
                max: '2099-12-31'
            }}
            onChange={handeDateChange}
            value={state.date}
            {...rest}
            InputLabelProps={{
                shrink: true
            }}
        />
    );
};
export const TextBoxShrink = (props) => {
    return <TextField margin="dense" autoComplete="off" required fullWidth variant="outlined" InputLabelProps={{ shrink: true }} {...props} />;
};
export const DateTimePicker = (props) => {
    const { value, onChange, ...rest } = props;

    let [state, setState] = useState({ date: null });

    useEffect(() => {
        setState((st) => ({ ...st, date: props.value }));
    }, [props.value]);

    const handeDateChange = (e) => {
        const { value } = e.target;
        if (value < '2099-12-31T00:00') {
            setState((st) => ({ ...st, date: value }));
            props.onChange(e);
        }
    };
    return (
        <TextField
            id="datetime-local"
            size="small"
            variant="outlined"
            type="datetime-local"
            fullWidth
            inputProps={{
                max: '2099-12-31T00:00'
            }}
            onChange={handeDateChange}
            value={state.date}
            {...rest}
            InputLabelProps={{
                shrink: true
            }}
        />
    );
};
