import * as Components from './../../../../Components';
import { TypeOfAuth } from './../menu-auth-type';
import {
    BranchOverView,
    ProfitLoss,
    ProfitLossDetails,
    UsedStockValuation,
    UsedVehicleStock,
    AgeVehicleSTock,
    OveragePriorityIcon,
    SaleIcon
} from '../../../../Components/internal/NicolImages/index.jsx';

export default [
    {
        label: 'NewReservedGrid',
        exact: true,
        hidden: true,
        path: '/new_Reserved',
        component: Components.ReservedList,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'UsedReservedGrid',
        exact: true,
        hidden: true,
        path: '/used_Reserved',
        component: Components.UsedReservedList,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'New Vehicle Profit Report',
        exact: true,
        path: '/new_invoice',
        component: Components.NewVehicleProfitReport,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Used Vehicle Profit Report',
        exact: true,
        path: '/used_invoice',
        component: Components.UsedVehicleProfitReport,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'labour',
        exact: true,
        path: '/labour',
        component: Components.LabourGrid,
        authType: TypeOfAuth.Auth,
        hidden: true
    },

    {
        label: 'Logout',
        path: '/logout',
        hidden: true,
        component: Components.logout,
        authType: TypeOfAuth.Auth
    },

    {
        label: 'Branch Overview',
        exact: true,
        path: '/',
        component: Components.BudgetScreen,
        authType: TypeOfAuth.Auth,
        icon: <BranchOverView />
        // icon: <BranchOverView />
    },
    {
        label: 'Sales Profit and Loss',
        exact: true,
        path: '/profitloss',
        component: Components.ProfitLoss,
        authType: TypeOfAuth.Auth,
        icon: <ProfitLoss />
    },
    {
        label: 'Aftersales Profit and Loss',
        exact: true,
        path: '/AfterSaleProfitLoss',
        component: Components.AfterSaleProfitLoss,
        authType: TypeOfAuth.Auth,
        icon: <ProfitLoss />
    },

    {
        label: 'Profit and Loss Detail',
        exact: true,
        path: '/profitLossDetails',
        component: Components.ProfitLossDetails,
        authType: TypeOfAuth.Auth,
        icon: <ProfitLossDetails />
    },
    {
        label: 'Used Stock Valuation',
        exact: true,
        path: '/UsedStockValuation',
        component: Components.UsedStockValuation,
        authType: TypeOfAuth.Auth,
        icon: <UsedStockValuation />
    },
    {
        label: 'Used Vehicle Stock',
        exact: true,
        path: '/usedStock',
        component: Components.UsedStock,
        authType: TypeOfAuth.Auth,
        icon: <UsedVehicleStock />
    },
    {
        label: 'Aged Vehicle Stock',
        exact: true,
        path: '/agedStocks',
        component: Components.AgedStocks,
        authType: TypeOfAuth.Auth,
        icon: <AgeVehicleSTock />
    },

    {
        label: 'Overage Priority List',
        exact: true,
        path: '/overagePriority',
        component: Components.OveragePriority,
        authType: TypeOfAuth.Auth,
        icon: <OveragePriorityIcon />
    },

    {
        label: 'Sales Reporting',
        exact: true,
        path: '/salesReporting',
        component: Components.SalesReporting,
        authType: TypeOfAuth.Auth,
        icon: <SaleIcon />
    },

    {
        label: 'Sales Report Details',
        hidden: true,
        exact: true,
        path: '/SalesReportDetails',
        component: Components.SalesReportDetails,
        authType: TypeOfAuth.Auth,
        icon: <SaleIcon />
    },

    {
        label: 'SalesPanLTable',
        hidden: true,
        exact: true,
        path: '/panLTable',
        component: Components.ProfitTable,
        authType: TypeOfAuth.Auth,
        icon: <SaleIcon />
    },
    {
        label: 'AfterSalesPanLTable',
        hidden: true,
        exact: true,
        path: '/aSalepanLTable',
        component: Components.AfterSandLTable,
        authType: TypeOfAuth.Auth,
        icon: <SaleIcon />
    },
    {
        label: 'Uncapped Vehicles',
        exact: true,
        path: '/Uncapped_Vehicled',
        component: Components.UnCappedVehicleTabComponent,
        authType: TypeOfAuth.Auth,
        icon: <SaleIcon />
    },
    {
        label: 'Demo Stock',
        exact: true,
        path: '/demoStock',
        component: Components.DemoStock,
        authType: TypeOfAuth.Auth,
        icon: <OveragePriorityIcon />
    },
    {
        label: 'Sales Performance',
        exact: true,
        path: '/salesPerformance',
        component: Components.SitePerformance,
        authType: TypeOfAuth.Auth,
        icon: <SaleIcon />
    },
    {
        label: 'Used Stock Report',
        exact: true,
        path: '/usedStockReport',
        component: Components.UsedStockReport,
        authType: TypeOfAuth.Auth,
        icon: <UsedStockValuation />
    }
];
