import React, { useEffect } from 'react';
import { Tabs, Tab, Box, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import DialogComp from '../Dialog/DialogComp';
import { SecondaryButton } from '../../FormInput';

const ShowWarningComponent = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <SecondaryButton className="setFullWidth" onClick={props.onSubmit}>
                    Yes
                </SecondaryButton>
            </Grid>
            <Grid item xs={6}>
                <SecondaryButton className="setFullWidth" onClick={() => props.onClose(false)}>
                    No
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    tab: {
        justifyContent: 'center',
        flexGrow: '0',
        '&.MuiTab-root': {
            fontSize: (props) => (props.tabLableSize ? `${props.tabLableSize} !important` : '0.8rem !important'),
            padding: '8px 13px !important',
            textTransform: 'none !important',
            fontWeight: (props) => (props.tabLableWeight ? `${props.tabLableWeight} !important` : '300 !important')
        }
    }
}));

const tabStyle = {
    boxShadow: '0px 1px rgba(0, 0, 0, 0.4)',
    tabWidth: { minWidth: 'auto' }
};

const getTabAndPanelId = (controlId, index) => {
    const id = controlId || 'dummy_tab';
    return {
        tabId: `${id}_${index}`,
        panelId: `${id}_tab_body_${index}`
    };
};

function TabPanel(props) {
    const { children, selectedTabIndex, index, controlId } = props;
    let { tabId, panelId } = getTabAndPanelId(controlId, index);
    return (
        <div role="tabpanel" hidden={selectedTabIndex !== index} id={panelId} aria-labelledby={tabId}>
            {selectedTabIndex === index && <Box p={0}>{children}</Box>}
        </div>
    );
}

export default function TabComponent(props) {
    const classes = useStyles(props);
    const [state, setState] = React.useState({
        selectedIndex: undefined,
        mySelectedIndex: undefined,
        tabList: []
    });
    useEffect(() => {
        setState((st) => ({ ...st, selectedIndex: props.selectedIndex }));
    }, [props.selectedIndex, props.timeDependency]);

    useEffect(() => {
        if (props.tabList.length > 0) {
            setState((st) => {
                const nst = { ...st };
                nst.tabList = [...props.tabList];
                if (nst.selectedIndex !== undefined) {
                    if (nst.selectedIndex >= nst.tabList.length) {
                        nst.selectedIndex = nst.tabList - 1;
                    }
                } else if (nst.tabList.length) {
                    nst.selectedIndex = 0;
                } else {
                    nst.selectedIndex = undefined;
                }
                return nst;
            });
        }
    }, [props.tabList, state.selectedIndex]);

    const handleChange = (event, newValue) => {
        if (props.showWarning) {
            setState((st) => ({ ...st, showWarningModal: true, mySelectedIndex: newValue }));
        } else setState((st) => ({ ...st, selectedIndex: newValue }));
    };

    const closeModal = () => {
        setState((st) => ({ ...st, showWarningModal: false }));
    };

    const discardModal = () => {
        setState((st) => ({ ...st, selectedIndex: state.mySelectedIndex, showWarningModal: false }));
    };

    return (
        <div id={props.id} className="tab-component">
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                value={state.selectedIndex}
                onChange={handleChange}
            >
                {state.tabList.map((m, i) => {
                    let { tabId, panelId } = getTabAndPanelId(props.id, i);
                    return <Tab className={classes.tab} key={i} label={m.label} id={tabId} aria-controls={panelId} style={tabStyle.tabWidth} />;
                })}
            </Tabs>
            {state.tabList.map((m, i) => {
                return (
                    <TabPanel key={i} selectedTabIndex={state.selectedIndex} index={i} controlId={props.id}>
                        {m.body}
                    </TabPanel>
                );
            })}

            {state.showWarningModal ? (
                <DialogComp title={'Are you sure you want to discard your changes?'} onClose={() => closeModal(false)} fullWidth maxWidth="sm">
                    <ShowWarningComponent onClose={closeModal} onSubmit={discardModal} />
                </DialogComp>
            ) : null}
        </div>
    );
}
