import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import formatters from '../Grid/inputFormatter';

export const PandLLineChart = (props) => {
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ background: 'rgba(0,0,0,0.8)', color: '#fafafa', padding: 15 }}>
                    <p className="label">Profit : {formatters.CurrencyThousandSeparatorWithoutZero(payload[0]?.payload?.totalprofit)}</p>
                    <p className="label">Units : {formatters.ThousandSeparatorWithoutZero(payload[0]?.payload?.unit)}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="kpiCard">
            <ResponsiveContainer width="100%" height={props.height}>
                <LineChart
                    data={props.data}
                    margin={{
                        top: 5,
                        right: props.WindowWidths >= 600 ? 10 : -20,
                        left: props.WindowWidths >= 600 ? 20 : -20,
                        bottom: props.WindowWidths >= 600 ? 5 : -10
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" style={{ fontSize: 10 }} label="" />
                    <YAxis
                        yAxisId="left"
                        style={{ fontSize: 10 }}
                        label={{ value: 'TOTAL PROFIT', angle: -90, position: 'insideLeft', stroke: '#8884d8', fontSize: 15 }}
                    />

                    {/* <YAxis yAxisId="left" style={{ fontSize: 10 }} label={{ value: 'TOTAL PROFIT', angle: -90, position: 'insideLeft', stroke: '#8884d8' }} /> */}
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        style={{ fontSize: 10 }}
                        label={{ value: 'UNITS', angle: 90, position: 'insideRight', stroke: '#82ca9d', fontSize: 15 }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Legend /> */}
                    <Line yAxisId="left" dataKey="totalprofit" stroke="#8884d8" strokeWidth={4} Label />
                    <Line yAxisId="right" dataKey="unit" stroke="#82ca9d" strokeWidth={4} Label />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};
