import React from 'react';
import {
    Grid,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableContainer,
    Table,
    TableBody
} from '@material-ui/core';
import { useState } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import { FormControlLabel, Switch } from '@material-ui/core';

export const SalesExecTable = (props) => {
    const { execData } = props;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const list = execData;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getComparator(order, orderBy) {
        return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const headCellsSalesExec = [
        {
            id: 'displayName',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'totalVolume',
            numeric: true,
            disablePadding: false,
            label: 'Total Vol',
        },
        {
            id: 'ppu',
            numeric: true,
            disablePadding: false,
            label: 'Average PPU',
        },
        {
            id: 'chassisProfit',
            numeric: true,
            disablePadding: false,
            label: 'Chassis Profit',
        },
        {
            id: 'chassisPPU',
            numeric: true,
            disablePadding: false,
            label: 'Chassis PPU',
        },
        {
            id: 'upsellPPU',
            numeric: true,
            disablePadding: false,
            label: 'Upsell PPU',
        },
        {
            id: 'finCasesPercentage',
            numeric: true,
            disablePadding: false,
            label: 'Finance Cases',
        },
        {
            id: 'gapPercentage',
            numeric: true,
            disablePadding: false,
            label: 'Service Plan',
        },
        {
            id: 'supagardPercentage',
            numeric: true,
            disablePadding: false,
            label: 'Supagard',
        },
        {
            id: 'cosmeticPercentage',
            numeric: true,
            disablePadding: false,
            label: 'Cosmetic',
        },
        {
            id: 'tyreNAlloyPercentage',
            numeric: true,
            disablePadding: false,
            label: 'T&A',
        },
        {
            id: 'warrantyPercentage',
            numeric: true,
            disablePadding: false,
            label: 'Warranty',
        },
        {
            id: 'accessories',
            numeric: true,
            disablePadding: false,
            label: 'Accessories',
        }
    ];

    return (
        <>
            <TableContainer style={{ display: "block", overflow: "auto", whiteSpace: "nowrap", width: '100%', maxHeight: '500px', height: '100%' }}>
                <Table className={""} stickyHeader>
                    <TableHead>
                        <TableRow>
                        {headCellsSalesExec.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                </TableSortLabel>
                            </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...stableSort([...list], getComparator(order, orderBy))].map((row) => (
                            <TableRow key={row.displayName}>
                                <TableCell>{row.displayName}
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { 
                                            "/"
                                        }}
                                    ></span>
                                </TableCell>
                                <TableCell>
                                    {row.totalVolume}
                                </TableCell>
                                <TableCell>
                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.ppu)}
                                </TableCell>
                                <TableCell>
                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.chassisProfit)}
                                </TableCell>
                                <TableCell>
                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.chassisPPU)}
                                </TableCell>
                                <TableCell>
                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.upsellPPU)}
                                </TableCell>
                                <TableCell>
                                    {row.finCases}
                                    <span className='tableItemGreen'>({row.finCasesPercentage}%)</span>
                                </TableCell>
                                <TableCell>
                                    {row.gap}
                                    <span className='tableItemGreen'>({row.gapPercentage}%)</span>
                                </TableCell>
                                <TableCell>
                                    {row.supagard}
                                    <span className='tableItemGreen'>({row.supagardPercentage}%)</span>
                                </TableCell>
                                <TableCell>
                                    {row.cosmetic}
                                    <span className='tableItemGreen'>({row.cosmeticPercentage}%)</span>
                                </TableCell>
                                <TableCell>
                                    {row.tyreNAlloy}
                                    <span className='tableItemGreen'>({row.tyreNAlloyPercentage}%)</span>
                                </TableCell>
                                <TableCell>
                                    {row.warranty}
                                    <span className='tableItemGreen'>({row.warrantyPercentage}%)</span>
                                </TableCell>
                                <TableCell>
                                    {formatters.CurrencyThousandSeparatorWithoutZero(row.accessories)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};