import React, { useEffect, useContext, useState, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton } from '../../../Core/FormInput/index';
import './UserManagement.css';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Adduser from './AddUser';
import { getAllUsers } from '../../../Core/Service/admin-service';
import SetPassByAdminModal from './SetPassByAdmin';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { TextBox } from '../../../Core/FormInput';
import { some } from 'lodash';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { CSVLink } from "react-csv";

let searchTimer;

const UserManagement = (props) => {
    const { loggedIn, showToast } = useContext(AppContext);
    const [WindowWidths] = useWindowSize();
    const [state, setState] = useState({
        show: false,
        showAddUserModal: false,
        rows_new: [],
        users: [],
        filterText: '',
        filteredRows: []
    });

    const pullUserAndUpdateState = async () => {
        let res = await getAllUsers();
        if (res.success) {
            setState((st) => ({ ...st, rows_new: [...res.data] }));
        }
        if (!res.success) {
            showToast(res.message);
        }
    };

    useEffect(() => {
        if (loggedIn) {
            pullUserAndUpdateState();
        } else {
            props.history.push('/login');
        }
    }, []);

    const handelUpdateUser = (props) => {
        const user = props && props.value.row;
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                users: user
            };
            return nst;
        });
    };
    const closeUpdateUser = (refreshGrid) => {
        setState((st) => ({ ...st, show: false }));
        refreshGrid && pullUserAndUpdateState();
    };
    const closeResetModal = (val) => {
        setState((st) => {
            const nst = {
                ...st,
                showResetModal: val
            };
            return nst;
        });
    };
    const handelResetPass = (props) => {
        const user = props.value.row;
        setState((st) => {
            const nst = {
                ...st,
                showResetModal: true,
                users: user
            };
            return nst;
        });
    };
    const handleAdduserModel = () => {
        handelUpdateUser();
    };
    const Arrow = (props) => {
        return (
            <>
                <Tooltip title="Update User" aria-label="add">
                    <IconButton size="small" onClick={() => handelUpdateUser(props)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Reset Password">
                    <IconButton size="small" onClick={() => handelResetPass(props)}>
                        <LockIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const baseColumns = [
        {
            field: 'id',
            headerName: 'ID',
            sortable: false,
            hide: true
        },
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 100,

            renderCell: (params) => <Arrow value={params} />
        },
        { field: 'displayName', headerName: 'Name', flex: 0.6 },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1
        }
    ];

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No user available</div>
            </GridOverlay>
        );
    }
    const setFilterText = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setState((st) => {
            const newSt = { ...st };
            const { rows_new, filterText } = newSt;
            let filterd = [];
            filterd = [...rows_new];
            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }
            newSt.filteredRows = filterd;
            return newSt;
        });
    };

    useEffect(() => {
        setFilteredRow();
    }, [state.rows_new]);

    const column_new = useMemo(() => {
        const tempCols = [...baseColumns];
        tempCols.splice(5, 0, {
            field: 'userRole',
            headerName: 'User Type',
            flex: 0.4
        });
        if (WindowWidths > 750) {
            tempCols.splice(6, 0, { field: 'isLocked', headerName: 'Is Locked?', flex: 0.5, valueGetter: (params) => (params.value ? 'Yes' : 'No') });
            tempCols.splice(7, 0, {
                field: 'isActive',
                headerName: 'Is Active?',
                flex: 0.5,
                valueGetter: (params) => (params.value ? 'Yes' : 'No')
            });
        }
        return tempCols;
    }, [WindowWidths]);

    const csvUserExport = [
        {
            label: 'UserName',
            key: 'displayName',
        },
        {
            label: 'UserEmail',
            key: 'email'
        },
        {
            label: 'LastLogin',
            key: 'lastLogin'
        }
    ]

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0, display: "flex", alignContent: "center" }}>
                <div className='exportCSVUsers'>
                    <CSVLink data={state.filteredRows} headers={csvUserExport} target="_blank" filename={"UsersExport.csv"}>
                        <ViewComfyIcon />
                        Export Users
                    </CSVLink>
                </div>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdduserModel} className="btnNewUser">
                    Add user
                </SecondaryButton>
            </div>
            {state.show ? <Adduser users={state.users} onFormSubmit={closeUpdateUser} /> : null}
            {state.showResetModal ? <SetPassByAdminModal users={state.users} onFormSubmit={closeResetModal} /> : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb" style={{ fontSize: '0.85rem' }}>
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Users
                </Typography>
            </Breadcrumbs>
            <div style={{ margin: '10px' }}>
                <Grid container className="roots" spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextBox autoFocus={true} required name="Search" label="Search" onChange={setFilterText} value={state.filterText} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div
                            style={{
                                height: 'calc(100vh - 230px)',
                                width: '99.8%'
                            }}
                        >
                            <DataGrid
                                components={{
                                    NoRowsOverlay: CustomNoRowsOverlay
                                }}
                                rows={state.filteredRows}
                                headerHeight={30}
                                rowHeight={30}
                                hideFooter={true}
                                columns={column_new}
                                pageSize={100}
                                disableColumnMenu={true}
                                showColumnRightBorder={true}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default UserManagement;
