import React, { useContext, useState } from "react";
import AppContext from "../../../App/AppContext";
import {
    PasswordBox,
    SecondaryButton,
    DialogContents,
    DialogTitles,
    Dialogs,
} from "../../../Core/FormInput";
import { ResetPassByAdmin } from "../../../Core/Service/admin-service";
import { IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";

const SetPassByAdminModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        new: "",
        confirm: "",
        enableChange: false,
    });
    const user = props.users;
    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            nst.enableChange =
                nst.new && nst.confirm && nst.new === nst.confirm;
            return nst;
        });
    };

    const handleResetPass = async (e) => {
        e.preventDefault();
        if (state.new && state.confirm) {
            let pattern = /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$)/gm;
            if (!pattern.test(state.new)) {
                showToast("Password should be complex with 8 char minimum.")
            } else {
                const res = await ResetPassByAdmin(user.id, state.new, state.confirm);
                if (!res.success) {
                    console.error(`When admin trying to change user password (${user.id})`, res);
                    showToast(res.messages);
                } else {
                    showToast(res.message);
                    props.onFormSubmit(false);
                    hideModal();
                }
            }
        }
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="sm">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitles>Reset Password</DialogTitles>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>

            <DialogContents>
                <PasswordBox
                    label="New Password"
                    name="new"
                    onChange={fieldChange}
                />
                <PasswordBox
                    label="Confirm Password"
                    name="confirm"
                    onChange={fieldChange}
                />
                <SecondaryButton
                    className="Submitbtn"
                    onClick={handleResetPass}
                    disabled={!state.enableChange}
                >
                    Set Password
                </SecondaryButton>
            </DialogContents>
        </Dialogs>
    );
};

export default SetPassByAdminModal;
