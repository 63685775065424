import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import UsedStocks from './../../ReportsScreens/AgedMeasures/UsedStock/index';
import formatters from '../../../Core/Grid/inputFormatter';
import { Grid, TablePagination } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { getUncappedVehicles } from '../../../Core/Service/Nicol_Common_services';
import CapClean from '../UsedVehicleStock/CapClean';
import { some } from 'lodash';
import { TextBox } from '../../../Core/FormInput';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = (props, screenWidth) => [
    {
        id: 'branch',
        width: '10%',
        label: 'BRANCH'
        // numeric: true
    },
    {
        id: 'stockbookNumber',
        width: '5%',
        label: 'STOCK NO'
        // numeric: true
    },
    {
        id: 'registrationNumber',
        width: '5%',
        label: 'REG NO'
    },
    {
        id: 'make',
        width: '7%',
        label: 'MAKE'
    },
    {
        id: 'model',
        width: '10%',
        label: 'MODEL'
    },
    {
        id: 'dor',
        width: '6%',
        label: 'DOR'
    },
    {
        id: 'dayInStock',
        width: screenWidth < 1200 ? '3%' : '7%',
        label: 'DAYS IN STOCK'
    },
    {
        id: 'mileage',
        width: '4%',
        label: 'MILEAGE'
    },
    {
        id: 'sellingPrice',
        width: screenWidth < 1200 ? '3%' : '5%',
        numeric: true,
        label: 'SELLING PRICE'
    },
    {
        id: 'standInValue',
        width: screenWidth < 1200 ? '2%' : '7%',
        numeric: true,
        label: 'STAND IN VALUE'
    },
    {
        id: 'vat',
        width: '2%',
        numeric: true,
        label: 'VAT'
    },
    {
        id: 'tatal',
        width: '2%',
        numeric: true,
        label: 'Total'
    },
    {
        id: 'capClean',
        width: '7%',
        numeric: true,
        label: 'CAP CLEAN'
    },

    {
        id: 'valuationDateMatch',
        width: '7%',
        label: 'Last Updated',
        numeric: true
    },
    {
        id: 'r',
        width: '3%',
        numeric: true,
        label: 'R'
    }
];

let searchTimer;

function EnhancedTableHead(props) {
    const [WindowWidths] = useWindowSize();
    const { classes, order, orderBy, onRequestSort, screenWidth = WindowWidths } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    let headerColumn = headCells(props, screenWidth);
    return (
        <TableHead>
            <TableRow>
                {headerColumn.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        width={headCell.width}
                        style={{ fontSize: 12, fontWeight: 600, padding: '0px 2px' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 1450,
        '& .MuiTableCell-root': {
            padding: '5px 7px'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

export default function UncappedVehicled(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [stateGrid, setStateGrid] = useState({ list: [], filterTableData: [], filterText: '', showLoader: true });
    const [state, setState] = React.useState({ stockID: null, show: false });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const pullgetUncappedVehicles = async () => {
        let res = await getUncappedVehicles(props.unCappedID);
        setStateGrid((rw) => ({ ...rw, list: res?.data?.list || [], showLoader: false }));
    };

    useEffect(async () => {
        pullgetUncappedVehicles();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const OnCapClick = (val) => {
        setState((st) => ({ ...st, show: true, stockID: val }));
    };
    const OnCloseShow = () => {
        setState((st) => ({ ...st, show: false }));
        pullgetUncappedVehicles();
    };

    const setFilterText = (e) => {
        const vl = e.target.value;
        setStateGrid((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setStateGrid((st) => {
            const newSt = { ...st };
            const { filterText, list } = newSt;
            let filterd = [];
            filterd = [...list];
            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }
            newSt.filterTableData = filterd;
            return newSt;
        });
    };

    useEffect(() => {
        setFilteredRow();
    }, [stateGrid.list]);
    let RenderList = rowsPerPage > 0 ? [...stateGrid.filterTableData].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stateGrid.filterTableData;

    return (
        <div>
            {stateGrid.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    {state.show ? <CapClean stockID={state.stockID} onClose={OnCloseShow} isEditable={true} /> : null}
                    <Grid item xs={12} sm={4}>
                        <TextBox
                            required
                            name="Search"
                            label="Search"
                            onChange={setFilterText}
                            value={stateGrid.filterText}
                            style={{ maxHeight: 30, marginBottom: 20 }}
                        />
                    </Grid>

                    <TableContainer style={{ maxHeight: 560, width: '100%' }}>
                        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={stateGrid.filterTableData?.length}
                                className="type3_Text"
                                showName={props.showName}
                            />
                            <TableBody>
                                {[...stableSort([...RenderList], getComparator(order, orderBy))].map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <TableCell className="type3_Text" component="th" id={labelId} scope="row">
                                                {row.branch}
                                            </TableCell>
                                            <TableCell className="type3_Text" component="th" id={labelId} scope="row">
                                                {row.stockbookNumber}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="type3_Text"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => OnCapClick(row.usedVehicleStockID)}
                                            >
                                                {row.registrationNumber}
                                            </TableCell>
                                            <TableCell align="left" className="type3_Text">
                                                {row.make}
                                            </TableCell>
                                            <TableCell align="left" className="type3_Text">
                                                {row.model}
                                            </TableCell>
                                            <TableCell align="left" className="type3_Text">
                                                {formatters.YearFormatter(row.dor)}
                                            </TableCell>
                                            <TableCell align="left" className="type3_Text">
                                                {row.dayInStock}
                                            </TableCell>
                                            <TableCell align="left" className="type3_Text">
                                                {formatters.ThousandSeparatorWithoutZero(row.originalMileage)}
                                            </TableCell>
                                            <TableCell align="right" className="type3_Text">
                                                {formatters.CurrencyThousandSeparatorWithoutZero(row.sellingPrice)}
                                            </TableCell>
                                            <TableCell align="right" className="type3_Text">
                                                {formatters.CurrencyThousandSeparatorWithoutZero(row.standInValue)}
                                            </TableCell>
                                            <TableCell align="right" className="type3_Text">
                                                {formatters.CurrencyThousandSeparatorWithoutZero(row.vat)}
                                                {/* {row.vq} */}
                                            </TableCell>
                                            <TableCell align="right" className="type3_Text">
                                                {formatters.CurrencyThousandSeparatorWithoutZero(row.standInValue + row.vat)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className="type3_Text"
                                                nastyle={{ color: row.capClean - row.standInValue < 0 ? '#E51A54' : '#539101' }}
                                            >
                                                {formatters.CurrencyThousandSeparatorWithoutZero(row.capClean)}
                                            </TableCell>
                                            <TableCell align="right" className="type3_Text">
                                                {formatters.YearFormatter(row.valuationDateMatch)}
                                            </TableCell>
                                            <TableCell align="right" className="type3_Text" style={{ color: true ? '#539101' : '#E51A54' }}>
                                                {row.r}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                        component="div"
                        count={stateGrid.filterTableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </div>
    );
}
