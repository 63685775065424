import React from 'react';
import { TextField, Checkbox, Chip, Badge, Tooltip, Paper, Button, Grid, FormControl, Select, MenuItem, ListItemText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import './Controls.scss';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useWindowSize } from '../Controls/ScreenResolution';

import { useEffect, useState, useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
        maxWidth: '100%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 350,
            padding: '0px',
            width: 250,
            zIndex: 9999999,
            position: 'relative'
        }
    }
};

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        fontWeight: 500,
        display: 'block',
        minWidth: '150px'
    }
}))(Tooltip);

const CustomPaper = (paper_props, name) => {
    let btnClicked = (type) => () => {
        document.dispatchEvent(new CustomEvent(`${name}:multiselect:button_clicked`, { detail: { type } }));
    };
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} onMouseDown={(e) => e.preventDefault()} style={{ background: 'white' }}>
                <Paper elevation={8}>
                    {paper_props.children}
                    <Grid item xs={12} style={{ backgroundColor: '#0e2132', textAlign: 'right', padding: '5px', marginTop: -20 }}>
                        <Button onClick={btnClicked('SelectAll')} style={{ backgroundColor: 'white', color: 'black', marginTop: 2, marginRight: 20 }}>
                            Select All
                        </Button>

                        <Button onClick={btnClicked('clear')} style={{ backgroundColor: 'white', color: 'black', marginTop: 2, marginRight: 20 }}>
                            clear
                        </Button>

                        <Button onClick={btnClicked('submit')} style={{ backgroundColor: 'white', color: 'black', marginTop: 2 }}>
                            Done
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

const Multiselect = (props) => {
    let { selectAll, isDoneFunction, ...rest } = props;

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        const handler = (ev) => {
            switch (ev.detail.type) {
                case 'SelectAll':
                    selectAll('SelectAll', props.name);
                    break;
                case 'clear':
                    selectAll('clear', props.name);
                    break;
                case 'submit':
                    isDoneFunction();
                    setOpen(false);
                    break;
                default:
                    // Handle the default case here
                    console.log('Invalid');
                    break;
            }
        };
        document.addEventListener(`${props.name}:multiselect:button_clicked`, handler);
        return () => {
            document.removeEventListener(`${props.name}:multiselect:button_clicked`, handler);
        };
    }, [selectAll, isDoneFunction]);

    const CustomPaperWrapper = useCallback((paper_props) => {
        return CustomPaper(paper_props, props.name);
    }, []);

    return (
        <Autocomplete
            {...rest}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            open={open}
            onOpen={handleOpen}
            onClose={() => setOpen(false)}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                </React.Fragment>
            )}
            ListboxProps={{ style: { maxHeight: 400, overflow: 'auto' } }}
            PaperComponent={props.hideButtons ? undefined : CustomPaperWrapper}
            getOptionSelected={(option, value) => option.name === value?.name}
            renderTags={(value, getTagProps) => {
                let tempList = [];
                let titleList = [];
                value?.forEach((p, i) => {
                    if (props.customFlag) {
                        if (value.length === 1) {
                            tempList.push(<Chip label={`${p?.name} `} key={i} />);
                        }
                        else if (value.length > 1) {
                            if (i === 0) {
                                tempList.push(<Chip label={`${p?.name}, `} key={i} />);
                            } else if (i === 1) {
                                tempList.push(<Chip label={`${p?.name} `} key={i} />);
                            }

                            titleList.push(
                                <div style={{ padding: 4 }} key={`title_${i}`}>
                                    {p?.name}
                                </div>
                            );
                        }
                    } else {
                        if (i < 1) {
                            tempList.push(<Chip label={`${p?.name} `} key={i} />);
                        } else {
                            titleList.push(
                                <div style={{ padding: 4 }} key={`title_${i}`}>
                                    {p?.name}
                                </div>
                            );
                        }
                    }
                });

                if (props.customFlag) {
                    if (value?.length > 2) {
                        tempList.push(
                            <LightTooltip title={titleList} key={`tooltip_${props.name}`}>
                                <Badge color="secondary" badgeContent={value.length} overlap="rectangular" className='custom-chip'>
                                    <Chip label={`more`} style={{ marginLeft: '3px' }} />
                                </Badge>
                            </LightTooltip>
                        );
                    }
                } else {
                    if (value?.length > 1) {
                        tempList.push(
                            <LightTooltip title={titleList} key={`tooltip_${props.name}`}>
                                <Badge color="secondary" badgeContent={value.length} overlap="rectangular" className='custom-chip'>
                                    <Chip label={`more`} style={{ marginLeft: '3px' }} />
                                </Badge>
                            </LightTooltip>
                        );
                    }
                }

                return <div key={`${props.name}_tags`}>{tempList}</div>;
            }}
            renderInput={(params) => (
                <>
                    <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder}></TextField>
                </>
            )}
        />
    );
};
export default Multiselect;

export const MultiselectWithoutCheckbox = (props) => {
    return (
        <Autocomplete
            {...props}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => <React.Fragment>{option.name}</React.Fragment>}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
};

export const SingelSelect = (props) => {
    return (
        <Autocomplete
            {...props}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => <React.Fragment>{option.name}</React.Fragment>}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
};

export const MultipleSelectCheckmarks = (props) => {
    const classes = useStyles();
    const [personName, setPersonName] = React.useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        props.getvalue(personName);
    }, [personName]);

    useEffect(() => {
        setPersonName(props.value);
    }, []);

    const selctAll = () => {
        setPersonName(props.options.map((n) => n.name));
    };
    const clearAll = () => {
        setPersonName([]);
    };

    const CustomPaper = (props) => {
        return (
            <Grid
                container
                spacing={0}
                justifyContent="flex-end"
                style={{ position: 'sticky', top: 0, backgroundColor: '#0e2132', zIndex: 9999, marginBottom: 4 }}
            >
                <Grid onClick={selctAll} item style={{ padding: '1px', height: 50 }}>
                    <Button style={{ backgroundColor: 'white', color: 'black', marginTop: 5, marginRight: 20 }}>select all</Button>
                </Grid>
                <Grid onClick={clearAll} item style={{ padding: '1px', height: 50 }}>
                    <Button style={{ backgroundColor: 'white', color: 'black', marginTop: 5, marginRight: 20 }}>Clear</Button>
                </Grid>
                <Grid onClick={() => handleClose(true)} item style={{ padding: '1px', height: 50 }}>
                    <Button style={{ backgroundColor: 'white', color: 'black', marginTop: 5, marginRight: 20 }}>Done</Button>
                </Grid>
            </Grid>
        );
    };

    const handleChange = (event) => {
        if (event.target.value !== undefined) {
            setPersonName(event.target.value);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = (isRefreshFlag) => {
        isRefreshFlag && props.isDoneFunction();
        setOpen(false);
    };

    return (
        <FormControl className={classes.formControl}>
            <Select
                size="small"
                open={open}
                onOpen={handleOpen}
                variant="outlined"
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={personName}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                PaperComponent={CustomPaper}
            >
                <CustomPaper />

                {props.options.map((value) => (
                    <MenuItem
                        key={value.name}
                        value={value.name}
                        style={{
                            height: 30
                        }}
                    >
                        <Checkbox checked={personName.indexOf(value.name) > -1} />
                        <ListItemText primary={value.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
