import { FormLabel, Grid, Hidden } from '@material-ui/core';
import { Multiselect, TextBox } from '../../../Core/FormInput';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { getReportsDropdowns } from '../../../Core/Service/reportService';
import { FilterBtn, LinkedButton } from '../../../Core/BudgetCore/budgetButton';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import DemoStockTable from './demoStockTable';
import { postDemoStockGetExcelFile, postDemoStockList } from '../../../Core/Service/Nicol_Common_services';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { some } from 'lodash';
import { useHistory } from 'react-router-dom';
import { MultipleSelectCheckmarks } from '../../../Core/FormInput/MultiSelect';

let searchTimer;

const buttonsList = [
    { id: 'tdays', name: '30 DAYS' },
    { id: 'sdays', name: '60 DAYS' },
    { id: 'ndays', name: '90 DAYS' },
    { id: 'htdays', name: '120 DAYS' }
];

const DemoStock = (props) => {
    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));

    const [state, setState] = useState({
        branches: [],
        tableRow: [],
        branchList: [],
        franchise: [],
        groupIds: JSON.parse(localStorage.getItem('_defaultBranchID')) ? [JSON.parse(localStorage.getItem('_defaultBranchID'))] : [],
        franchiseIds: [],
        showTable: true,
        type: buttonsList[0].id,
        tableData: [],
        showLoader: true,
        filterText: '',
        includeDemoVehicles: true
    });
    const history = useHistory();

    const [temp, tempOption] = useState({
        groupVal: []
    });

    const [WindowWidths] = useWindowSize();

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                let DropDownRes = await getReportsDropdowns(false);
                if (DropDownRes.success) {
                    setState((st) => ({
                        ...st,
                        branchList: DropDownRes.data.branch
                    }));
                } else {
                    // Handle the case where the request was not successful
                    console.error('Request was not successful:');
                }
            } catch (error) {
                // Handle any other errors that might occur during the asynchronous operation
                console.error('An error occurred:');
            }
        };

        fetchDropdownData();
    }, []);

    useEffect(() => {
        if (state.branchList.length > 0) {
            tempOption((st) => ({
                ...st,
                groupVal: state.groupIds.map((w) => {
                    let list = state.branchList.find((m) => w === m.id);
                    return list;
                })
            }));
        }
    }, [state.branchList]);

    const pullDemoStockList = async () => {
        let data = {
            branchID: state.groupIds,
            type: state.type,
            includeDemoVehicles: state.includeDemoVehicles
        };
        let res = await postDemoStockList(data);
        if (res.success) {
            setState((st) => ({
                ...st,
                tableData: res.data.tableData || [],
                showLoader: false,
                uncappedVehicles: res?.data?.uncappedVehicles[0]?.uncappedVehicles || []
            }));
        }
    };

    useEffect(async () => {
        pullDemoStockList();
    }, [state.type, state.isReload, state.includeDemoVehicles]);

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleClick = (val) => {
        if (val._reactName === 'onChange') {
            setState((st) => ({
                ...st,
                type: val.target.value
            }));
        } else {
            setState((st) => ({
                ...st,
                type: val
            }));
        }
    };

    const refreshNgetData = () => {
        setState((st) => {
            const nst = { ...st, isReload: new Date() };
            return nst;
        });
    };

    const downloadCSVWrapper = async () => {
        let data = {
            type: state.type,
            branchID: state.groupIds,
            includeDemoVehicles: state.includeDemoVehicles
        };
        await postDemoStockGetExcelFile(data, 'Demo_Stock_List');
    };

    const setFilterText = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setState((st) => {
            const newSt = { ...st };
            const { filterText, tableData } = newSt;
            let filterd = [];
            filterd = [...tableData];
            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }
            newSt.filterTableData = filterd;
            return newSt;
        });
    };
    useEffect(() => {
        setFilteredRow();
    }, [state.tableData]);

    const handelSwitchBtnClick = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            let newSt = { ...st };
            newSt[name] = checked;
            return newSt;
        });
    };

    let randerSelectAll = (type, name) => {
        if (type === 'SelectAll') {
            tempOption((st) => {
                let newSt = {
                    ...st
                };
                if (name === 'branch') {
                    newSt.groupVal = [...state.branchList];
                    localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: state.branchList.map((c) => c.id) }));
                    setState((st) => {
                        const nst = { ...st, groupIds: state.branchList.map((c) => c.id) };
                        return nst;
                    });
                }

                return newSt;
            });
        }
        if (type === 'clear') {
            tempOption((st) => {
                let newSt = {
                    ...st
                };
                if (name === 'branch') {
                    newSt.groupVal = [];
                    localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: [] }));
                    setState((st) => {
                        const nst = { ...st, groupIds: [] };
                        return nst;
                    });
                }

                return newSt;
            });
        }
    };

    const multiHandleChanges = (val) => {
        let idArray = [];
        val.forEach((n) => {
            let index = state.branchList.findIndex((k) => k.name === n);
            if (index >= 0) {
                idArray.push(state.branchList[index].id);
            }
        });
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: idArray }));
        setState((st) => {
            const nst = { ...st, groupIds: idArray };
            return nst;
        });
    };

    return (
        <div className="budgetScreen">
            {state.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={6} sm={8} md={9} lg={9} xl={9}>
                            <div className="mainHeading alignLeft screenMainHeader">Demo Stock</div>
                        </Grid>
                        <Grid item spacing={2} container xs={6} sm={4} md={3} lg={3} xl={3} justifyContent="flex-end">
                            <Grid item style={{ marginTop: WindowWidths > 1024 ? 0 : 10 }}>
                                <LinkedButton onClick={downloadCSVWrapper}>
                                    <ViewComfyIcon />
                                    {WindowWidths > 1024 ? <> &nbsp;&nbsp;DOWNLOAD CSV</> : ''}
                                </LinkedButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container style={{ textAlign: 'left' }} className="containerPadding" alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                SELECT BRANCH
                            </FormLabel>
                            <Hidden only={['sm', 'md', 'xl', 'lg']}>
                                <MultipleSelectCheckmarks
                                    options={state.branchList || []}
                                    getvalue={(val) => multiHandleChanges(val)}
                                    value={temp.groupVal.map((n) => n.name)}
                                    isDoneFunction={refreshNgetData}
                                />
                            </Hidden>
                            <Hidden only={['xs']}>
                                <Multiselect
                                    options={state.branchList || []}
                                    onChange={handleChanges}
                                    value={temp?.groupVal}
                                    isDoneFunction={refreshNgetData}
                                    selectAll={randerSelectAll}
                                    name="branch"
                                    disableClearable={true}
                                />
                            </Hidden>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-start" spacing={WindowWidths <= 600 ? 1 : 0}>
                        {WindowWidths >= 600 ? (
                            buttonsList.map((n, i) => {
                                return (
                                    <Grid key={i} item xs={6} sm={3} md={2} lg={2} className={WindowWidths <= 600 ? '' : 'containerPadding'}>
                                        <>
                                            <FilterBtn active={state.type === n.id} onClick={() => handleClick(n.id)}>
                                                {n.name?.toUpperCase()}
                                            </FilterBtn>
                                        </>
                                    </Grid>
                                );
                            })
                        ) : (
                            <Grid item xs={12}>
                                <select
                                    name="type"
                                    value={state.type}
                                    onChange={handleClick}
                                    style={{
                                        width: '100%',
                                        padding: 10,
                                        background: '#dcd4fc',
                                        color: '#522bd2',
                                        textAlign: 'center',
                                        fontFamily: `Montserrat`,
                                        fontWeight: 600,
                                        border: '0.0625rem solid #522bd2',
                                        marginTop: 10
                                    }}
                                >
                                    {buttonsList.map((p, i) => {
                                        return (
                                            <option key={i + 1} value={p.id} style={{ background: 'white' }}>
                                                {p.name?.toUpperCase()}
                                            </option>
                                        );
                                    })}
                                </select>
                            </Grid>
                        )}
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <TextBox required name="Search" label="Search" onChange={setFilterText} value={state.filterText} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="kpiCard">
                                {state.filterTableData && (
                                    <DemoStockTable list={state.filterTableData || []} showName={true} refreshNgetData={refreshNgetData} />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default DemoStock;
