import React, { useState, useEffect } from 'react';
import { Grid, FormLabel, DialogContent, DialogTitle, Box, IconButton } from '@material-ui/core';
import Multiselect, { MultipleSelectCheckmarks } from '../../Core/FormInput/MultiSelect';
import moment from 'moment';
import { getReportsDropdowns } from '../../Core/Service/reportService';
import { DatePicker, SelectBox } from '../../Core/FormInput';
import { useWindowSize } from '../../Core/Controls/ScreenResolution';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterBtnWithIcon } from '../../Core/BudgetCore/budgetButton';

import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import '../../Core/BudgetCore/budget.scss';
import '../../Core/BudgetCore/budgetCommon.scss';

const closeIconStyle = {
    marginRight: 7
};

let monthOfDate = [
    { id: 1, name: 'Month to Date' },
    { id: 2, name: 'Q1' },
    { id: 3, name: 'Q2' },
    { id: 4, name: 'Q3' },
    { id: 5, name: 'Q4' },
    { id: 6, name: 'January' },
    { id: 7, name: 'February' },
    { id: 8, name: 'March' },
    { id: 9, name: 'April' },
    { id: 10, name: 'May' },
    { id: 11, name: 'June' },
    { id: 12, name: 'July' },
    { id: 13, name: 'August' },
    { id: 14, name: 'September' },
    { id: 15, name: 'October' },
    { id: 16, name: 'November' },
    { id: 17, name: 'December' }
];

const BudgetFilter = (props) => {
    let currnetMonth = new Date();
    const [WindowWidths] = useWindowSize();
    const dateRange = {
        6: 0,
        7: 1,
        8: 2,
        9: 3,
        10: 4,
        11: 5,
        12: 6,
        13: 7,
        14: 8,
        15: 9,
        16: 10,
        17: 11
    };
    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
    let getWeek = `${moment().format('YYYY')}-W${moment().format('ww')}` || '';
    const [checked, setChecked] = React.useState(false);

    const handleChangeCollaps = () => {
        setChecked((prev) => !prev);
    };

    const [state, setState] = useState({
        branchList: [],
        franchise: [],
        unitsList: {},
        branchBudgets: {},
        Multiselect: [],
        monthOfDate: [],
        yearList: [],
        newWeekFilter: JSON.parse(localStorage.getItem('NicoleFilterData'))?.newWeekFilter || [
            {
                startDate: moment().startOf('week').format('YYYY-MM-DD'),
                endDate: moment().endOf('week').format('YYYY-MM-DD')
            }
        ],
        currentWeek: JSON.parse(localStorage.getItem('NicoleFilterData'))?.currentWeek
            ? JSON.parse(localStorage.getItem('NicoleFilterData'))?.currentWeek
            : getWeek,

        monthList: [monthOfDate[0].id],
        yearID: JSON.parse(localStorage.getItem('NicoleFilterData'))?.yearId || `${moment().year()}`,
        groupIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.groupIds || [JSON.parse(localStorage.getItem('_defaultBranchID'))] || [],
        franchiseIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.franchiseIds || [],
        filterDate: JSON.parse(localStorage.getItem('NicoleFilterData'))?.filterDate || [
            {
                startDate: moment(currnetMonth).startOf('month').format('YYYY-MM-DD'),
                endDate: moment(currnetMonth).endOf('month').format('YYYY-MM-DD')
            }
        ]
    });

    const [temp, tempOption] = useState({
        groupVal: [],
        franchiseVal: JSON.parse(localStorage.getItem('NicoleFilterData'))?.franchiseVal || [],
        listOfMonth: JSON.parse(localStorage.getItem('NicoleFilterData'))?.listOfMonth || [monthOfDate[0]],
        yearlist: JSON.parse(localStorage.getItem('NicoleFilterData'))?.yearlist || []
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                let DropDownRes = await getReportsDropdowns(props.isPermissionBased);

                if (DropDownRes.success) {
                    setState((st) => ({
                        ...st,
                        branchList: DropDownRes.data.branch,
                        franchise: DropDownRes.data.franchiseCode,
                        monthOfDate: monthOfDate || [],
                        yearList: DropDownRes.data.year.map((m) => ({
                            id: m.value,
                            name: m.text
                        }))
                    }));
                } else {
                    // Handle the case where the request for DropDownRes was not successful
                    console.error('Request for DropDownRes was not successful:');
                }
            } catch (error) {
                // Handle any errors that might occur during the asynchronous operation
                console.error('An error occurred:', error);
            }
        };

        fetchData();
    }, [props.isPermissionBased]);

    useEffect(() => {
        let data = {
            filterDate: state.filterDate,
            franchiseCode: state.franchiseIds,
            branchID: state.groupIds,
            isReload: new Date(),
            type: props.type ? props.type : '',
            isTable: props.isTable ? props.isTable : false,
            newWeekFilter: state.newWeekFilter
        };

        props.getFilterData(data);
    }, [props.type, state.currentWeek, props.includeReserved, state.yearID]);

    const HandleDoneSubmit = (val) => {
        let data = {
            filterDate: state.filterDate,
            franchiseCode: state.franchiseIds,
            branchID: state.groupIds,
            isReload: new Date(),
            type: props.type ? props.type : '',
            isTable: props.isTable ? props.isTable : false,
            newWeekFilter: state.newWeekFilter
        };

        props.getFilterData(data);
    };

    useEffect(() => {
        if (state.branchList.length > 0) {
            tempOption((st) => ({
                ...st,
                groupVal: state.groupIds.map((w) => {
                    let list = state.branchList.find((m) => w === m.id);
                    return list;
                })
            }));
        }
    }, [state.branchList, state.groupIds]);

    useEffect(() => {
        if (state.branchList.length > 0 && props.getSelectedBranchList) {
            let tempBranchList =
                state.branchList.length > 0
                    ? state.groupIds.map((p) => {
                          let obj = state.branchList.find((q) => q.id == p);
                          return obj;
                      })
                    : [];
            props.getSelectedBranchList(tempBranchList);
        }
    }, [state.branchList, state.groupIds]);

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: value.map((c) => c.id) }));
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };
    const multiHandleChanges = (val) => {
        if (val?.length > 0) {
            let idArray = [];
            val.forEach((n) => {
                let index = state.branchList.findIndex((k) => k.name === n);
                if (index >= 0) {
                    idArray.push(state.branchList[index].id);
                }
            });
            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: idArray }));
            setState((st) => {
                const nst = { ...st, groupIds: idArray };
                return nst;
            });
        }
    };
    const multiFrenchiseHandleChanges = (val) => {
        if (val?.length > 0) {
            let idArray = [];
            let ObjArray = [];
            val.forEach((n) => {
                let index = state.franchise.findIndex((k) => k.name === n);
                if (index >= 0) {
                    idArray.push(state.franchise[index].id);
                    ObjArray.push({ id: state.franchise[index].id, name: state.franchise[index].name });
                }
            });
            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, franchiseIds: idArray, franchiseVal: ObjArray }));
            setState((st) => {
                const nst = { ...st, franchiseIds: idArray };
                return nst;
            });
        }
    };
    const multiPeriodHandleChanges = (val) => {
        if (val?.length > 0) {
            let array = [];
            let idArray = [];
            let ObjArray = [];
            val.forEach((n) => {
                let index = state.monthOfDate.findIndex((k) => k.name === n);
                if (index >= 0) {
                    idArray.push(state.monthOfDate[index].id);
                    ObjArray.push({ id: state.monthOfDate[index].id, name: state.monthOfDate[index].name });
                }
            });
            tempOption((st) => ({ ...st, listOfMonth: ObjArray }));
            let tempNewArr = [...ObjArray].map((n) => {
                let startRange;
                let endRange;
                if (n.id >= 6) {
                    startRange = moment().month(dateRange[n.id]).startOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
                    endRange = moment().month(dateRange[n.id]).endOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
                } else {
                    switch (n.id) {
                        case 1:
                            startRange = moment(currnetMonth).startOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
                            endRange = moment(currnetMonth).endOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
                            break;
                        case 2:
                            startRange = moment().month(0).startOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
                            endRange = moment().month(2).endOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
                            break;
                        case 3:
                            startRange = moment().month(3).startOf('month').year(state.yearID).format('YYYY-MM-DD');
                            endRange = moment().month(5).endOf('month').year(state.yearID).format('YYYY-MM-DD');
                            break;
                        case 4:
                            startRange = moment().month(6).startOf('month').year(state.yearID).format('YYYY-MM-DD');
                            endRange = moment().month(8).endOf('month').year(state.yearID).format('YYYY-MM-DD');
                            break;
                        case 5:
                            startRange = moment().month(9).startOf('month').year(state.yearID).format('YYYY-MM-DD');
                            endRange = moment().month(11).endOf('month').year(state.yearID).format('YYYY-MM-DD');
                            break;
                        default:
                            // Handle the default case here
                            console.log('Invalid n.id value or no matching case found');
                            break;
                    }
                }
                return {
                    startDate: startRange,
                    endDate: endRange
                };
            });
            array = [...array, ...tempNewArr];
            localStorage.setItem(
                'NicoleFilterData',
                JSON.stringify({
                    ...portalNicolJson,
                    listOfMonth: ObjArray,
                    filterDate: [...array]
                })
            );
            setState((st) => {
                const nst = { ...st, monthList: idArray };
                if (array.length > 0) {
                    nst.filterDate = [...array];
                }
                return nst;
            });
        }
    };

    const handleWeek = (e) => {
        let newWeekFilter = [];
        let startDate = moment(e.target.value).startOf('isoWeek').format('YYYY-MM-DD');
        let endDate = moment(e.target.value).endOf('isoWeek').format('YYYY-MM-DD');
        newWeekFilter.push({
            startDate: startDate,
            endDate: endDate
        });

        localStorage.setItem(
            'NicoleFilterData',
            JSON.stringify({
                ...portalNicolJson,
                newWeekFilter,
                currentWeek: e.target.value
            })
        );
        setState((st) => ({
            ...st,
            currentWeek: e.target.value,
            newWeekFilter: [...newWeekFilter]
        }));
    };

    const handleChangesfranch = (event, value) => {
        tempOption((st) => ({ ...st, franchiseVal: value }));
        localStorage.setItem(
            'NicoleFilterData',
            JSON.stringify({
                ...portalNicolJson,
                franchiseVal: value,
                franchiseIds: value.map((c) => c.id)
            })
        );
        setState((st) => {
            const nst = { ...st, franchiseIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handlePeriodMonths = (event, value) => {
        let array = [];
        let currnetMonth = new Date();
        tempOption((st) => ({ ...st, listOfMonth: value }));
        let tempNewArr = [...value].map((n) => {
            let startRange;
            let endRange;
            if (n.id >= 6) {
                startRange = moment().month(dateRange[n.id]).startOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
                endRange = moment().month(dateRange[n.id]).endOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
            } else {
                switch (n.id) {
                    case 1:
                        startRange = moment(currnetMonth).startOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
                        endRange = moment(currnetMonth).endOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
                        break;
                    case 2:
                        startRange = moment().month(0).startOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');
                        endRange = moment().month(2).endOf('month').year(`${state.yearID}`).format('YYYY-MM-DD');

                        break;
                    case 3:
                        startRange = moment().month(3).startOf('month').year(state.yearID).format('YYYY-MM-DD');
                        endRange = moment().month(5).endOf('month').year(state.yearID).format('YYYY-MM-DD');

                        break;
                    case 4:
                        startRange = moment().month(6).startOf('month').year(state.yearID).format('YYYY-MM-DD');
                        endRange = moment().month(8).endOf('month').year(state.yearID).format('YYYY-MM-DD');

                        break;
                    case 5:
                        startRange = moment().month(9).startOf('month').year(state.yearID).format('YYYY-MM-DD');
                        endRange = moment().month(11).endOf('month').year(state.yearID).format('YYYY-MM-DD');
                        break;
                    default:
                        // Handle the default case here
                        console.log('Invalid n.id value or no matching case found');
                        break;
                }
            }
            return {
                startDate: startRange,
                endDate: endRange
            };
        });
        array = [...array, ...tempNewArr];

        localStorage.setItem(
            'NicoleFilterData',
            JSON.stringify({
                ...portalNicolJson,
                listOfMonth: value,
                filterDate: [...array]
            })
        );

        setState((st) => {
            const nst = { ...st, monthList: value.map((c) => c.id) };
            if (array.length > 0) {
                nst.filterDate = [...array];
            }
            return nst;
        });
    };

    const handlePeriodYear = (event) => {
        let array = [];
        let { name, value } = event.target;
        if (state.filterDate.length > 0) {
            array = state.filterDate.map((m) => ({
                startDate: moment(m.startDate).set('year', value).format('YYYY-MM-DD'),
                endDate: moment(m.endDate).set('year', value).format('YYYY-MM-DD')
            }));
            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, yearId: value, filterDate: array }));
        }
        setState((st) => ({ ...st, [name]: value, filterDate: array }));
    };

    let randerSelectAll = (type, name) => {
        if (type === 'SelectAll') {
            tempOption((st) => {
                let newSt = {
                    ...st
                };
                if (name === 'branch') {
                    newSt.groupVal = [...state.branchList];
                    localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: state.branchList.map((c) => c.id) }));
                    setState((st) => {
                        const nst = { ...st, groupIds: state.branchList.map((c) => c.id) };
                        return nst;
                    });
                }
                if (name === 'franchise') {
                    newSt.franchiseVal = [...state.franchise];
                    localStorage.setItem(
                        'NicoleFilterData',
                        JSON.stringify({
                            ...portalNicolJson,
                            franchiseVal: [...state.franchise],
                            franchiseIds: [...state.franchise].map((c) => c.id)
                        })
                    );
                    setState((st) => {
                        const nst = { ...st, franchiseIds: [...state.franchise].map((c) => c.id) };
                        return nst;
                    });
                }
                if (name === 'period') {
                    newSt.listOfMonth = [...state.monthOfDate];
                    localStorage.setItem(
                        'NicoleFilterData',
                        JSON.stringify({
                            ...portalNicolJson,
                            listOfMonth: [...state.monthOfDate],
                            filterDate: [...state.monthOfDate]
                        })
                    );

                    setState((st) => {
                        const nst = { ...st, monthList: [...state.monthOfDate].map((c) => c.id) };
                        nst.filterDate = [...state.monthOfDate];
                        return nst;
                    });
                }
                return newSt;
            });
        }
        if (type === 'clear') {
            tempOption((st) => {
                let newSt = {
                    ...st
                };
                if (name === 'branch') {
                    newSt.groupVal = [];
                    localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: [] }));
                    setState((st) => {
                        const nst = { ...st, groupIds: [] };
                        return nst;
                    });
                }
                if (name === 'franchise') {
                    newSt.franchiseVal = [];
                    localStorage.setItem(
                        'NicoleFilterData',
                        JSON.stringify({
                            ...portalNicolJson,
                            franchiseVal: [],
                            franchiseIds: []
                        })
                    );
                    setState((st) => {
                        const nst = { ...st, franchiseIds: [] };
                        return nst;
                    });
                }
                if (name === 'period') {
                    newSt.listOfMonth = [];
                    localStorage.setItem(
                        'NicoleFilterData',
                        JSON.stringify({
                            ...portalNicolJson,
                            listOfMonth: [],
                            filterDate: []
                        })
                    );

                    setState((st) => {
                        const nst = { ...st, monthList: [] };
                        nst.filterDate = [];
                        return nst;
                    });
                }
                return newSt;
            });
        }
    };

    return (
        <>
            {WindowWidths < 960 ? (
                <div className="filterGrid">
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <FilterBtnWithIcon onClick={handleChangeCollaps} active={checked}>
                                <FilterListIcon />
                            </FilterBtnWithIcon>
                        </Grid>
                    </Grid>
                    {checked ? (
                        <Dialog onClose={() => setChecked(false)} open={checked}>
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    <DialogTitle>Filters</DialogTitle>
                                </Box>
                                <Box>
                                    <IconButton onClick={() => setChecked(false)} style={closeIconStyle}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>

                            <DialogContent style={{ height: '300px !important' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                            SELECT BRANCH
                                        </FormLabel>
                                        <MultipleSelectCheckmarks
                                            options={state.branchList || []}
                                            getvalue={(val) => multiHandleChanges(val)}
                                            isDoneFunction={HandleDoneSubmit}
                                            value={temp.groupVal.map((n) => n.name)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                            SELECT FRANCHISE
                                        </FormLabel>
                                        <MultipleSelectCheckmarks
                                            options={state.franchise || []}
                                            value={temp.franchiseVal.map((n) => n.name)}
                                            isDoneFunction={HandleDoneSubmit}
                                            getvalue={(val) => multiFrenchiseHandleChanges(val)}
                                        />
                                    </Grid>
                                    {!props.sales ? (
                                        <>
                                            <Grid item xs={12}>
                                                <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                                    SELECT PERIOD
                                                </FormLabel>
                                                <MultipleSelectCheckmarks
                                                    options={state.monthOfDate || []}
                                                    getvalue={(val) => multiPeriodHandleChanges(val)}
                                                    isDoneFunction={HandleDoneSubmit}
                                                    value={temp.listOfMonth.map((n) => n.name)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 10 }}>
                                                    &nbsp;
                                                </FormLabel>
                                                <SelectBox onChange={handlePeriodYear} value={state.yearID} name="yearID" List={state.yearList || []} />
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={12}>
                                            <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 11 }}>
                                                SELECT PERIOD
                                            </FormLabel>
                                            <br />
                                            <DatePicker type="week" onChange={handleWeek} value={state.currentWeek || ''} placeholder="yyyy-mm-dd" />
                                        </Grid>
                                    )}
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    ) : null}
                </div>
            ) : (
                <>
                    <Grid container>
                        <Grid item container xs={12} sm={6} md={6} lg={4} xl={4} className="containerPadding">
                            <Grid item xs={12}>
                                <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                    SELECT BRANCH
                                </FormLabel>
                                <Multiselect
                                    options={state.branchList || []}
                                    onChange={handleChanges}
                                    value={temp.groupVal}
                                    selectAll={randerSelectAll}
                                    name="branch"
                                    isDoneFunction={HandleDoneSubmit}
                                    disableClearable={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6} lg={3} xl={4} className="containerPadding">
                            <Grid item xs={12}>
                                {' '}
                                <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                    SELECT FRANCHISE
                                </FormLabel>
                                <Multiselect
                                    name="franchise"
                                    options={state.franchise || []}
                                    selectAll={randerSelectAll}
                                    value={temp.franchiseVal}
                                    onChange={handleChangesfranch}
                                    isDoneFunction={HandleDoneSubmit}
                                    disableClearable={true}
                                />
                            </Grid>
                        </Grid>
                        {!props.sales ? (
                            <Grid item container xs={12} sm={6} md={6} lg={5} xl={4}>
                                <Grid item xs={8} md={8} lg={8} className="containerPadding">
                                    <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                        SELECT PERIOD
                                    </FormLabel>
                                    <Multiselect
                                        name="period"
                                        options={state.monthOfDate || []}
                                        selectAll={randerSelectAll}
                                        onChange={handlePeriodMonths}
                                        isDoneFunction={HandleDoneSubmit}
                                        value={temp.listOfMonth}
                                        disableClearable={true}
                                    />
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} className="containerPadding">
                                    <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 10 }}>
                                        &nbsp;
                                    </FormLabel>
                                    <SelectBox onChange={handlePeriodYear} value={state.yearID} name="yearID" List={state.yearList || []} />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={6} md={6} lg={5} xl={4} className="containerPadding week">
                                <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 11 }}>
                                    SELECT PERIOD
                                </FormLabel>
                                <DatePicker type="week" onChange={handleWeek} value={state.currentWeek || ''} />
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </>
    );
};

export default BudgetFilter;
