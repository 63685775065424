import { Badge, Checkbox, FormControlLabel } from '@material-ui/core';
import { FormLabel, Grid, Hidden } from '@material-ui/core';
import { Multiselect, TextBox } from '../../../Core/FormInput';
import React, { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { getReportsDropdowns } from '../../../Core/Service/reportService';
import { LinkedButton } from '../../../Core/BudgetCore/budgetButton';
import { UsedStocksCard, UsedStocksCardMobileView, UsedStocksCardTWO } from '../../../Core/BudgetCore/budgetCardsUi_v2';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import UsedStocksTable from './usedStockTable';
import { getVehicleProfileListDropDown, postUsedVehicleStockGetExcelFile, postUsedVehicleStockValuation } from '../../../Core/Service/Nicol_Common_services';
import { useHistory } from 'react-router-dom';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { AppStorage } from '../../../Core/Service/storage-service';
import formatters from '../../../Core/Grid/inputFormatter';
import { some } from 'lodash';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { MultipleSelectCheckmarks } from '../../../Core/FormInput/MultiSelect';

let searchTimer;

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600]
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'white', border: '1px solid black', padding: '10px', textAlign: 'center' }}>
                <div>{label}</div>
                <div style={{ color: '#1ECBE1' }}>
                    {payload[0].name}: &nbsp;
                    {formatters.CurrencyThousandSeparatorWithoutZero(+payload[0].value)}
                </div>
            </div>
        );
    }

    return null;
};

const UsedStock = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let BranchID = +params.get('ID') || null;

    const [WindowWidths] = useWindowSize();

    let VehicleFilter = JSON.parse(localStorage.getItem('NicoleFilterData'))?.VehicleProfileIds || [];
    if (!VehicleFilter?.includes('R') && !VehicleFilter?.includes('A')) {
        VehicleFilter = ['R', 'A', ...VehicleFilter];
    } else if (!VehicleFilter?.includes('R')) {
        VehicleFilter = ['R', ...VehicleFilter];
    } else if (!VehicleFilter?.includes('A')) {
        VehicleFilter = ['A', ...VehicleFilter];
    }

    const [state, setState] = useState({
        branches: [],
        tableRow: [],
        branchList: [],
        VehicleProfile: [],
        groupIds: BranchID ? [BranchID] : JSON.parse(localStorage.getItem('_defaultBranchID')) ? [JSON.parse(localStorage.getItem('_defaultBranchID'))] : [],
        VehicleProfileIds: JSON.parse(localStorage.getItem('NicoleFilterData'))?.VehicleIdState ? VehicleFilter : ['R', 'A'] || [],
        showTable: true,
        showLoader: true,
        isReload: '',
        modelArray: ['LTHIRE', 'DEMOCAR', 'COURTSYCAR', 'DEMOCOMM', 'HIRECAR'],
        VehicleIdState: JSON.parse(localStorage.getItem('NicoleFilterData'))?.VehicleProfileIds || false,
        LTHIRE: true,
        DEMOCAR: true,
        COURTSYCAR: true,
        DEMOCOMM: true,
        HIRECAR: true,
        redirectedBranch: JSON.parse(localStorage.getItem('NicoleFilterData'))?.redirectBranch || null
    });

    const [stockData, setStockData] = useState({
        branchData: [],
        tableData: [],
        weeklyData: [],
        gpValueData: [],
        mainData: [],
        showLoader: true,
        filterTableData: [],
        filterText: ''
    });

    const history = useHistory();

    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));

    const [temp, tempOption] = useState({
        groupVal: [],
        VehicleProfileVal: []
    });

    const handleChange = (event) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[event.target.name] = event.target.checked;
            let newArr = [];
            if (event.target.checked === true) {
                newArr.push(...st.modelArray, event.target.name);
                newSt.modelArray = newArr;
            }
            if (event.target.checked === false) {
                let slicedArr = [...st.modelArray];
                const index = slicedArr.indexOf(event.target.name);
                if (index > -1) {
                    slicedArr.splice(index, 1);
                }
                newSt.modelArray = slicedArr;
            }
            return newSt;
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let DropDownRes = await getReportsDropdowns(false);
                let newRes = await getVehicleProfileListDropDown();

                if (DropDownRes.success) {
                    setState((st) => ({
                        ...st,
                        branchList: DropDownRes?.data.branch || [],
                        vehicleList: newRes?.data.list
                    }));
                } else {
                    // Handle the case where the request for DropDownRes was not successful
                    console.error('Request for DropDownRes was not successful:');
                }
            } catch (error) {
                // Handle any errors that might occur during the asynchronous operation
                console.error('An error occurred:');
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (state.branchList?.length > 0) {
            if (state.groupIds?.length > 0) {
                tempOption((st) => ({
                    ...st,
                    groupVal: state.groupIds.map((w) => {
                        let list = state?.branchList?.find((m) => w === m.id);
                        return list;
                    })
                }));
            }
            if (state.VehicleProfileIds.length > 0) {
                tempOption((st) => ({
                    ...st,
                    VehicleProfileVal: state.VehicleProfileIds.map((w) => {
                        let list = state?.vehicleList?.find((m) => w === m.id);
                        return list;
                    })
                }));
            }
        }
    }, [state.branchList, state.groupIds]);

    const pullData = async (Ids) => {
        let data = {
            vehicleID: state.VehicleProfileIds,
            branchID: Ids || [],
            model: state.modelArray
        };
        let res = await postUsedVehicleStockValuation(data);
        if (res.success) {
            let combineData = [];
            let weeklyData = res?.data?.weeklyData || [];
            if (res?.data?.mainData != null && res?.data?.mainData.length > 0) {
                combineData = res?.data?.mainData?.map((p) => {
                    let weeklyDataByIdList = weeklyData.filter((q) => q.branchID == p.branchID);
                    p.weeklyDataList = [...weeklyDataByIdList].sort(
                        (a, b) => moment(b.valuationDate1).format('YYYYMMDD') - moment(a.valuationDate1).format('YYYYMMDD')
                    );
                    p.weeklyDataList = p.weeklyDataList.map((m, index) => ({
                        ...m,
                        getCalculatedvalue: ((m.stockValue1 - p.weeklyDataList[index + 1]?.stockValue1) / p.weeklyDataList[index + 1]?.stockValue1) * 100
                    }));
                    return p;
                });
            }

            setStockData((st) => ({
                ...st,
                mainData: combineData,
                tableData: res?.data?.tableData || [],
                weeklyData: res?.data?.weeklyData,
                gpValueData: res?.data?.gpStock,
                showLoader: false,
                uncappedVehicles: res?.data?.uncappedVehicles[0]?.uncappedVehicles || []
            }));
        }
    };

    useEffect(() => {
        let Ids = state.groupIds;
        pullData(Ids);
    }, [state.modelArray, state.isPullData, state.groupIds]);

    const refreshNgetData = () => {
        setState((st) => {
            const nst = { ...st, isPullData: new Date() };
            return nst;
        });
    };

    const screenReload = (data, ids) => {
        setState((st) => ({
            ...st,
            isReload: data
        }));

        pullData(ids);
    };

    const handleChanges = (event, value) => {
        tempOption((st) => ({ ...st, groupVal: value }));
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupVal: value, groupIds: value.map((c) => c.id) }));
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const multiHandleChanges = (val) => {
        let idArray = [];
        val.forEach((n) => {
            let index = state.branchList.findIndex((k) => k.name === n);
            if (index >= 0) {
                idArray.push(state.branchList[index].id);
            }
        });
        setState((st) => {
            const nst = { ...st, groupIds: idArray };
            return nst;
        });
    };

    const handleChangesfranch = (event, value) => {
        tempOption((st) => ({ ...st, VehicleProfileVal: value }));

        setState((st) => {
            const nst = { ...st, VehicleProfileIds: value.map((c) => c.id) };
            localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, VehicleProfileIds: value.map((c) => c.id), VehicleIdState: true }));
            return nst;
        });
    };
    const multiHandleFranchChanges = (val) => {
        let idArray = [];
        val.forEach((n) => {
            let index = state.vehicleList.findIndex((k) => k.name === n);
            if (index >= 0) {
                idArray.push(state.vehicleList[index].id);
            }
        });
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, VehicleProfileIds: idArray }));
        setState((st) => {
            const nst = { ...st, VehicleProfileIds: idArray };
            return nst;
        });
    };

    const downloadCSVWrapper = async () => {
        let data = {
            vehicleID: state.VehicleProfileIds,
            branchID: state.groupIds,
            model: state.modelArray
        };
        await postUsedVehicleStockGetExcelFile(data, 'Used_Vehicle_Stock_Valuation');
    };
    const isUsedStockValuationAllowed = AppStorage.getReportingCanAccessUsedStockValuation();
    const isOveragePriorityListAllowed = AppStorage.getReportingCanAccessOveragePriorityList();

    const setFilterText = (e) => {
        const vl = e.target.value;
        setStockData((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setStockData((st) => {
            const newSt = { ...st };
            const { filterText, tableData } = newSt;
            let filterd = [];
            filterd = [...tableData];
            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }
            newSt.filterTableData = filterd;
            return newSt;
        });
    };
    useEffect(() => {
        setFilteredRow();
    }, [stockData.tableData]);

    let randerSelectAll = (type, name) => {
        if (type === 'SelectAll') {
            tempOption((st) => {
                let newSt = {
                    ...st
                };
                if (name === 'branch') {
                    newSt.groupVal = [...state.branchList];
                    localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: state.branchList.map((c) => c.id) }));
                    setState((st) => {
                        const nst = { ...st, groupIds: state.branchList.map((c) => c.id) };
                        return nst;
                    });
                }
                if (name === 'vehicle') {
                    newSt.VehicleProfileVal = [...state.vehicleList];
                    setState((st) => {
                        const nst = { ...st, VehicleProfileIds: state.vehicleList.map((c) => c.id) };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, VehicleProfileIds: state.vehicleList.map((c) => c.id) }));
                        return nst;
                    });
                }

                return newSt;
            });
        }
        if (type === 'clear') {
            tempOption((st) => {
                let newSt = {
                    ...st
                };
                if (name === 'branch') {
                    newSt.groupVal = [];
                    localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: [] }));
                    setState((st) => {
                        const nst = { ...st, groupIds: [] };
                        return nst;
                    });
                }
                if (name === 'vehicle') {
                    newSt.VehicleProfileVal = [];
                    setState((st) => {
                        const nst = { ...st, VehicleProfileIds: [] };
                        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, VehicleProfileIds: [] }));
                        return nst;
                    });
                }

                return newSt;
            });
        }
    };
    let onRedirectToUncappedVehicled = () => {
        history.push({ pathname: '/Uncapped_Vehicled', search: `?Type=F` });
    };
    return (
        <div className="budgetScreen">
            {stockData.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Grid container alignItems="center" alignContent="center" justifyContent="space-between">
                        <Grid item xs={6} sm={8} md={9} lg={9} xl={9}>
                            <div className="mainHeading alignLeft screenMainHeader">Used Vehicle Stock</div>
                        </Grid>
                        <Grid item spacing={2} container xs={6} sm={4} md={3} lg={3} xl={3} justifyContent="flex-end">
                            {AppStorage.getReportingCanAccessUncappedVehicled() && (
                                <Grid item>
                                    <Badge
                                        overlap="rectangular"
                                        badgeContent={stockData.uncappedVehicles || 0}
                                        color="secondary"
                                        style={{ marginTop: 18, cursor: 'pointer' }}
                                        title="Uncapped Vehicles"
                                        onClick={onRedirectToUncappedVehicled}
                                    >
                                        <DirectionsCarIcon color="secondary" />
                                    </Badge>
                                </Grid>
                            )}
                            <Grid item style={{ marginTop: WindowWidths > 1024 ? 0 : 10 }}>
                                <LinkedButton onClick={downloadCSVWrapper}>
                                    <ViewComfyIcon />
                                    {WindowWidths > 1024 ? <> &nbsp;&nbsp;DOWNLOAD CSV</> : ''}
                                </LinkedButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-evenly">
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={4} className="containerPadding">
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} lg={12}>
                                    <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                        SELECT BRANCH
                                    </FormLabel>
                                    <Hidden only={['sm', 'md', 'xl', 'lg']}>
                                        <MultipleSelectCheckmarks
                                            options={state.branchList || []}
                                            getvalue={(val) => multiHandleChanges(val)}
                                            value={temp.groupVal.map((n) => n.name)}
                                            isDoneFunction={refreshNgetData}
                                        />
                                    </Hidden>
                                    <Hidden only={['xs']}>
                                        <Multiselect
                                            isDoneFunction={refreshNgetData}
                                            options={state.branchList || []}
                                            onChange={handleChanges}
                                            value={temp.groupVal}
                                            fullWidth
                                            name="branch"
                                            selectAll={randerSelectAll}
                                            disableClearable={true}
                                        />
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={12}>
                                    <FormLabel component="legend" className="budgetLebels" style={{ marginBottom: 2 }}>
                                        VEHICLE PROFILE
                                    </FormLabel>
                                    <Hidden only={['sm', 'md', 'xl', 'lg']}>
                                        <MultipleSelectCheckmarks
                                            options={state.vehicleList || []}
                                            getvalue={(val) => multiHandleFranchChanges(val)}
                                            value={temp.VehicleProfileVal.map((n) => n.name)}
                                            isDoneFunction={refreshNgetData}
                                        />
                                    </Hidden>
                                    <Hidden only={['xs']}>
                                        <Multiselect
                                            isDoneFunction={refreshNgetData}
                                            name="vehicle"
                                            value={temp.VehicleProfileVal}
                                            options={state.vehicleList || []}
                                            onChange={handleChangesfranch}
                                            selectAll={randerSelectAll}
                                            disableClearable={true}
                                        />
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={7} xl={8} className="containerPadding">
                            <Grid
                                container
                                justifyContent="space-around"
                                alignItems="center"
                                className="UVSbranchOverViewKip_card"
                                style={{ padding: '8px 10px ' }}
                            >
                                <div className="USVtype2_Text alignLeft p4 w100">GROUP STOCK VALUE - COMBINED (YR)</div>
                                <ResponsiveContainer className="USV-Graph">
                                    <LineChart data={stockData.gpValueData}>
                                        <CartesianGrid x={1} y={0} />
                                        <Line dataKey="stockValue" stroke="#1ECBE1" strokeWidth={4} Label name="Stock Value" />
                                        <Tooltip content={<CustomTooltip />} />
                                        <XAxis dataKey="month" textAnchor="end" sclaeToFit="true" verticalAnchor="start" style={{ fontSize: 10 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    {stockData?.mainData?.map((p, i) => {
                        return (
                            <React.Fragment key={`${i}_UVS`}>
                                <Grid container key={i}>
                                    {WindowWidths > 600 && (
                                        <Grid item xl={1} lg={1} md={4} sm={4} xs={12} className="containerPadding">
                                            <div
                                                className="USVUnitKipOne_card"
                                                style={{
                                                    background: '#569101',
                                                    color: 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <div>
                                                    <div className="USVUnitHeading">{p.units}</div>
                                                    <div className="USVUnitHeading">Units</div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                    {WindowWidths >= 600 ? (
                                        <Grid item xl={4} lg={4} md={8} sm={8} xs={12} className="containerPadding">
                                            <UsedStocksCard
                                                showStock={isUsedStockValuationAllowed}
                                                showOverage={isOveragePriorityListAllowed}
                                                CurrentStockValue={p?.stockValue}
                                                AverageStockValue={p?.avgStockValue}
                                                PreviousYearAverage={p?.prevYearAvgValue}
                                                stockLink={'/usedStock'}
                                                priorityLink={'/overagePriority'}
                                                history={history}
                                                screenReload={screenReload}
                                                branchID={p?.branchID}
                                                branch={p.branch}
                                                setState={setState}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} className="containerPadding">
                                            <UsedStocksCardMobileView
                                                showStock={isUsedStockValuationAllowed}
                                                showOverage={isOveragePriorityListAllowed}
                                                CurrentStockValue={p?.stockValue}
                                                AverageStockValue={p?.avgStockValue}
                                                PreviousYearAverage={p?.prevYearAvgValue}
                                                stockLink={'/usedStock'}
                                                priorityLink={'/overagePriority'}
                                                history={history}
                                                screenReload={screenReload}
                                                branchID={p?.branchID}
                                                branch={p.branchShortName}
                                                Units={p.units}
                                                setState={setState}
                                            />
                                        </Grid>
                                    )}

                                    {WindowWidths > 600 && (
                                        <Grid item xl={7} lg={7} md={12} sm={12} xs={12} className="containerPadding">
                                            <UsedStocksCardTWO list={p.weeklyDataList || []} />
                                        </Grid>
                                    )}
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                    <Grid container>
                        <Grid item xs={12} className="containerPadding">
                            <Grid container>
                                <Grid item xs={12} sm={4}>
                                    <TextBox required name="Search" label="Search" onChange={setFilterText} value={stockData.filterText} />
                                </Grid>
                                <Grid container item xs={12} sm={8} justifyContent="space-evenly" alignContent="center" style={{ marginTop: 5 }}>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={state.LTHIRE} onChange={handleChange} name="LTHIRE" color="primary" />}
                                            label="LTHIRE"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={state.DEMOCAR} onChange={handleChange} name="DEMOCAR" color="primary" />}
                                            label="DEMOCAR"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={state.COURTSYCAR} onChange={handleChange} name="COURTSYCAR" color="primary" />}
                                            label="COURTSYCAR"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={state.DEMOCOMM} onChange={handleChange} name="DEMOCOMM" color="primary" />}
                                            label="DEMOCOMM"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={state.HIRECAR} onChange={handleChange} name="HIRECAR" color="primary" />}
                                            label="HIRECAR"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="containerPadding">
                            <div className="kpiCard">
                                {stockData.filterTableData && <UsedStocksTable list={stockData.filterTableData} refreshNgetData={refreshNgetData} />}
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default UsedStock;
