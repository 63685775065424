import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 600,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    rootSmall: {
        display: 'flex',
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    }
}));

export default function CircularIndeterminate(props) {
    const classes = useStyles();

    return (
        <div className={props.type === 'small' ? classes.rootSmall : classes.root}>
            <CircularProgress color={props.type === 'small' ? '' : 'secondary' } />
         </div>
    );
}