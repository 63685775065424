import React, { useState, useEffect } from 'react';
import { Grid, Hidden, TableHead, TableRow, TableCell, TableSortLabel, makeStyles, TableContainer, Table, TablePagination, TableBody } from '@material-ui/core';
import { TextBox } from '../../../Core/FormInput';
import formatters from '../../../Core/Grid/inputFormatter';
import BudgetFilter from '../budgetFilters';
import { postBranchOverViewUsedReserved, postReservedForUnits } from '../../../Core/Service/Nicol_Common_services';
import CircularIndeterminate from '../../../Core/BudgetCore/loader';
import { LinkedButton } from '../../../Core/BudgetCore/budgetButton';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { some } from 'lodash';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';

let searchTimer;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = (props, screenWidth) => [
    {
        id: 'action',
        width: '2%',
        label: ''
    },

    {
        id: 'sp',
        width: '12%',
        label: 'Exec'
    },
    {
        id: 'buyer',
        width: '12%',
        label: 'Bought By'
    },

    {
        id: 'reg',
        width: '10%',
        label: 'Reg Number'
    },
    {
        id: 'v',
        label: 'Vehicle'
    },
    {
        id: 'id',
        width: '14%',
        label: 'Invoice Date',
        align: 'right'
    },
    {
        id: 'cust',
        width: '12%',
        label: 'Customer'
    },

    {
        id: 'gp',
        width: '10%',
        label: 'Gross Profit',
        numeric: true,
        align: 'right'
    }
];

function EnhancedTableHead(props) {
    const [WindowWidths] = useWindowSize();
    const { classes, order, orderBy, onRequestSort, screenWidth = WindowWidths } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    let headerColumn = headCells(props, screenWidth);
    return (
        <TableHead>
            <TableRow>
                {headerColumn.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        width={headCell.width}
                        style={{ fontSize: 12, fontWeight: 600, padding: '0px 2px' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 800,
        '& .MuiTableCell-root': {
            padding: '5px 7px'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

// let baseColumns = [
//     { title: 'Exec', field: 'sp', width: '10%', sortable: true },
//     { title: 'Bought By', field: 'buyer', width: '10%', sortable: true },

//     { title: 'Branch', field: 'loc', width: '10%', sortable: true },
//     {
//         title: 'Reg Number',
//         field: 'reg',
//         width: '9%',
//         sortable: true
//     },
//     { title: 'Vehicle', field: 'v', width: '20%', sortable: true },
//     {
//         title: 'Invoice Date',
//         field: 'id',
//         width: '12%',
//         sortable: true,
//         format: 'dd-mmm-yyyy',
//         cssClass: 'text-center',
//         renderer: formatters.DateFormatter
//     },

//     {
//         title: 'Customer',
//         field: 'cust',
//         width: '13%',
//         sortable: true
//         // align: 'right'
//     },

//     {
//         title: 'Gross Profit',
//         field: 'gp',
//         width: '12%',
//         sortable: true,
//         renderer: function (value, record) {
//             return formatters.CurrencyThousandSeparatorWithoutZero(record.gp);
//         },
//         align: 'right'
//     }
// ];

const DetailTemplate = ({ props }) => {
    return (
        <div style={{ width: '100%', padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>VSB</b>
                        <br />
                        {props.vsb}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Sale Price</b>
                        <br />
                        {props.sale}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Cost Price</b>
                        <br />
                        {props.cost}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Disc O/A</b>
                        <br />
                        {props.discoval}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Bonuses</b>
                        <br />
                        {props.bonuses}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Subsidy</b>
                        <br />
                        {props.subsidy}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>CPI</b>
                        <br />
                        {props.cpi}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Finance</b>
                        <br />
                        {props.finance}
                    </span>
                </Grid>
                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Finance Company</b>
                        <br />
                        {props.fcompany}
                    </span>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>ACC Sale</b>
                        <br />
                        {props.accSale}
                    </span>
                </Grid>

                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>ACC Cost</b>
                        <br />
                        {props.accCost}
                    </span>
                </Grid>

                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>War Sale</b>
                        <br />
                        {props.warSale}
                    </span>
                </Grid>

                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>War Cost</b>
                        <br />
                        {props.warCost}
                    </span>
                </Grid>

                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Body Cost</b>
                        <br />
                        {props.bodyCost}
                    </span>
                </Grid>

                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Prep Cost</b>
                        <br />
                        {props.prepCost}
                    </span>
                </Grid>

                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Total Cost</b>
                        <br />
                        {props.tc}
                    </span>
                </Grid>

                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>Total Sale</b>
                        <br />
                        {props.ts}
                    </span>
                </Grid>

                <Grid item>
                    <span style={{ textAlign: 'left', width: '10%' }}>
                        {' '}
                        <b style={{ fontSize: '12px !important', padding: '10px 0px', fontWight: 900 }}>&nbsp;</b>
                        <br /> &nbsp;
                    </span>
                </Grid>
            </Grid>
        </div>
    );
};

const UsedReservedList = (props) => {
    const classes = useStyles();
    const [WindowWidths] = useWindowSize();

    const [state, setState] = useState({
        isReload: new Date(),
        showLoader: true,
        newEnquiryList: [],
        filterText: '',
        showRow: 0,
        showData: false,
        gridData: {},
        branchName: '',
        filterTableData: []
    });

    const handleModal = (value) => {
        setState((st) => ({ ...st, gridData: value ? value : {}, showData: !st.showData, branchName: value?.sp || '' }));
    };

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const getFilterData = async (value) => {
        setState((st) => ({ ...st, showLoader: true }));

        let data = {
            filterDate: value.filterDate,
            franchiseCode: value.franchiseCode,
            branchID: value.branchID,
            type: props?.location?.state
        };

        let isCurrencyFormatterList = [
            { paramName: 'sale', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'cost', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'discoval', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'bonuses', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'subsidy', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'cpi', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'finance', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'accSale', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'accCost', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'warSale', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'warCost', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'bodyCost', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'prepCost', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'tc', formatterName: 'CurrencyThousandSeparator' },
            { paramName: 'ts', formatterName: 'CurrencyThousandSeparator' }
        ];

        let res = await postReservedForUnits(data, props?.location?.state);
        if (res.success) {
            setState((st) => ({
                ...st,
                newEnquiryList: res.data.list.map((p) => {
                    isCurrencyFormatterList.forEach((o) => {
                        p[o.paramName] = formatters[o.formatterName](p[o.paramName]);
                    });
                    return { ...p };
                }),
                isReload: new Date(),
                filterData: data,
                showLoader: false
            }));
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const csvDownloadHandler = async () => {
        await postBranchOverViewUsedReserved(state.filterData, true, `Used Reserved`);
    };

    const setFilterText = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setState((st) => {
            const newSt = { ...st };
            const { filterText, newEnquiryList } = newSt;
            let filterd = [];
            filterd = [...newEnquiryList];
            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }
            newSt.filterTableData = filterd;
            return newSt;
        });
    };

    useEffect(() => {
        setFilteredRow();
    }, [state.newEnquiryList]);

    let RenderList = rowsPerPage > 0 ? [...state.filterTableData].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : state.filterTableData;

    return (
        <div className="budgetScreen">
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={6} sm={9} md={9} lg={10} xl={10}>
                    <div className="mainHeading alignLeft screenMainHeader">Used Reserved</div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={2} xl={2} style={{ marginTop: 5 }}>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Hidden only={['xl', 'lg', 'md']}>
                            <Grid item>
                                <BudgetFilter getFilterData={getFilterData} />
                            </Grid>
                        </Hidden>
                        <Grid item style={{ paddingTop: '4px' }}>
                            <LinkedButton onClick={csvDownloadHandler}>
                                <ViewComfyIcon />
                                {WindowWidths > 1024 ? <> &nbsp;&nbsp;DOWNLOAD CSV</> : ''}
                            </LinkedButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Hidden only={['sm', 'xs']}>
                {' '}
                <BudgetFilter getFilterData={getFilterData} />
            </Hidden>

            <Grid container>
                {state.showLoader ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                required
                                name="Search"
                                label="Search"
                                onChange={setFilterText}
                                value={state.filterText}
                                style={{ maxHeight: 30, marginBottom: 20 }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TableContainer style={{ maxHeight: 500, width: '100%' }}>
                                <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={state.filterTableData?.length}
                                        className="type3_Text"
                                    />
                                    <TableBody>
                                        {[...stableSort([...RenderList], getComparator(order, orderBy))].map((k, i) => (
                                            <>
                                                <TableRow key={i}>
                                                    <TableCell>
                                                        <NavigateNextIcon
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleModal(k);
                                                            }}
                                                        />
                                                    </TableCell>

                                                    <TableCell>{k.sp}</TableCell>
                                                    <TableCell>{k.buyer}</TableCell>
                                                    {/* <TableCell>{k.branch}</TableCell> */}
                                                    <TableCell>{k.reg}</TableCell>
                                                    <TableCell>{k.v}</TableCell>
                                                    <TableCell>{formatters.DateFormatter(k.id)}</TableCell>
                                                    <TableCell>{k.cust}</TableCell>
                                                    <TableCell align="right">{formatters.CurrencyThousandSeparatorWithoutZero(k.gp)}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                                component="div"
                                count={state.filterTableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </>
                )}
                {state.showData ? (
                    <DialogComp maxWidth="lg" onClose={handleModal} title={state.branchName}>
                        <DetailTemplate props={state.gridData} />
                    </DialogComp>
                ) : null}
            </Grid>
        </div>
    );
};

export default UsedReservedList;
