import { CircularProgress, Grid, TableContainer } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { SecondaryButton } from '../../../../Core/FormInput';
import formatters from '../../../../Core/Grid/inputFormatter';
import { getNicolBudgetSalesTargets, setNicolBudgetSalesTargets } from '../../../../Core/Service/branch-screen-service';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../../commonStyle.scss';

import moment from 'moment';
import AppContext from '../../../../App/AppContext';

const useStyles = makeStyles({
    table: {
        minWidth: 1250,

        '& .MuiTableCell-root': {
            padding: '10px'
        }
    },
    tableRowStyle: {
        backgroundColor: '#E0E0E0'
    }
});

const MonthShortNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function isFiniteCheck(str) {
    return isFinite(str) ? str.toFixed(2) : '0.00';
}

const PartsSalesTargetScreen = (props) => {
    const [targetTypeList, setTargetTypeList] = useState([]);

    const [showCircularLoader, setShowCircularLoader] = useState(true);
    const [calculatedTargetTypeList, setCalculatedTargetTypeList] = useState([]);
    const { showToast } = useContext(AppContext);
    const classes = useStyles();
    const pullSalesTargetGridData = async () => {
        setShowCircularLoader(true);
        let tempTargetTypeList = [
            {
                rowName: 'Parts retail sales',
                staticID: 1,
                data: []
            },
            {
                rowName: 'Parts Trade sales',
                staticID: 2,
                data: []
            },
            {
                rowName: 'Parts workshop internal sales',
                staticID: 3,
                data: []
            },
            {
                rowName: 'Parts workshop external sales',
                staticID: 4,
                data: []
            },
            {
                rowName: 'Parts internal sales',
                staticID: 5,
                data: []
            },
            {
                rowName: 'Parts Warranty',
                staticID: 6,
                data: []
            },
            {
                rowName: 'Parts total sales',
                data: []
            },
            {
                rowName: 'Parts retail GP',
                staticID: 7,
                data: []
            },
            {
                rowName: 'Parts Trade GP',
                staticID: 8,
                data: []
            },
            {
                rowName: 'Parts workshop internal sales GP',
                staticID: 9,
                data: []
            },
            {
                rowName: 'Parts workshop external sales GP',
                staticID: 10,
                data: []
            },
            {
                rowName: 'Parts internal GP',
                staticID: 11,
                data: []
            },
            {
                rowName: 'Parts Warranty GP',
                staticID: 12,
                data: []
            },
            {
                rowName: 'Parts total GP',
                data: []
            },
            {
                rowName: 'Parts bonus',
                staticID: 13,
                data: []
            },
            {
                rowName: 'Obsolete Stock',
                staticID: 14,
                data: []
            },
            {
                rowName: 'Parts Total Gross Profit',
                data: []
            }
        ];

        let res = await getNicolBudgetSalesTargets(props.yearCode?.id, props.data.departmentID, props.branchID);
        let tempResData = res.data.list || [];

        tempResData.forEach((q) => {
            let objIndex = tempTargetTypeList.findIndex((p) => p.staticID === q.salesTargetTypeID);
            if (objIndex > -1) {
                tempTargetTypeList[objIndex].data.push(q);
            }
        });

        tempTargetTypeList.forEach((r) => {
            let list2 = MonthShortNameList.map((m) => {
                let obj = r.data.find((p) => moment(p.salesTargetDate).format('DD-MMM-YYYY').includes(`${m}`));
                if (obj) {
                    return obj;
                } else {
                    return {
                        salesTargetAmount: 0,
                        salesTargetBranchID: props.branchID,
                        salesTargetDate: moment(`01/${m}/${props.yearCode?.id}`).format('DD-MMM-YYYY'),
                        salesTargetDepartmentID: props.data.departmentID,
                        salesTargetID: null,
                        salesTargetTypeID: r.staticID
                    };
                }
            });
            r.data = list2;
        });

        setTargetTypeList(tempTargetTypeList);
        setShowCircularLoader(false);
    };

    useEffect(() => {
        pullSalesTargetGridData();
    }, [props.yearCode?.id]);

    useEffect(() => {
        if (targetTypeList.length > 0) {
            let tempArr = [...targetTypeList];

            /////  Parts Total Sales Calculations //////
            let PartsTotalSalesIdList = [1, 2, 3, 4, 5, 6];
            let PartsTotalSalesSortedList = PartsTotalSalesIdList.map((p) => {
                let obj = tempArr.find((q) => q.staticID == p);
                if (obj) {
                    return { ...obj };
                }
            });
            let tempPartsTotalSalesCalObj = {};
            PartsTotalSalesSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempPartsTotalSalesCalObj[i] = (+tempPartsTotalSalesCalObj[i] || 0) + r.salesTargetAmount;
                });
            });

            tempArr[6].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempPartsTotalSalesCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            //////  Parts total GP Calculations//////
            let PartsTotalGPIdList = [7, 8, 9, 10, 11, 12];
            let PartsTotalGPSortedList = PartsTotalGPIdList.map((p) => {
                let obj = tempArr.find((q) => q.staticID == p);
                if (obj) {
                    return { ...obj };
                }
            });
            let tempPartsTotalGPCalObj = {};
            PartsTotalGPSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempPartsTotalGPCalObj[i] = (+tempPartsTotalGPCalObj[i] || 0) + r.salesTargetAmount;
                });
            });

            tempArr[13].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempPartsTotalGPCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            ///// Parts Total Gross Profit Calculatins //////
            let PartsTotalGrossProfitIdList = ['Parts total GP', 'Parts bonus'];
            let PartsTotalGrossProfitSortedList = PartsTotalGrossProfitIdList.map((p) => {
                let obj = tempArr.find((q) => q.rowName == p);
                if (obj) {
                    return { ...obj };
                }
            });

            let tempPartsTotalGrossProfitCalObj = {};
            PartsTotalGrossProfitSortedList.forEach((q) => {
                q.data.forEach((r, i) => {
                    tempPartsTotalGrossProfitCalObj[i] = (+tempPartsTotalGrossProfitCalObj[i] || 0) + r.salesTargetAmount;
                });
            });

            tempArr[16].data = MonthShortNameList.map((q, i) => {
                return {
                    salesTargetAmount: tempPartsTotalGrossProfitCalObj[i],
                    salesTargetDate: moment(`01/${q}/${props.yearCode?.id}`).format('DD-MMM-YYYY')
                };
            });

            tempArr.forEach((t) => {
                let DataTotal = 0;
                t.data.forEach((q) => {
                    DataTotal += +q.salesTargetAmount;
                });
                t.allSalesTargetAmount = isFiniteCheck(DataTotal);
            });

            setCalculatedTargetTypeList(tempArr);
        }
    }, [targetTypeList]);

    const gridInputChangeHandler = (index, typeID) => (e) => {
        let tempList = [...targetTypeList];
        let objIndex = tempList.findIndex((x) => x.staticID === typeID);
        tempList[objIndex].data[index] = {
            ...tempList[objIndex].data[index],
            salesTargetAmount: +e.target.value,
            salesTargetTypeID: typeID,
            isChanged: true
        };
        setTargetTypeList(tempList);
    };

    const InputWrapper = (index, typeID, value) => {
        return (
            <input type="number" name={typeID} onChange={gridInputChangeHandler(index, typeID)} value={value} className="pa-4" style={{ textAlign: 'right' }} />
        );
    };

    const handleSubmit = async () => {
        let tempTargetTypeList = [...targetTypeList];
        let data = [];
        tempTargetTypeList.forEach((f) => {
            let tempData = f.data.filter((j) => j.isChanged).map(({ salesTargetDepartmentID, salesTargetBranchID, isChanged, ...q }) => ({ ...q }));
            data = [...data, ...tempData];
        });
        let filterPostData = {
            BranchID: props.branchID,
            SalesTargetDepartmentID: props.data.departmentID,
            SalesTargets: data
        };

        let res = await setNicolBudgetSalesTargets(filterPostData);
        if (res.success) {
            showToast('Sales Target Save Successfully');
            pullSalesTargetGridData();
        }
    };

    return (
        <Grid container item style={{ paddingTop: '10px' }} justifyContent="center">
            <Grid container item spacing={2} justifyContent="space-between">
                <Grid xs={8} item></Grid>
                <Grid xs={4} container item spacing={2} alignItems="center" justifyContent="flex-end">
                    <Grid item xs={4}>
                        <SecondaryButton className="setFullWidth" onClick={handleSubmit}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={4}>
                        <SecondaryButton className="setFullWidth">Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
            {showCircularLoader ? (
                <Grid item>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 290px)' }} className="input-Number-remove-spinners">
                        <Table className={classes.table} stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={170}></TableCell>
                                    {MonthShortNameList.map((p) => {
                                        return <TableCell align="right">{p}</TableCell>;
                                    })}
                                    <TableCell width={100} align="right">
                                        Total
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {calculatedTargetTypeList.map((t, i) => {
                                    return (
                                        <TableRow className={t.staticID ? {} : classes.tableRowStyle} key={i}>
                                            <TableCell component="th" scope="row">
                                                {t.rowName}
                                            </TableCell>

                                            {t.data.map((p, i) => {
                                                return t.staticID ? (
                                                    <TableCell align="right" key={i}>
                                                        {InputWrapper(i, t.staticID, p.salesTargetAmount)}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell align="right" key={i}>
                                                        {formatters.ThousandSeparatorWithRoundnWithoutZero(+p.salesTargetAmount)}
                                                    </TableCell>
                                                );
                                            })}

                                            <TableCell align="right">{formatters.ThousandSeparatorWithRoundnWithoutZero(+t.allSalesTargetAmount)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
};

export default PartsSalesTargetScreen;
