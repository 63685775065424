import React, { useState } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { SemiCircleProgress } from 'react-semicircle-progressbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination]);

export const LineWithIcon = (props) => {
    let checker = false;
    let checkerGreen = false;

    const GreenCheck = () => {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M6.3335 8.63642L8.11127 10.3334L11.6668 6.93945" stroke="#4F8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <ellipse cx="9" cy="8.63636" rx="8" ry="7.63636" stroke="#4F8000" stroke-width="2"/>
                </svg>
            </>
        );
    }

    const RedCheck = () => {
        return (
            <>
                <svg fill="#CE2020" height="18" width="18" viewBox="0 0 27.963 27.963">
                    <g>
                        <g id="c129_exclamation">
                            <path d="M13.983,0C6.261,0,0.001,6.259,0.001,13.979c0,7.724,6.26,13.984,13.982,13.984s13.98-6.261,13.98-13.984
                                C27.963,6.259,21.705,0,13.983,0z M13.983,26.531c-6.933,0-12.55-5.62-12.55-12.553c0-6.93,5.617-12.548,12.55-12.548
                                c6.931,0,12.549,5.618,12.549,12.548C26.531,20.911,20.913,26.531,13.983,26.531z"/>
                            <polygon points="15.579,17.158 16.191,4.579 11.804,4.579 12.414,17.158 		"/>
                            <path d="M13.998,18.546c-1.471,0-2.5,1.029-2.5,2.526c0,1.443,0.999,2.528,2.444,2.528h0.056c1.499,0,2.469-1.085,2.469-2.528
                                C16.441,19.575,15.468,18.546,13.998,18.546z"/>
                        </g>
                        <g id="Capa_1_207_">
                        </g>
                    </g>
                </svg>
            </>
        );
    }

    if (props.iconType === "checker" || props.iconType === "checkerIcon") {
        checker = true;

        if (props.value <= 0) {
            checkerGreen = true;
        }
    }

    return (
        <>
            <div className={props.iconType === "checker" || props.iconType === "checkerIcon" ? "stockBoxLineIcon checker" : props.iconType === "fullNoIcon" ? "stockBoxLineIcon fullNoIcon" : "stockBoxLineIcon"}>
                <div className="stockBoxlineLeft">
                    {
                        checker ? checkerGreen ?
                            <GreenCheck /> : <RedCheck /> : ""
                    }
                    <span className='stockBoxLineTitle'>{props.title}</span>
                </div>
                <div className={checker ? checkerGreen ? "stockBoxLineRight greenLine" : "stockBoxLineRight redLine" : "stockBoxLineRight"}>
                    <span className='stockBoxLineValue'>{props.value} {props.valueTotal ? <>/ {props.valueTotal}</> : ""}</span>
                    {
                        props.valueCurrency !== "" ? <>
                            <span className='stockBoxLineCurrency'>({props.valueCurrency})</span>
                        </> : <></>
                    }
                    {
                        props.iconType === "loading" ? <>
                            <span className='stockBoxLineFunc' onClick={() => props.func(props.funcType, props.funcValue)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M5.8335 14.0005H9.3335" stroke="#3E3E3E" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M14.0005 5.8335V9.3335" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M22.1665 14.0005H18.6665" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M14.0005 22.1665L14.0005 18.6665" stroke="#3E3E3E" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M8.22559 19.7744L10.7005 17.2995" stroke="#3E3E3E" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M8.22559 8.22559L10.7005 10.7005" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M19.7744 8.22559L17.2995 10.7005" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M19.7744 19.7744L17.2995 17.2995" stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            </span>
                        </> : 
                        props.iconType === "link" || props.iconType === "checkerIcon"  ? <>
                            <span className='stockBoxLineLink' onClick={() => props.func(props.funcType, props.funcValue)}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.94523 13.7985C5.2337 13.5101 5.2337 13.0418 4.94523 12.7533L2.09133 9.89941L9.89985 2.0909L17.7084 9.89941L14.8288 12.7789C14.5404 13.0674 14.5404 13.5357 14.8288 13.8242C15.1173 14.1126 15.5856 14.1126 15.8741 13.8242L18.9345 10.7638C19.411 10.2873 19.411 9.51155 18.9345 9.03505L10.7637 0.864288C10.2872 0.387784 9.51147 0.387783 9.03497 0.864287L0.864207 9.03505C0.387703 9.51155 0.387703 10.2873 0.864207 10.7638L3.89948 13.7991C4.18846 14.087 4.65677 14.087 4.94523 13.7985ZM10.7263 18.9719L13.3655 16.3327C13.654 16.0443 13.654 15.576 13.3655 15.2875C13.0771 14.999 12.6087 14.999 12.3203 15.2875L10.6341 16.9737L10.6341 8.74095C10.6341 8.3331 10.3031 8.00211 9.89523 8.00211C9.69131 8.00211 9.50635 8.0846 9.37262 8.21833C9.23889 8.35206 9.1564 8.53702 9.1564 8.74095L9.15589 16.964L7.46609 15.2742C7.17763 14.9857 6.70932 14.9857 6.42086 15.2742C6.13239 15.5626 6.13239 16.0309 6.42086 16.3194L9.07339 18.9719C9.5294 19.428 10.2703 19.428 10.7263 18.9719Z" fill="black" />
                                </svg>
                            </span>
                        </> : props.iconType === "checker" || props.iconType === "noIcon" ? <>
                            <span className='spacingLinkIcon'></span>
                        </> :
                        <></>
                    }
                </div>
            </div>  
            
        </>
    )
}

export const UsedStockReportTotalStock = (props) => {
    let {allData} = props;
    const [mobileHeader, setMobileHeader] = useState("daysInStock");
    const [WindowWidths] = useWindowSize();

    let dueInCalc = allData.due7 + allData.dueEom + allData.duePlusEom;
    let dueInPercentage = (dueInCalc*100)/allData.limits[0].overallLimit;
    let reservedPercentage = (allData.reserved*100)/allData.limits[0].overallLimit;

    const SmallGraphBars = (props) => {
        return (
            <>
                <div className="stockBoxDaysStockValueWrp">
                    <div className="stockBoxDaysStockValueLeft">
                        <span className="stockBoxDaysStockValueDesc">{props.valueDesc}</span>
                        <span className="stockBoxDaysStockValueProgress" style={{ width: props.progress+"%", maxWidth: "60%", background: props.colour }}></span>
                    </div>
                    <div className="stockBoxDaysStockValueRight">
                        <span className="stockBoxDaysStockValue">{props.value} <span>({props.progress+"%"})</span></span>
                        <span className="stockBoxDaysStockValueLink" onClick={() => props.func(props.funcType, props.funcValue)}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.94523 13.7985C5.2337 13.5101 5.2337 13.0418 4.94523 12.7533L2.09133 9.89941L9.89985 2.0909L17.7084 9.89941L14.8288 12.7789C14.5404 13.0674 14.5404 13.5357 14.8288 13.8242C15.1173 14.1126 15.5856 14.1126 15.8741 13.8242L18.9345 10.7638C19.411 10.2873 19.411 9.51155 18.9345 9.03505L10.7637 0.864288C10.2872 0.387784 9.51147 0.387783 9.03497 0.864287L0.864207 9.03505C0.387703 9.51155 0.387703 10.2873 0.864207 10.7638L3.89948 13.7991C4.18846 14.087 4.65677 14.087 4.94523 13.7985ZM10.7263 18.9719L13.3655 16.3327C13.654 16.0443 13.654 15.576 13.3655 15.2875C13.0771 14.999 12.6087 14.999 12.3203 15.2875L10.6341 16.9737L10.6341 8.74095C10.6341 8.3331 10.3031 8.00211 9.89523 8.00211C9.69131 8.00211 9.50635 8.0846 9.37262 8.21833C9.23889 8.35206 9.1564 8.53702 9.1564 8.74095L9.15589 16.964L7.46609 15.2742C7.17763 14.9857 6.70932 14.9857 6.42086 15.2742C6.13239 15.5626 6.13239 16.0309 6.42086 16.3194L9.07339 18.9719C9.5294 19.428 10.2703 19.428 10.7263 18.9719Z" fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
            </>
        )
    }

    const DaysInStock = (props) => {
        return (
            <>
                <SmallGraphBars
                    valueDesc={"0-30"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.dis30 * 100) / props.data.vehicleCount) : 0}
                    colour={"#4F8000"}
                    value={props.data.dis30}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={2}
                />
                <SmallGraphBars
                    valueDesc={"31-60"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.dis60 * 100) / props.data.vehicleCount) : 0}
                    colour={"#6BC167"}
                    value={props.data.dis60}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={3}
                />
                <SmallGraphBars
                    valueDesc={"61-90"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.dis90 * 100) / props.data.vehicleCount) : 0}
                    colour={"#FFC400"}
                    value={props.data.dis90}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={4}
                />
                <SmallGraphBars
                    valueDesc={"90+"}
                    progress={props.data.vehicleCount !== 0 ? Math.round((props.data.dis90Plus * 100) / props.data.vehicleCount) : 0}
                    colour={"#FF4436"}
                    value={props.data.dis90Plus}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={5}
                />
            </>
        );
    }

    const StockProfile = (props) => {
        return (
            <>
                <LineWithIcon
                    title={"Demo"}
                    value={props.data.demoQnty}
                    valueTotal={props.data.limits[0].demoStockLimit}
                    valueCurrency={formatters.ThousandWithKWithoutZero(props.data.demoValue)}
                    iconType={"loading"}
                    func={props.handleClickFilter}
                    funcType={"stockProfile"}
                    funcValue={2}
                    
                />
                <LineWithIcon
                    title={"Pre-Reg"}
                    value={props.data.preRegQnty}
                    valueCurrency={formatters.ThousandWithKWithoutZero(props.data.preRegValue)}
                    iconType={"loading"}
                    func={props.handleClickFilter}
                    funcType={"stockProfile"}
                    funcValue={3}
                />
                <LineWithIcon
                    title={"Courtesy Car"}
                    value={props.data.courtesyQnty}
                    valueTotal={props.data.limits[0].courtesyCarLimit}
                    valueCurrency={formatters.ThousandWithKWithoutZero(props.data.courtesyValue)}
                    iconType={"loading"}
                    func={props.handleClickFilter}
                    funcType={"stockProfile"}
                    funcValue={1}
                />
                <LineWithIcon
                    title={"LT Hire"}
                    value={props.data.ltHireQnty}
                    valueCurrency={formatters.ThousandWithKWithoutZero(props.data.ltHireValue)}
                    iconType={"loading"}
                    func={props.handleClickFilter}
                    funcType={"stockProfile"}
                    funcValue={4}
                />
                <LineWithIcon
                    title={"Trade"}
                    value={props.data.tradeQnty}
                    valueCurrency={formatters.ThousandWithKWithoutZero(props.data.tradeValue)}
                    iconType={"loading"}
                    func={props.handleClickFilter}
                    funcType={"stockProfileTrade"}
                    funcValue={"T"}
                />
            </>
        );
    }

    const DueIn = (props) => {
        return (
            <>
                <LineWithIcon
                    title={"< 7 Days"}
                    value={props.data.due7}
                    valueCurrency={formatters.ThousandWithKWithoutZero(props.data.due7Value)}
                    iconType={"link"}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={6}
                />
                <LineWithIcon
                    title={"End of month"}
                    value={props.data.dueEom}
                    valueCurrency={formatters.ThousandWithKWithoutZero(props.data.dueEomValue)}
                    iconType={"link"}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={7}
                />
                <LineWithIcon
                    title={"Later"}
                    value={props.data.duePlusEom}
                    valueCurrency={formatters.ThousandWithKWithoutZero(props.data.duePlusEomValue)}
                    iconType={"link"}
                    func={props.handleClickFilter}
                    funcType={"daysInStock"}
                    funcValue={8}
                />
            </>
        );
    }

    return (
        <>
            <div className="stockBoxWrp flexbox">
                <div className="stockBoxLeft">
                    <div className="stockBoxTop">
                        <div className="stockBoxTopLeft">
                            <span className="stockBoxMainLine">
                                {allData.vehicleCount}<span>/{allData.limits[0].overallLimit}</span>
                                
                            </span>
                            <span className="stockBoxMainLineText">Total Stock</span>
                        </div>
                        <div className="stockBoxTopRight">
                            <span className="stockBoxTopRightValue">Avg DIS <span>{allData.avgDis}</span></span>
                            <span className="stockBoxTopRightValue">Stock Turn <span>{allData.stockTurn}</span></span>
                        </div>
                    </div>

                    <div className="stockBoxTypesBarWrp">
                        <div className="stockBoxTypesBar">
                            <span style={{width: "60%", background: "#5F83DD", height: "100%", display: "block"}}></span>
                            <span style={{width: reservedPercentage+"%", background: "#147E00", height: "100%", display: "block"}}></span>
                            <span style={{width: dueInPercentage+"%", background: "#41C5C3", height: "100%", display: "block"}}></span>
                        </div>
                        <div className="stockBoxTypesInr">
                            <span className="stockBoxTypes">
                                <span className="stockBoxTypesColour" style={{background: "#5F83DD"}}></span>
                                <span className="stockBoxTypeValue" style={{color: "#5F83DD"}}>{allData.vehicleCount-allData.reserved-dueInCalc}</span>
                                <span>Stock</span>
                            </span>
                            <span className="stockBoxTypes">
                                <span className="stockBoxTypesColour" style={{background: "#147E00"}}></span>
                                <span className="stockBoxTypeValue" style={{color: "#147E00"}}>{allData.reserved}</span>
                                <span>Res</span>
                            </span>
                            <span className="stockBoxTypes">
                                <span className="stockBoxTypesColour" style={{background: "#41C5C3"}}></span>
                                <span className="stockBoxTypeValue" style={{color: "#41C5C3"}}>{dueInCalc}</span>
                                <span>Due In</span>
                            </span>
                        </div>
                    </div>

                    {
                        WindowWidths < 1199 ? <>
                            <div className='stockBoxMobileOptions'>
                                <div className='stockBoxMobileOptionsHeader'>
                                    <span className={mobileHeader === "daysInStock" ? "active" : ""} onClick={() => setMobileHeader("daysInStock")}>DAYS IN STOCK</span>
                                    <span className={mobileHeader === "stockProfile" ? "active" : ""} onClick={() => setMobileHeader("stockProfile")}>STOCK PROFILE</span>
                                    <span className={mobileHeader === "dueIn" ? "active" : ""} onClick={() => setMobileHeader("dueIn")}>DUE IN</span>
                                </div>
                                <div className="stockBoxMobileOptionsInr">
                                    <div className={mobileHeader === "daysInStock" ? "stockMobileOption active" : "stockMobileOption" }>
                                        <DaysInStock data={allData} />
                                    </div>
                                    <div className={mobileHeader === "stockProfile" ? "stockMobileOption active" : "stockMobileOption" }>
                                        <StockProfile data={allData} />
                                    </div>
                                    <div className={mobileHeader === "dueIn" ? "stockMobileOption active" : "stockMobileOption" }>
                                        <DueIn data={allData} />
                                    </div>  
                                </div>  
                            </div>
                        </> : 
                        <>
                            <div className="stockBoxDaysStock">
                                <span className="stockBoxItemTitle">DAYS IN STOCK</span>
                                <DaysInStock data={allData} handleClickFilter={props.handleClickFilter} />
                            </div>
                        </>
                    }
                </div>  
                {
                    WindowWidths > 1200 ? <>
                        <div className="stockBoxRight">
                            <div className="stockBoxRightTop">
                                <span className="stockBoxItemTitle">STOCK PROFILE</span>
                                <StockProfile data={allData} handleClickFilter={props.handleClickFilter} />
                            </div>
                            <div className="stockBoxRightBottom">
                                <span className="stockBoxItemTitle">DUE IN STOCK</span>
                                <DueIn data={allData} handleClickFilter={props.handleClickFilter} />
                            </div>
                        </div>
                    </> : <></>
                }
            </div>
        </>
    );
}

export const UsedStockReportToDo = (props) => {
    let {allData} = props;
    const [WindowWidths] = useWindowSize();

    return (
        <>
            <div className="stockBoxWrp flexbox">
                <div className='stockBoxLeft'>
                    <div className="stockBoxTop">
                        <div className="stockBoxTopLeft">
                                <span className='stockBoxLeftMainTitle'>
                                    {
                                        allData.noPrice +
                                        allData.noPhotos +
                                        allData.noAttGrabber +
                                        allData.toPrep +
                                        allData.priceChange +
                                        allData.noCapCode +
                                        allData.lowLeads +
                                        allData.reserver10PlusDay
                                    } 
                                    <span> To Do</span>
                                </span>
                                <span className='stockBoxLeftSubTitle'>ACTION LIST</span>
                        </div>
                        {
                            WindowWidths < 991 ? <>
                                <div className="stockBoxTopRight">
                                    <span className="stockBoxTopRightValue">Avg DIS <span>{allData.avgDis}</span></span>
                                    <span className="stockBoxTopRightValue">Stock Turn <span>{allData.stockTurn}</span></span>
                                </div>    
                            </> : <></>
                         }
                    </div>

                    <LineWithIcon
                        title={"No Price"}
                        value={allData.noPrice}
                        valueCurrency={""}
                        iconType={"checker"}
                    />
                    <LineWithIcon
                        title={"No Photos"}
                        value={allData.noPhotos}
                        valueCurrency={""}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={1}
                    />
                    <LineWithIcon
                        title={"No Att Grabber"}
                        value={allData.noAttGrabber}
                        valueCurrency={""}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={2}
                    />
                    <LineWithIcon
                        title={"To Prep"}
                        value={allData.toPrep}
                        valueCurrency={""}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={3}
                    />
                    <LineWithIcon
                        title={"Price Change"}
                        value={allData.priceChange}
                        valueCurrency={""}
                        iconType={"checkerIcon"}
                        func={props.handleClickFilter}
                        funcType={"listUpdate"}
                        funcValue={4}
                    />
                    <LineWithIcon
                        title={"No CAP Code"}
                        value={allData.noCapCode}
                        valueCurrency={""}
                        iconType={"checker"}
                    />
                    <LineWithIcon
                        title={"Low Leads"}
                        value={allData.lowLeads}
                        valueCurrency={""}
                        iconType={"checker"}
                    />
                    <LineWithIcon
                        title={"Reserved 10+Day"}
                        value={allData.reserver10PlusDay}
                        valueCurrency={""}
                        iconType={"checker"}
                    />
                </div>
                {
                    WindowWidths > 1200 ? <>
                        <div className="stockBoxRight">
                            <div className='halfCircleProgress'>         
                                <SemiCircleProgress
                                    percentage={80}
                                    size={{
                                        width: 200,
                                        height: 200,
                                    }}
                                    fontStyle={{
                                        display: "none"
                                    }}
                                    strokeWidth={8}
                                    strokeColor="#4A3AFF"
                                    hasBackground={true}
                                    bgStrokeColor="#E5EAFC"
                                />
                                <div className='halfCircleText'>
                                    <span className='halfCircleValue'>80%</span>
                                    <span className='halfCircleSub'>Stock Advertised</span>
                                </div>
                            </div>
                            <div className="lineItemsWrp">
                                <LineWithIcon
                                    title={"Avg Rating"}
                                    value={allData.avrRating}
                                    valueTotal={100}
                                    valueCurrency={""}
                                    iconType={"noIcon"}
                                />
                                <LineWithIcon
                                    title={"Low Quality Ads"}
                                    value={allData.lowQualityAds}
                                    valueCurrency={"2%"}
                                    iconType={"link"}
                                    func={props.handleClickFilter}
                                    funcType={"stockProfile"}
                                    funcValue={2}
                                />
                                <LineWithIcon
                                    title={"AT Rejected Ads"}
                                    value={allData.atRejectedAds}
                                    valueCurrency={"2%"}
                                    iconType={"link"}
                                    func={props.handleClickFilter}
                                    funcType={"stockProfile"}
                                    funcValue={2}
                                />
                                <LineWithIcon
                                    title={"Avg Images per Ad"}
                                    value={allData.avgImagesAds}
                                    valueCurrency={""}
                                    iconType={"noIcon"}
                                />
                            </div>
                        </div>
                    </> : <></>
                }
            </div>
        </>
    );
}

export const UsedStockCurrentValue = (props) => {
    let {allData} = props;
    const [WindowWidths] = useWindowSize();

    let capPercentValue = Math.round((allData.capValue*100)/allData.retailTotal);
    let capPercentWidth = ((capPercentValue)*60)/100;

    let atPercentValue = Math.round((allData.atValue*100)/allData.retailTotal);
    let atpPercentWidth = ((atPercentValue)*60)/100;

    return (
        <div className="stockBoxWrp flexbox">
            <div className='stockBoxLeft'>
                <div className="stockBoxTop">
                    <div className="stockBoxTopLeft">
                        <span className='stockBoxLeftMainTitle'>{formatters.CurrencyThousandSeparatorWithoutZero(allData.retailTotal)}</span>
                        <span className='stockBoxLeftSubTitle blackText'>Current Stock Value</span>
                    </div>
                </div>
                <div className="currentStockBars">
                    <div className="currentStockBarWrp">
                        <span className='currentStockTitle'>SIV</span>
                        <span className='currentStockBar' style={{background: "#2F2D2D"}}></span>
                    </div>
                    <div className="currentStockBarWrp">
                        <span className='currentStockTitle'>AT</span>
                        <span className='currentStockBar' style={{background: "#4871D9", width: atpPercentWidth+"%"}}></span>
                        <span className='currentStocPerc'>{atPercentValue-100 + "%"}</span>
                    </div>
                    <div className="currentStockBarWrp">
                        <span className='currentStockTitle'>CAP</span>
                        <span className='currentStockBar' style={{background: "#FF5C00", width: capPercentWidth +"%"}}></span>
                        <span className='currentStocPerc'>{capPercentValue-100 + "%"}</span>
                    </div>
                </div>
                {
                    WindowWidths > 1200 ? <>
                        <LineWithIcon
                            title={"AT Value"}
                            value={allData.atValue !== 0 ? formatters.CurrencyThousandSeparatorWithoutZero(allData.atValue) : "-"}
                            valueCurrency={""}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"AT Variance"}
                            value={allData.atVariance !== 0 ? formatters.CurrencyThousandSeparatorWithoutZero(allData.atVariance) : "-"}
                            valueCurrency={""}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"CAP Value"}
                            value={allData.capValue !== 0 ? formatters.CurrencyThousandSeparatorWithoutZero(allData.capValue) : "-"}
                            valueCurrency={""}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"CAP Variance"}
                            value={allData.capVariance !== 0 ? formatters.CurrencyThousandSeparatorWithoutZero(allData.capVariance) : "-"}
                            valueCurrency={""}
                            iconType={"fullNoIcon"}
                        />
                    </> : <>
                        <LineWithIcon
                            title={"Total Overaged"}
                            value={allData.totalOveraged}
                            valueCurrency={formatters.CurrencyThousandSeparatorWithoutZero(allData.totalOveragedValue)}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"Total Risk"}
                            value={allData.totalRisk}
                            valueCurrency={""}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"Franchise Overaged"}
                            value={allData.franOveraged}
                            valueCurrency={formatters.CurrencyThousandSeparatorWithoutZero(allData.franOveragedValue)}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"Franchise Risk"}
                            value={allData.franRisk}
                            valueCurrency={""}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"Non-Fran Overaged"}
                            value={allData.nonFranOveraged}
                            valueCurrency={formatters.CurrencyThousandSeparatorWithoutZero(allData.nonFranOveragedValue)}
                            iconType={"fullNoIcon"}
                        />
                        <LineWithIcon
                            title={"Non-Fran Risk"}
                            value={allData.nonFranRisk}
                            valueCurrency={""}
                            iconType={"fullNoIcon"}
                        />
                    </>
                }
            </div>
            {
                WindowWidths > 1200 ? <>
                    <div className='stockBoxRight'>
                        <span className='stockBoxLeftSubTitle'>OVERAGED PRIORITY LIST</span>
                        <span className='overagedValue'>{formatters.CurrencyThousandSeparatorWithoutZero(Math.abs(allData.franRisk + allData.nonFranRisk))}</span>
                        <span className='stockBoxLeftSubTitle backText'>Total Writedown Risk</span>
                        <span className='stockSmallText'>Exclude demo/courtesy</span>
                        <LineWithIcon
                            title={"Total OA"}
                            value={allData.totalOveraged}
                            valueCurrency={formatters.CurrencyThousandSeparatorWithoutZero(allData.totalOveragedValue)}
                            iconType={"link"}
                            func={props.handleClickFilter}
                            funcType={"franNonFran"}
                            funcValue={1}
                        />
                        <LineWithIcon
                            title={"Franchise OA"}
                            value={allData.franOveraged}
                            valueCurrency={formatters.CurrencyThousandSeparatorWithoutZero(allData.franOveragedValue)}
                            iconType={"link"}
                            func={props.handleClickFilter}
                            funcType={"franNonFran"}
                            funcValue={2}
                        />
                        <LineWithIcon
                            title={"Franchise Risk"}
                            value={formatters.CurrencyThousandSeparatorWithoutZero(Math.abs(allData.franRisk))}
                            valueCurrency={""}
                            iconType={"noIcon"}
                        />
                        <LineWithIcon
                            title={"Non-Fran OA"}
                            value={allData.nonFranOveraged}
                            valueCurrency={formatters.CurrencyThousandSeparatorWithoutZero(allData.nonFranOveragedValue)}
                            iconType={"link"}
                            func={props.handleClickFilter}
                            funcType={"franNonFran"}
                            funcValue={3}
                        />
                        <LineWithIcon
                            title={"Non-Fran Risk"}
                            value={formatters.CurrencyThousandSeparatorWithoutZero(Math.abs(allData.nonFranRisk))}
                            valueCurrency={""}
                            iconType={"noIcon"}
                        />
                        <LineWithIcon
                            title={"Demo/Courtesy Risk"}
                            value={formatters.CurrencyThousandSeparatorWithoutZero(Math.abs(allData.demoCourtesyRisk))}
                            valueCurrency={""}
                            iconType={"noIcon"}
                        />
                    </div>
                </> : <></>
            }
        </div>
    );
}

export const UsedStockIndicators = (props) => {
    let {allData} = props;
    const [WindowWidths] = useWindowSize();

    const GraphCol = (props) => {
        return (
            <div className='graphColWrp'>
                <div className='graphColTop'>
                    <span className='graphColValue'>{props.value} <span>({props.valuePercent})</span></span>
                </div>
                <div className='graphCol' style={{
                    maxHeight: props.maxHeigh,
                    height: props.height,
                    background: props.bgColour
                }}></div>
                <div className='graphColBtm'>
                    <span className='graphDesc' style={{background: props.textBackground, color: props.textColour}}>{props.text}</span>
                </div>
            </div>
        );
    }

    const PriceIndicator = (props) => {
        const priceArr = [props.data.priceIndicatorLow, props.data.priceIndicatorFair, props.data.priceIndicatorGood, props.data.priceIndicatorGreat, props.data.priceIndicatorHigh, props.data.priceIndicatorNoAnalysis];
        const priceIndicatorMax = priceArr.reduce((partialSum, a) => partialSum + a, 0);
        let higherValue =  priceArr.sort(function (a, b) { return a - b; });
        higherValue =  higherValue.slice(Math.max(higherValue.length - 1, 0));

        return (
            <>
                <div className='stockGraphIndicatorTop'>
                    <span className='stockGraphIndicatorTopTitle'>
                        Price indicator
                    </span>
                    <span className='stockGraphIndicatorTopSub'>
                        Advertised stock only
                    </span>
                </div>
                <div className='stockGraphIndicatorGraph'>
                    <GraphCol
                        value={props.data.priceIndicatorLow}
                        valuePercent={Math.round((props.data.priceIndicatorLow * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorLow !== 0 ? higherValue !== props.data.priceIndicatorLow ? Math.round((130 * props.data.priceIndicatorLow) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#D6D5D4"}
                        textColour={"#000"}
                        text={"Low"}
                    />
                    <GraphCol
                        value={props.data.priceIndicatorGreat !== 0 ? props.data.priceIndicatorGreat : 1}
                        valuePercent={Math.round((props.data.priceIndicatorGreat * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorGreat !== 0 ? higherValue !== props.data.priceIndicatorGreat ? Math.round((130 * props.data.priceIndicatorGreat) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#87C1A0"}
                        textColour={"#000"}
                        text={"Great"}
                    />
                    <GraphCol
                        value={props.data.priceIndicatorGood}
                        valuePercent={Math.round((props.data.priceIndicatorGood * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorGood !== 0 ? higherValue !== props.data.priceIndicatorGood ? Math.round((130 * props.data.priceIndicatorGood) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#1E6446"}
                        textColour={"#000"}
                        text={"Good"}
                    />
                    <GraphCol
                        value={props.data.priceIndicatorFair}
                        valuePercent={Math.round((props.data.priceIndicatorFair * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorFair !== 0 ? higherValue !== props.data.priceIndicatorFair ? Math.round((130 * props.data.priceIndicatorFair) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#FBBA00"}
                        textColour={"#000"}
                        text={"Fair"}
                    />
                    <GraphCol
                        value={props.data.priceIndicatorHigh}
                        valuePercent={Math.round((props.data.priceIndicatorHigh * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorHigh !== 0 ? higherValue !== props.data.priceIndicatorHigh ? Math.round((130 * props.data.priceIndicatorHigh) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#FF9A7A"}
                        textColour={"#000"}
                        text={"High"}
                    />
                    <GraphCol
                        value={props.data.priceIndicatorNoAnalysis}
                        valuePercent={Math.round((props.data.priceIndicatorNoAnalysis * 100) / priceIndicatorMax) + "%"}
                        height={props.data.priceIndicatorNoAnalysis !== 0 ? higherValue !== props.data.priceIndicatorNoAnalysis ? Math.round((130 * props.data.priceIndicatorNoAnalysis) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#C7CACE"}
                        textBackground={""}
                        textColour={"#000"}
                        text={"No analysis"}
                    />
                </div>
            </>
            
        );
    }

    const PerformanceRating = (props) => {
        const perfArr = [props.data.perfLow, props.data.perfBelowAvg, props.data.perfAboveAvg, props.data.perfExcellent, props.data.perfNoRating];
        const perfIndicatorMax = perfArr.reduce((partialSum, a) => partialSum + a, 0);
        let higherValue =  perfArr.sort(function (a, b) { return a - b; });
        higherValue =  higherValue.slice(Math.max(higherValue.length - 1, 0));

        return (
            <>
                <div className='stockGraphIndicatorTop'>
                    <span className='stockGraphIndicatorTopTitle'>
                        Performance Rating
                    </span>
                    <span className='stockGraphIndicatorTopSub'>
                        Advertised stock only
                    </span>
                </div>
                <div className='stockGraphIndicatorGraph'>
                    <GraphCol
                        value={props.data.perfLow}
                        valuePercent={Math.round((props.data.perfLow * 100) / perfIndicatorMax) + "%"}
                        height={props.data.perfLow !== 0 ? higherValue !== props.data.perfLow ? Math.round((130 * props.data.perfLow) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#ED4C3C"}
                        textBackground={"#FF3011"}
                        textColour={"#FFF"}
                        text={"Low"}
                    />
                    <GraphCol
                        value={props.data.perfBelowAvg}
                        valuePercent={Math.round((props.data.perfBelowAvg * 100) / perfIndicatorMax) + "%"}
                        height={props.data.perfBelowAvg !== 0 ? higherValue !== props.data.perfBelowAvg ? Math.round((130 * props.data.perfBelowAvg) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#F5C426"}
                        textBackground={"#F5C426"}
                        textColour={"#FFF"}
                        text={"Below Avg"}
                    />
                    <GraphCol
                        value={props.data.perfAboveAvg}
                        valuePercent={Math.round((props.data.perfAboveAvg * 100) / perfIndicatorMax) + "%"}
                        height={props.data.perfAboveAvg !== 0 ? higherValue !== props.data.perfAboveAvg ? Math.round((130 * props.data.perfAboveAvg) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#7CBF6A"}
                        textBackground={"#7CBF6A"}
                        textColour={"#FFF"}
                        text={"Above Avg"}
                    />
                    <GraphCol
                        value={props.data.perfExcellent}
                        valuePercent={Math.round((props.data.perfExcellent * 100) / perfIndicatorMax) + "%"}
                        height={props.data.perfExcellent !== 0 ? higherValue !== props.data.perfExcellent ? Math.round((130 * props.data.perfExcellent) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#62AD54"}
                        textBackground={"#62AD54"}
                        textColour={"#FFF"}
                        text={"Excellent"}
                    />
                    <GraphCol
                        value={props.data.perfNoRating}
                        valuePercent={Math.round((props.data.perfNoRating * 100) / perfIndicatorMax) + "%"}
                        height={props.data.perfNoRating !== 0 ? higherValue !== props.data.perfNoRating ? Math.round((130 * props.data.perfNoRating) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={""}
                        textColour={"#000"}
                        text={"No rating"}
                    />
                </div>
            </>
        );
    }

    const RetailRating = (props) => {
        const perfArr = [props.data.retail20, props.data.retail2140, props.data.retail4160, props.data.retail6180, props.data.retai81];
        const perfIndicatorMax = perfArr.reduce((partialSum, a) => partialSum + a, 0);
        let higherValue =  perfArr.sort(function (a, b) { return a - b; });
        higherValue =  higherValue.slice(Math.max(higherValue.length - 1, 0));

        return (
            <>
                <div className='stockGraphIndicatorTop'>
                    <span className='stockGraphIndicatorTopTitle'>
                        Retail Rating
                    </span>
                    <span className='stockGraphIndicatorTopSub'>
                        Advertised stock only
                    </span>
                </div>
                <div className='stockGraphIndicatorGraph'>
                    <GraphCol
                        value={props.data.retail20}
                        valuePercent={Math.round((props.data.retail20 * 100) / perfIndicatorMax) + "%"}
                        height={props.data.retail20 !== 0 ? higherValue !== props.data.retail20 ? Math.round((130 * props.data.retail20) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#ED4C3C"}
                        textBackground={"#FF3011"}
                        textColour={"#FFF"}
                        text={"< 20"}
                    />
                    <GraphCol
                        value={props.data.retail2140}
                        valuePercent={Math.round((props.data.retail2140 * 100) / perfIndicatorMax) + "%"}
                        height={props.data.retail2140 !== 0 ? higherValue !== props.data.retail2140 ? Math.round((130 * props.data.retail2140) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#F5C426"}
                        textBackground={"#F5C426"}
                        textColour={"#FFF"}
                        text={"21 - 60"}
                    />
                    <GraphCol
                        value={props.data.retail4160}
                        valuePercent={Math.round((props.data.retail4160 * 100) / perfIndicatorMax) + "%"}
                        height={props.data.retail4160 !== 0 ? higherValue !== props.data.retail4160 ? Math.round((130 * props.data.retail4160) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#F9F562"}
                        textBackground={"#F9F562"}
                        textColour={"#A59F9F"}
                        text={"41 - 60"}
                    />
                    <GraphCol
                        value={props.data.retail6180}
                        valuePercent={Math.round((props.data.retail6180 * 100) / perfIndicatorMax) + "%"}
                        height={props.data.retail6180 !== 0 ? higherValue !== props.data.retail6180 ? Math.round((130 * props.data.retail6180) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#7CBF6A"}
                        textBackground={"#7CBF6A"}
                        textColour={"#FFF"}
                        text={"61 - 80"}
                    />
                    <GraphCol
                        value={props.data.retai81}
                        valuePercent={Math.round((props.data.retai81 * 100) / perfIndicatorMax) + "%"}
                        height={props.data.retai81 !== 0 ? higherValue !== props.data.retai81 ? Math.round((130 * props.data.retai81) / higherValue) + "px" : higherValue : 1}
                        bgColour={"#4871D9"}
                        textBackground={"#62AD54"}
                        textColour={"#FFF"}
                        text={"> 81"}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            {
                WindowWidths > 1200 ? <>
                    <div className="stockBoxWrp">
                        <PriceIndicator 
                            data={allData}
                        />
                    </div>
                    <div className="stockBoxWrp">
                        <PerformanceRating 
                            data={allData}
                        />
                    </div>
                    <div className="stockBoxWrp">
                        <RetailRating 
                            data={allData}
                        />
                    </div>
                </> : <>
                    <div className="stockBoxWrp swiperWrp">
                        <div className='usedStockSwiper'>
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                centeredSlides={true}
                            >
                                <SwiperSlide>
                                    <PriceIndicator 
                                        data={allData}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <PerformanceRating 
                                        data={allData}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <RetailRating 
                                        data={allData}
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </>
            }
        </>
    );
}