import { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AppContext from '../../../App/AppContext';
import {
    TextBox,
    PasswordBox,
    SecondaryButton,
    PrimaryCheckbox,
    DialogContents,
    DialogTitles,
    DialogsActions,
    Dialogs,
    SecondaryCheckbox,
    Multiselect,
    SingelSelect,
    SelectBox
} from '../../../Core/FormInput';

import { Adduser, UpdateUser, getUser, getScreenDropdown } from '../../../Core/Service/admin-service';
import CloseIcon from '@material-ui/icons/Close';
import { Checkbox, IconButton, InputLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import UserModules from './userModules';

const AddUserModal = (props) => {
    const { hideModal, showToast, portalSettings, userRoles } = useContext(AppContext);
    const [brancheData, setbrancheData] = useState({
        branches: []
    });

    const [franchiseData, setFranchisesData] = useState({
        franchises: []
    });
    const [selectedData, setSelectedData] = useState({
        branches: [],
        franchises: []
    });

    const [state, setState] = useState({
        userType: '',
        email: '',
        name: '',
        phone: '',
        password: '',
        confirm: '',
        userAccount: '',
        enableChange: false,
        allbranch: false,
        allworkflow: false,
        isActive: true,
        isLocked: false,
        branches: [],
        doc_id: '',
        canResetPassword: false,
        franchises: [],
        eReportingAllowed: false,
        sendEmailFromOwnEmail: false,
        canAccessBranchOverview: false,
        canAccessSalesProfitAndLoss: false,
        canAccessAfterSalesProfitAndLoss: false,
        canAccessProfitAndLossDetail: false,
        canAccessUsedStockValuation: false,
        canAccessUsedVehicleStock: false,
        canAccessAgedVehicleStock: false,
        canAccessOveragePriorityList: false,
        canAccessSalesReporting: false,
        canAccessUncappedVehicled: false,
        defaultBranchID: {},
        defaultBranchName: '',
        canAccessDemoStock: false,
        canAccessSitePerformance: false,
        canAccessUsedStockReport: false,
        canEditUsedStockReport: false,
    });
    const user = props.users;

    useEffect(() => {
        if (user) {
            const selectedfranchises = franchiseData.franchises.filter((w) => state.franchises?.indexOf(w.id) > -1);
            const selectedBranches = brancheData?.branches?.filter((w) => state.branches?.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,

                branches: selectedBranches,
                franchises: selectedfranchises
            }));

            if (brancheData.branches?.length > 0 && brancheData.branches?.length === selectedBranches?.length) {
                setState((st) => ({ ...st, allbranch: true }));
            }
        }
    }, [state.branches, brancheData.branches, state.defaultBranchID, state.franchises, franchiseData.franchises]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let respo = await getScreenDropdown();

                if (respo.success) {
                    const branches = respo?.data?.branches;
                    setbrancheData((st) => ({ ...st, branches }));
                    setFranchisesData((st) => ({
                        ...st,
                        franchises: respo?.data?.franchiseCode
                    }));
                }

                if (user) {
                    let result = await getUser(user.id);
                    const updatedUser = result.data;

                    if (result.success) {
                        setState((st) => ({
                            ...st,
                            franchises: updatedUser.franchisesCode,
                            doc_id: updatedUser.id,
                            name: updatedUser.displayName,
                            phone: updatedUser.phoneNo,
                            email: updatedUser.email,
                            userAccount: updatedUser.userAccount,
                            userType: updatedUser.userRole,
                            isActive: updatedUser.isActive,
                            isLocked: updatedUser.isLocked,
                            enableChange: true,
                            workflow: updatedUser.workflowIds,
                            branches: updatedUser.branchIds,
                            customer: updatedUser.customerIds,
                            canResetPassword: updatedUser.canResetPassword,
                            eReportingAllowed: updatedUser.eReportingAllowed,
                            canAccessBranchOverview: updatedUser.canAccessBranchOverview || false,
                            canAccessSalesProfitAndLoss: updatedUser.canAccessSalesProfitAndLoss || false,
                            canAccessAfterSalesProfitAndLoss: updatedUser.canAccessAfterSalesProfitAndLoss || false,
                            canAccessProfitAndLossDetail: updatedUser.canAccessProfitAndLossDetail || false,
                            canAccessUsedStockValuation: updatedUser.canAccessUsedStockValuation || false,
                            canAccessUsedVehicleStock: updatedUser.canAccessUsedVehicleStock || false,
                            canAccessAgedVehicleStock: updatedUser.canAccessAgedVehicleStock || false,
                            canAccessOveragePriorityList: updatedUser.canAccessOveragePriorityList || false,
                            canAccessSalesReporting: updatedUser.canAccessSalesReporting || false,
                            canAccessUncappedVehicled: updatedUser.canAccessUncappedVehicled || false,
                            defaultBranchID: updatedUser.defaultBranchID || null,
                            canAccessDemoStock: updatedUser.canAccessDemoStock || false,
                            canAccessSitePerformance: updatedUser.canAccessSitePerformance || false,
                            canAccessUsedStockReport: updatedUser.canAccessUsedStockReport || false,
                            canEditUsedStockReport: updatedUser.canEditUsedStockReport || false
                        }));
                    }
                }
            } catch (error) {
                // Handle any errors that might occur during the asynchronous operation
                console.error('An error occurred:', error);
            }
        };

        fetchData();
    }, [user]);

    const singleSelectChange = (event) => {
        let { name, value } = event.target;
        // setState((st) => {
        //     let newSt = { ...st, [nm]: val };

        //     return newSt;
        // });

        setState((st) => ({ ...st, [name]: value }));
    };

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const handleSubmit = async (e) => {
        let commonData = {
            id: state.doc_id || null,
            displayName: state.name,
            email: state.email,
            phone: state.phone,
            isLocked: state.isLocked,
            isActive: state.isActive,
            userRole: state.userType,
            userAccount: state.userAccount,
            sendEmailFromOwnEmail: state.sendEmailFromOwnEmail
        };

        let customData = {
            admin: {
                ...commonData,
                branchIds: state.branches,
                customerIds: [],
                workflowIds: [],
                canResetPassword: state.canResetPassword,
                franchisesCode: state.franchises,
                eReportingAllowed: state.eReportingAllowed,
                canAccessBranchOverview: state.canAccessBranchOverview,
                canAccessSalesProfitAndLoss: state.canAccessSalesProfitAndLoss,
                canAccessAfterSalesProfitAndLoss: state.canAccessAfterSalesProfitAndLoss,
                canAccessProfitAndLossDetail: state.canAccessProfitAndLossDetail,
                canAccessUsedStockValuation: state.canAccessUsedStockValuation,
                canAccessUsedVehicleStock: state.canAccessUsedVehicleStock,
                canAccessAgedVehicleStock: state.canAccessAgedVehicleStock,
                canAccessOveragePriorityList: state.canAccessOveragePriorityList,
                canAccessSalesReporting: state.canAccessSalesReporting,
                canAccessUncappedVehicled: state.canAccessUncappedVehicled,
                defaultBranchID: state.defaultBranchID,
                canAccessDemoStock: state.canAccessDemoStock,
                canAccessSitePerformance: state.canAccessSitePerformance,
                canAccessUsedStockReport: state.canAccessUsedStockReport,
                canEditUsedStockReport: state.canEditUsedStockReport
            },
            internal: {
                ...commonData,
                branchIds: state.branches,
                customerIds: [],
                canResetPassword: state.canResetPassword,
                franchisesCode: state.franchises,
                eReportingAllowed: state.eReportingAllowed,
                canAccessBranchOverview: state.canAccessBranchOverview,
                canAccessSalesProfitAndLoss: state.canAccessSalesProfitAndLoss,
                canAccessAfterSalesProfitAndLoss: state.canAccessAfterSalesProfitAndLoss,
                canAccessProfitAndLossDetail: state.canAccessProfitAndLossDetail,
                canAccessUsedStockValuation: state.canAccessUsedStockValuation,
                canAccessUsedVehicleStock: state.canAccessUsedVehicleStock,
                canAccessAgedVehicleStock: state.canAccessAgedVehicleStock,
                canAccessOveragePriorityList: state.canAccessOveragePriorityList,
                canAccessSalesReporting: state.canAccessSalesReporting,
                canAccessUncappedVehicled: state.canAccessUncappedVehicled,
                defaultBranchID: state.defaultBranchID,
                canAccessDemoStock: state.canAccessDemoStock,
                canAccessSitePerformance: state.canAccessSitePerformance,
                canAccessUsedStockReport: state.canAccessUsedStockReport,
                canEditUsedStockReport: state.canEditUsedStockReport
            }
        };

        let data = {
            ...commonData,
            ...customData[`${state.userType}`]
        };

        let res = {};
        if (state.doc_id) {
            res = await UpdateUser(data);
        } else {
            data = {
                ...data,
                password: state.password,
                confirmPassword: state.confirm
            };
            res = await Adduser(data);
        }
        if (!res.success) {
            console.error(res);
        } else {
            hideModal();
            props.onFormSubmit(true);
        }
        showToast(res.message);
    };
    const AutoBrChange = (event, value) => {
        setSelectedData((st) => ({ ...st, branches: value }));
        setState((st) => {
            const nst = { ...st, branches: value.map((c) => c.id) };
            return nst;
        });
    };

    const AutoFranchiseChange = (event, value) => {
        setSelectedData((st) => ({ ...st, franchises: value }));
        setState((st) => {
            const nst = { ...st, franchises: value.map((c) => c.id) };
            return nst;
        });
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            if (!user) {
                nst.enableChange = nst.password && nst.email && nst.name && nst.password === nst.confirm;
            }
            return nst;
        });
    };
    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setState((st) => {
                const nst = { ...st };
                nst[name] = checked;
                if (name == 'allbranch') {
                    nst.branches = [...brancheData.branches.map((c) => c.id)];
                } else {
                    nst[name] = checked;
                }
                return nst;
            });
        } else {
            setState((st) => {
                const nst = { ...st, [name]: checked };
                return nst;
            });
        }
    };
    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{user ? <DialogTitles>Update User</DialogTitles> : <DialogTitles>Add User</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents style={{ overflow: 'auto', height: '80vh' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextBox autoFocus={true} required name="name" label="Name" onChange={fieldChange} value={state.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Phone Number" name="phone" variant="outlined" fullWidth onChange={fieldChange} value={state.phone} margin="dense" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox required label="Email" name="email" type="email" onChange={fieldChange} value={state.email} />
                    </Grid>
                    <Grid item xs={4} sm={6}>
                        <TextField label="Keyloop Code" name="userAccount" variant="outlined" fullWidth onChange={fieldChange} value={state.userAccount} margin="dense" />
                    </Grid>
                    {!user ? (
                        <>
                            <Grid item xs={12} sm={6}>
                                <PasswordBox autoComplete="new-password" label="Password" name="password" onChange={fieldChange} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PasswordBox autoComplete="new-password" label="Confirm Password" name="confirm" onChange={fieldChange} />
                            </Grid>
                        </>
                    ) : null}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={state.sendEmailFromOwnEmail} onChange={handleCheckbox} name="sendEmailFromOwnEmail" />}
                            label="Send Email From Own Email?"
                        />
                    </Grid>
                    <Grid item row xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">User Type</FormLabel>
                            <Grid item xs={12}>
                                <RadioGroup row aria-label="userType" name="userType" value={state.userType} onChange={fieldChange}>
                                    <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                                    <FormControlLabel value="internal" control={<Radio />} label="Internal User" />
                                </RadioGroup>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <InputLabel>Default Branch</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectBox
                                name="defaultBranchID"
                                required
                                List={brancheData.branches}
                                value={state.defaultBranchID}
                                onChange={singleSelectChange} 
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<PrimaryCheckbox onChange={handleCheckbox} checked={state.allbranch} name="allbranch" value={state.allbranch} />}
                                label="All Branches"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {!state.allbranch ? (
                                <Multiselect
                                    hideButtons={true}
                                    label="Branches"
                                    options={brancheData.branches}
                                    value={selectedData.branches}
                                    onChange={AutoBrChange}
                                />
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <UserModules
                                selectedData={selectedData}
                                franchiseData={franchiseData}
                                AutoFranchiseChange={AutoFranchiseChange}
                                handleClick={handleClick}
                                state={state}
                                handleCheckbox={handleCheckbox}
                                portalSettings={portalSettings}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Multiselect
                            hideButtons={true}
                            label="Franchises"
                            options={franchiseData.franchises}
                            value={selectedData.franchises}
                            onChange={AutoFranchiseChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            control={<SecondaryCheckbox checked={state.isActive} onChange={handleCheckbox} name="isActive" />}
                            label="Is Active?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            control={<SecondaryCheckbox checked={state.isLocked} onChange={handleCheckbox} name="isLocked" />}
                            label="Is Locked?"
                        />
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                {!user ? (
                    <SecondaryButton className="Submitbtn" onClick={handleSubmit} disabled={!state.enableChange}>
                        Submit
                    </SecondaryButton>
                ) : (
                    <SecondaryButton className="Submitbtn" onClick={handleSubmit} disabled={!state.enableChange}>
                        Update
                    </SecondaryButton>
                )}
            </DialogsActions>
        </Dialogs>
    );
};

export default AddUserModal;
